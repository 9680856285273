define("darwin-portal/helpers/format-titlecase", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.toTitleCase = toTitleCase;
    function toTitleCase(str) {
        try {
            return str.toLowerCase().replace(/\b(\w)/g, function (s) {
                return s.toUpperCase();
            });
        } catch (_a) {
            return "";
        }
    }
});
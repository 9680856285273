define("darwin-portal/models/si-command", ["exports", "ember-data", "ember-data/attr", "moment"], function (exports, _emberData, _attr, _moment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var SiCommand = function (_DS$Model$extend) {
        _inherits(SiCommand, _DS$Model$extend);

        function SiCommand() {
            _classCallCheck(this, SiCommand);

            return _possibleConstructorReturn(this, (SiCommand.__proto__ || Object.getPrototypeOf(SiCommand)).apply(this, arguments));
        }

        return SiCommand;
    }(_emberData.default.Model.extend({
        Name: (0, _attr.default)("string"),
        FriendlyName: (0, _attr.default)("string"),
        Data: (0, _attr.default)("raw"),
        AckType: (0, _attr.default)("number"),
        Timestamp: (0, _attr.default)("date"),
        SiId: (0, _attr.default)("string"),
        CognitoId: (0, _attr.default)("string"),
        DeviceToken: (0, _attr.default)("string"),
        // Computed
        dateAndHour: Ember.computed("Timestamp", function () {
            // Return the timestamp as a date/hour string so we can
            // group telemetry by the hour
            return (0, _moment.default)(Ember.get(this, "Timestamp")).format("YYYY-MM-DD HH");
        })
    }));

    exports.default = SiCommand;
});
define('darwin-portal/components/devices-map', ['exports', 'mapbox-gl'], function (exports, _mapboxGl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    tagName: "",
    size: "medium",
    selectedItem: null,
    pointColor: '#00ABE9',
    navigationControl: null,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'navigationControl', new _mapboxGl.default.NavigationControl());
    },

    // Computed
    totalDevicesCount: Ember.computed('model.value', function () {
      var deviceLocations = Ember.get(this, 'model.value');
      if (Ember.isEmpty(deviceLocations)) {
        return "";
      }
      return deviceLocations.features.length;
    }),
    locationBounds: Ember.computed('model.value', function () {
      var mapPadding = 0.1;
      var geoJsonFeatures = Ember.get(this, 'model.value.features');

      if (Ember.isEmpty(geoJsonFeatures)) {
        return [[0, 0], [0, 0]];
      }

      var deviceLngs = geoJsonFeatures.map(function (feature) {
        return feature.geometry.coordinates[0];
      });

      var deviceLats = geoJsonFeatures.map(function (feature) {
        return feature.geometry.coordinates[1];
      });

      var maxLng = Math.max.apply(Math, _toConsumableArray(deviceLngs)) + mapPadding;
      var minLng = Math.min.apply(Math, _toConsumableArray(deviceLngs)) - mapPadding;
      var maxLat = Math.max.apply(Math, _toConsumableArray(deviceLats)) + mapPadding;
      var minLat = Math.min.apply(Math, _toConsumableArray(deviceLats)) - mapPadding;

      return [[maxLng, maxLat], [minLng, minLat]];
    }),

    actions: {
      clusterClicked: function clusterClicked(_ref) {
        var map = _ref.target,
            point = _ref.point;

        var features = map.queryRenderedFeatures(point, {
          layers: ['clusters']
        });
        var clusterId = features[0].properties.cluster_id;
        var sourceId = features[0].source;
        map.getSource(sourceId).getClusterExpansionZoom(clusterId, function (err, zoom) {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        });
      },
      pointClicked: function pointClicked(_ref2) {
        var map = _ref2.target,
            point = _ref2.point;

        var features = map.queryRenderedFeatures(point, {
          layers: ['unclustered-point']
        }),
            action = Ember.get(this, 'locationSelected');

        // Check we defined an item
        if (action) {
          // Call the action passing the selected item
          action(features[0].properties);
        }
      },
      enterCluster: function enterCluster(_ref3) {
        var map = _ref3.target;

        map.getCanvas().style.cursor = 'pointer';
      },
      exitCluster: function exitCluster(_ref4) {
        var map = _ref4.target;

        map.getCanvas().style.cursor = '';
      }
    }
  }).reopenClass({
    positionalParams: ['model']
  });
});
define("darwin-portal/controllers/external/login", ["exports", "ember-concurrency-decorators", "ember-concurrency"], function (exports, _emberConcurrencyDecorators, _emberConcurrency) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    function isStateResponse(arg) {
        return arg.state !== undefined;
    }

    var ExternalLogin = function (_EmberController$ext) {
        _inherits(ExternalLogin, _EmberController$ext);

        function ExternalLogin() {
            _classCallCheck(this, ExternalLogin);

            var _this = _possibleConstructorReturn(this, (ExternalLogin.__proto__ || Object.getPrototypeOf(ExternalLogin)).apply(this, arguments));

            _this.session = Ember.inject.service('session');
            _this.authState = Ember.inject.service('auth-state');
            _this.errorMessage = '';
            _this.isLoggingIn = false;
            return _this;
        }

        _createClass(ExternalLogin, [{
            key: "authenticateTask",
            value: regeneratorRuntime.mark(function authenticateTask(portalUser) {
                var cognitoUser, authRes;
                return regeneratorRuntime.wrap(function authenticateTask$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                cognitoUser = {
                                    username: Ember.get(portalUser, 'username').toLowerCase(),
                                    password: Ember.get(portalUser, 'password')
                                };

                                this.set('errorMessage', undefined);
                                _context.prev = 2;
                                _context.next = 5;
                                return this.get('session').authenticate('authenticator:cognito', cognitoUser);

                            case 5:
                                authRes = _context.sent;

                                // remove the username and password from ember data
                                this.store.unloadAll('portal-user');
                                this.set('errorMessage', undefined);
                                Ember.set(this, "isLoggingIn", true);
                                return _context.abrupt("return", authRes);

                            case 12:
                                _context.prev = 12;
                                _context.t0 = _context["catch"](2);

                                if (_context.t0.name === 'UserNotFoundException') {
                                    this.set('errorMessage', "Username or password is incorrect");
                                } else if (isStateResponse(_context.t0) && _context.t0.state.name === 'newPasswordRequired') {
                                    this.get('authState').state = _context.t0.state;
                                    this.set('errorMessage', '');
                                    this.transitionToRoute('external.set-password');
                                } else {
                                    this.set('errorMessage', _context.t0.message || _context.t0);
                                }

                            case 15:
                                Ember.set(this, "isLoggingIn", false);
                                // return;

                            case 16:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, authenticateTask, this, [[2, 12]]);
            })
        }]);

        return ExternalLogin;
    }(Ember.Controller.extend({
        queryParams: ['msg'],
        init: function init() {
            var _this2 = this;

            this._super.apply(this, arguments);
            Ember.run.schedule('actions', function () {
                _this2.checkAuthenticated.perform();
            });
        },

        // if they're authenticated already, redirect
        checkAuthenticated: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
            var authenticated;
            return regeneratorRuntime.wrap(function _callee$(_context2) {
                while (1) {
                    switch (_context2.prev = _context2.next) {
                        case 0:
                            _context2.next = 2;
                            return this.get('session').get('isAuthenticated');

                        case 2:
                            authenticated = _context2.sent;

                            if (authenticated) {
                                this.transitionToRoute('internal');
                            }

                        case 4:
                        case "end":
                            return _context2.stop();
                    }
                }
            }, _callee, this);
        }))
    }));

    exports.default = ExternalLogin;

    __decorate([_emberConcurrencyDecorators.dropTask], ExternalLogin.prototype, "authenticateTask", null);
});
define('darwin-portal/objects/alarm-sub-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        Info: false,
        Warning: false,
        Critical: false,
        All: false
    });
});
define('darwin-portal/components/uxs-toolbar', ['exports', 'ember-ux-sauce/components/uxs-toolbar'], function (exports, _uxsToolbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsToolbar.default;
    }
  });
});
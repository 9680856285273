define("darwin-portal/components/schedule-table", ["exports", "ember-bem-sauce/mixins/bem-component"], function (exports, _bemComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var ScheduleTable = function (_EmberComponent$exte) {
        _inherits(ScheduleTable, _EmberComponent$exte);

        function ScheduleTable() {
            _classCallCheck(this, ScheduleTable);

            return _possibleConstructorReturn(this, (ScheduleTable.__proto__ || Object.getPrototypeOf(ScheduleTable)).apply(this, arguments));
        }

        return ScheduleTable;
    }(Ember.Component.extend(_bemComponent.default, {
        // Attributes
        base: 'schedule-table',
        tagName: '',
        eventCount: [1, 2, 3, 4, 5, 6],
        days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        defaultDataModifers: [],
        activeDataModifiers: ['active'],
        // Computed
        scheduleRows: Ember.computed('zone', function () {
            var zone = Ember.get(this, 'zone');
            var scheduleDays = Ember.get(zone, 'Schedule');
            var rows = scheduleDays.reduce(function (acc, scheduleDay) {
                scheduleDay.events.forEach(function (event) {
                    var row = Object.assign({}, event, { day: scheduleDay.day });
                    acc.push(row);
                });
                return acc;
            }, []);
            return rows;
        }),
        tableClass: Ember.computed('showKey', function () {
            var showKey = Ember.get(this, 'showKey');
            var base = ['table'];
            if (showKey) {
                base.push('no-margin');
            }
            return base.join(' ');
        })
    }));

    exports.default = ScheduleTable;

    ;
});
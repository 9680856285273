define('darwin-portal/mixins/components/menu-filter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        can: Ember.inject.service(),
        items: Ember.computed('_items', function () {
            var items = Ember.get(this, '_items');
            var ccan = this.get('can');
            var filtered = items.filter(function (x) {
                if (x.ability) {
                    return ccan.can(x.ability);
                } else {
                    return true;
                }
            });
            return filtered;
        })
    });
});
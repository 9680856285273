define('darwin-portal/mixins/validations/partner', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            min: 2
        })],
        website: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
            type: 'url'
        })],
        contactNumber: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
            type: 'phone',
            regex: /^\+{0,1}[0-9, ]*$/
        })]
    });
});
define('darwin-portal/controllers/internal/partner/properties', ['exports', 'darwin-portal/mixins/controllers/paginatable', 'darwin-portal/utils/properties/sort-options', 'darwin-portal/mixins/controllers/searchable', 'darwin-portal/mixins/controllers/sortable'], function (exports, _paginatable, _sortOptions, _searchable, _sortable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPartnerProperties = function (_EmberController$ext) {
        _inherits(InternalPartnerProperties, _EmberController$ext);

        function InternalPartnerProperties() {
            _classCallCheck(this, InternalPartnerProperties);

            return _possibleConstructorReturn(this, (InternalPartnerProperties.__proto__ || Object.getPrototypeOf(InternalPartnerProperties)).apply(this, arguments));
        }

        return InternalPartnerProperties;
    }(Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
        // Computed
        partner: Ember.computed.alias('partnerController.partner'),
        properties: Ember.computed.alias('model.properties.value'),
        meta: Ember.computed.alias('model.properties.value.meta'),
        loading: Ember.computed.or('loadingProperties', 'loadingPartner'),
        loadingProperties: Ember.computed.alias('model.properties.isRunning'),
        loadingPartner: Ember.computed.alias('partnerController.loading'),
        // Properties
        sort: 'createdAt',
        sortOptions: _sortOptions.default,
        // Controllers
        partnerController: Ember.inject.controller('internal.partner')
    }));

    exports.default = InternalPartnerProperties;
});
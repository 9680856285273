define('darwin-portal/components/current-telemetry', ['exports', 'ember-cli-group-by/macros'], function (exports, _macros) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var CurrentTelemetry = function (_EmberComponent$exte) {
        _inherits(CurrentTelemetry, _EmberComponent$exte);

        function CurrentTelemetry() {
            _classCallCheck(this, CurrentTelemetry);

            return _possibleConstructorReturn(this, (CurrentTelemetry.__proto__ || Object.getPrototypeOf(CurrentTelemetry)).apply(this, arguments));
        }

        return CurrentTelemetry;
    }(Ember.Component.extend({
        // Attributes
        tagName: '',
        // Computed
        telemetryGroupedByHour: (0, _macros.groupByPath)('telemetry', 'dateAndHour')
    }));

    exports.default = CurrentTelemetry;

    ;
});
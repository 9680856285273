define('darwin-portal/components/dashboard/traffic-tile/light', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Properties
    base: 'traffic__light',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style']);
    }
  }).reopenClass({
    positionalParams: ['number', 'label', 'style']
  });
});
define('darwin-portal/components/device-boiler-performance-chart', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var DeviceBoilerPerformanceChart = function (_EmberComponent$exte) {
        _inherits(DeviceBoilerPerformanceChart, _EmberComponent$exte);

        function DeviceBoilerPerformanceChart() {
            _classCallCheck(this, DeviceBoilerPerformanceChart);

            var _this = _possibleConstructorReturn(this, (DeviceBoilerPerformanceChart.__proto__ || Object.getPrototypeOf(DeviceBoilerPerformanceChart)).apply(this, arguments));

            _this.options = Ember.computed('telemetry', function () {
                var telemetry = _this.get('telemetry');
                var gaps = telemetry.map(function (t) {
                    return (0, _moment.default)(t.timestamp);
                }).sort(function (a, b) {
                    return a.valueOf() - b.valueOf();
                }).reduce(function (acc, curr, idx, src) {
                    var last = src[idx - 1];
                    var duration = _moment.default.duration(curr.diff(last));
                    var minutes = duration.asMinutes();
                    if (minutes > 20) {
                        var xMin = last.clone().add(7, 'minute');
                        var xMax = curr.clone().add(-7, 'minute');
                        acc.push({
                            //drawTime: 'afterDraw',
                            // id: 'a-line-1', // optional
                            type: 'box',
                            xScaleID: 'x-axis-0',
                            xMin: xMin,
                            xMax: xMax,
                            backgroundColor: '#eeeeee',
                            borderColor: '#eeeeee'
                        });
                    }
                    return acc;
                }, []);
                return {
                    tooltips: {
                        mode: 'index'
                    },
                    scales: {
                        xAxes: [{
                            type: 'time',
                            time: {
                                unit: 'hour',
                                displayFormats: {
                                    hour: 'DD/MM hA'
                                }
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: false
                            }
                        }]
                    },
                    annotation: {
                        drawTime: 'beforeDatasetsDraw',
                        annotations: gaps
                    }
                };
            });
            _this.data = Ember.computed('telemetry', function () {
                var telemetry = _this.get('telemetry');
                var fanSpeedData = telemetry.map(function (a) {
                    return {
                        y: a.chFanSpeedAggregate,
                        x: a.timestamp
                    };
                });
                var modulationData = telemetry.map(function (a) {
                    return {
                        y: a.relativeModulationLevelMean,
                        x: a.timestamp
                    };
                });
                // TODO: dhw/ch gas consumption
                var labels = telemetry.map(function (a) {
                    return a.timestamp;
                });
                return {
                    labels: labels,
                    datasets: [{
                        label: 'Fan Speed',
                        data: fanSpeedData,
                        borderWidth: 0,
                        borderColor: '#00698E',
                        backgroundColor: '#00698E',
                        fill: false,
                        pointRadius: 0
                    }, {
                        label: 'Modulation',
                        data: modulationData,
                        borderWidth: 0,
                        borderColor: '#6f3089',
                        backgroundColor: '#6f3089',
                        fill: false,
                        pointRadius: 0
                    }]
                };
            });
            return _this;
        }

        return DeviceBoilerPerformanceChart;
    }(Ember.Component.extend({}));

    exports.default = DeviceBoilerPerformanceChart;
});
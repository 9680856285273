define('darwin-portal/adapters/portal-session', ['exports', 'ember-data', 'darwin-portal/mixins/adapters/base-adapter', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _baseAdapter, _dataAdapterMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var PortalSession = function (_DS$JSONAPIAdapter$ex) {
        _inherits(PortalSession, _DS$JSONAPIAdapter$ex);

        function PortalSession() {
            _classCallCheck(this, PortalSession);

            return _possibleConstructorReturn(this, (PortalSession.__proto__ || Object.getPrototypeOf(PortalSession)).apply(this, arguments));
        }

        return PortalSession;
    }(_emberData.default.JSONAPIAdapter.extend(_baseAdapter.default, _dataAdapterMixin.default, {
        // anything which *must* be merged on the prototype
        pathForType: function pathForType() {
            return "portal-session";
        }
    }));

    exports.default = PortalSession;
});
define('darwin-portal/utils/properties/sort-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SORT_OPTIONS = [{
        label: 'Property Code',
        value: 'propertyCode'
    }, {
        label: 'Post Code',
        value: 'addressPostcode'
    }, {
        label: 'Street Name',
        value: 'addressLine1'
    }, {
        label: 'City',
        value: 'addressCity'
    }, {
        label: 'Tenant',
        value: 'tenant'
    }];
    exports.default = SORT_OPTIONS;
});
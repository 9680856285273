define("darwin-portal/routes/internal/properties/new", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPropertiesNew = function (_EmberRoute$extend) {
        _inherits(InternalPropertiesNew, _EmberRoute$extend);

        function InternalPropertiesNew() {
            _classCallCheck(this, InternalPropertiesNew);

            return _possibleConstructorReturn(this, (InternalPropertiesNew.__proto__ || Object.getPrototypeOf(InternalPropertiesNew)).apply(this, arguments));
        }

        _createClass(InternalPropertiesNew, [{
            key: "model",
            value: function model() {
                var partner = this.store.peekRecord("partner", localStorage.getItem("partner_id"));
                return this.store.createRecord('property', {
                    partner: partner
                });
            }
        }]);

        return InternalPropertiesNew;
    }(Ember.Route.extend({
        // anything which *must* be merged to prototype here
    }));

    exports.default = InternalPropertiesNew;
});
define('darwin-portal/components/single-point-map', ['exports', 'mapbox-gl'], function (exports, _mapboxGl) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var SinglePointMap = function (_EmberComponent$exte) {
        _inherits(SinglePointMap, _EmberComponent$exte);

        function SinglePointMap() {
            _classCallCheck(this, SinglePointMap);

            return _possibleConstructorReturn(this, (SinglePointMap.__proto__ || Object.getPrototypeOf(SinglePointMap)).apply(this, arguments));
        }

        return SinglePointMap;
    }(Ember.Component.extend({
        tagName: "",
        navigationControl: null,
        init: function init() {
            this._super.apply(this, arguments);
            Ember.set(this, 'navigationControl', new _mapboxGl.default.NavigationControl());
        },

        // Computed
        hasData: Ember.computed('coords', function () {
            return !!Ember.get(this, 'coords.features');
        }),
        locationBounds: Ember.computed('coords', function () {
            var mapPadding = 0.01;
            var geoJsonFeatures = Ember.get(this, 'coords.features');
            if (Ember.isEmpty(geoJsonFeatures)) {
                return;
            }
            var deviceLngs = geoJsonFeatures.map(function (feature) {
                return feature.geometry.coordinates[0];
            });
            var deviceLats = geoJsonFeatures.map(function (feature) {
                return feature.geometry.coordinates[1];
            });
            var maxLng = Math.max.apply(Math, _toConsumableArray(deviceLngs)) + mapPadding;
            var minLng = Math.min.apply(Math, _toConsumableArray(deviceLngs)) - mapPadding;
            var maxLat = Math.max.apply(Math, _toConsumableArray(deviceLats)) + mapPadding;
            var minLat = Math.min.apply(Math, _toConsumableArray(deviceLats)) - mapPadding;
            return [[maxLng, maxLat], [minLng, minLat]];
        }),
        centreCoords: Ember.computed('coords', function () {
            var geoJsonFeatures = Ember.get(this, 'coords.features');
            if (Ember.isEmpty(geoJsonFeatures)) {
                return;
            }
            var deviceLngs = geoJsonFeatures.map(function (feature) {
                return feature.geometry.coordinates[0];
            });
            var deviceLats = geoJsonFeatures.map(function (feature) {
                return feature.geometry.coordinates[1];
            });
            return [deviceLngs[0], deviceLats[0]];
        })
    }).reopenClass({
        positionalParams: ['title', 'coords', 'pointColor', 'fullScreenLink']
    }));

    exports.default = SinglePointMap;
});
define('darwin-portal/utils/ideal-users/action-routes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var ACTION_ROUTES = [{
        title: 'View User',
        route: 'internal.ideal-user.index',
        ability: 'read ideal-user'
    }, {
        title: 'Update User',
        route: 'internal.ideal-user.edit',
        ability: 'update ideal-user'
    },
    /*
      {
        title: 'User Activities',
        // needs updating
        route: 'internal.ideal-user.activities',
        ability: 'read activities in ideal-user',
      },
      {
        title: 'Audit Trail',
        // needs updating
        route: 'internal.ideal-user.audits',
        ability: 'read audits in ideal-user',
      },
    */
    {
        title: 'Archive User',
        route: 'internal.ideal-user.archive',
        ability: 'archive ideal-user'
    }];
    exports.default = ACTION_ROUTES;
});
define('darwin-portal/controllers/internal/partner-device/executions/index', ['exports', 'darwin-portal/mixins/controllers/partner-device', 'darwin-portal/mixins/controllers/filterable', 'darwin-portal/mixins/controllers/paginatable', 'darwin-portal/utils/properties/sort-options', 'darwin-portal/utils/partner-devices/filter-options', 'darwin-portal/mixins/controllers/searchable', 'darwin-portal/mixins/controllers/sortable'], function (exports, _partnerDevice, _filterable, _paginatable, _sortOptions, _filterOptions, _searchable, _sortable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPartnerDeviceExecutionsIndex = function (_EmberController$ext) {
        _inherits(InternalPartnerDeviceExecutionsIndex, _EmberController$ext);

        function InternalPartnerDeviceExecutionsIndex() {
            _classCallCheck(this, InternalPartnerDeviceExecutionsIndex);

            return _possibleConstructorReturn(this, (InternalPartnerDeviceExecutionsIndex.__proto__ || Object.getPrototypeOf(InternalPartnerDeviceExecutionsIndex)).apply(this, arguments));
        }

        return InternalPartnerDeviceExecutionsIndex;
    }(Ember.Controller.extend(_partnerDevice.default, _filterable.default, _paginatable.default, _searchable.default, _sortable.default, {
        // most of the common functionality of this controller
        // can be found in /mixins/controllers/partner-device.js
        filterOptions: _filterOptions.default,
        // Computed
        deviceLoading: Ember.computed.alias('deviceController.loading'),
        meta: Ember.computed.alias('model.alarms.value.meta'),
        // Properties
        sort: 'createdAt',
        sortOptions: _sortOptions.default,
        queryParams: ['showResolved'],
        showResolved: false,
        actions: {
            toggleShowResolved: function toggleShowResolved() {
                var toggledValue = !Ember.get(this, 'showResolved');
                this.transitionToRoute({
                    queryParams: {
                        showResolved: toggledValue
                    }
                });
            }
        }
    }));

    exports.default = InternalPartnerDeviceExecutionsIndex;
});
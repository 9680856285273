define('darwin-portal/objects/update-password-form', ['exports', 'darwin-portal/mixins/validations/objects/update-password-form'], function (exports, _updatePasswordForm) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend(_updatePasswordForm.default, {
        currentPassword: null,
        newPassword: null,
        confirmPassword: null
    });
});
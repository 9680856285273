define('darwin-portal/controllers/internal/partner-device/set-service', ['exports', 'darwin-portal/mixins/controllers/partner-device', 'moment'], function (exports, _partnerDevice, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPartnerDeviceSetService = function (_EmberController$ext) {
        _inherits(InternalPartnerDeviceSetService, _EmberController$ext);

        function InternalPartnerDeviceSetService() {
            _classCallCheck(this, InternalPartnerDeviceSetService);

            var _this = _possibleConstructorReturn(this, (InternalPartnerDeviceSetService.__proto__ || Object.getPrototypeOf(InternalPartnerDeviceSetService)).apply(this, arguments));

            // normal class body definition here
            _this.actions = {
                submit: function submit(device) {
                    var _this2 = this;

                    Ember.set(device, 'lastServiceAt', new Date());
                    // accounts for leap year i.e. serviceIntervalDays could be 365 or 366
                    var nextServiceDue = (0, _moment.default)().add(1, 'year');
                    var serviceIntervalDays = nextServiceDue.diff((0, _moment.default)(), 'days');
                    Ember.set(device, 'serviceIntervalDays', serviceIntervalDays);
                    device.save().then(function () {
                        // success
                        Ember.get(_this2, 'toast').info('Service Period Updated');
                        _this2.transitionToRoute('internal.partner-device.index', device.id);
                    }, function (e) {
                        // failure
                        console.error(e);
                        Ember.get(_this2, 'toast').error('Error Updating Service Period');
                    });
                },
                invalid: function invalid() {
                    Ember.get(this, 'toast').error('Please Correct Form Errors');
                },
                saveManual: function saveManual(device) {
                    var _this3 = this;

                    if (!device.serviceIntervalDays || device.serviceIntervalDays < 1) {
                        Ember.get(this, 'toast').error('Service interval must be greater than 0 days');
                    } else {
                        // the date template won't change the existing value unless they select something
                        // but we can't change the model at the start in case they want the other action
                        // so we guess that they meant today if they didn't change anything.
                        // Hopefully no one will load the page one day, then submit it the next...
                        if (!device.changedAttributes()['lastServiceAt']) {
                            Ember.set(device, 'lastServiceAt', new Date());
                        }
                        device.save().then(function () {
                            // success
                            Ember.get(_this3, 'toast').info('Service Period Updated');
                            Ember.run.later(function () {
                                _this3.transitionToRoute('internal.partner-device.index', device.id);
                            }, 500);
                        }, function (e) {
                            // failure
                            console.error(e);
                            Ember.get(_this3, 'toast').error('Error Updating Service Period');
                        });
                    }
                }
            };
            return _this;
        }

        return InternalPartnerDeviceSetService;
    }(Ember.Controller.extend(_partnerDevice.default, {
        // most of the common functionality of this controller
        // can be found in /mixins/controllers/partner-device.js
    }));

    exports.default = InternalPartnerDeviceSetService;
});
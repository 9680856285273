define('darwin-portal/components/mapbox-gl-call', ['exports', 'ember-mapbox-gl/components/mapbox-gl-call'], function (exports, _mapboxGlCall) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mapboxGlCall.default;
    }
  });
});
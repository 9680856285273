define('darwin-portal/services/cognito', ['exports', 'ember-cognito/services/cognito', 'darwin-portal/config/environment'], function (exports, _cognito, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var cognitoEnv = Ember.merge({
    autoRefreshSession: false
  }, _environment.default.cognito);

  exports.default = _cognito.default.extend({
    poolId: cognitoEnv.poolId,
    clientId: cognitoEnv.clientId,
    autoRefreshSession: cognitoEnv.autoRefreshSession,
    authenticationFlowType: cognitoEnv.authenticationFlowType
  });
});
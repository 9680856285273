define("darwin-portal/controllers/internal/partner-device/license", ["exports", "darwin-portal/mixins/controllers/partner-device", "ember-concurrency-decorators"], function (exports, _partnerDevice, _emberConcurrencyDecorators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var InternalPartnerDeviceLicense = function (_EmberController$ext) {
        _inherits(InternalPartnerDeviceLicense, _EmberController$ext);

        function InternalPartnerDeviceLicense() {
            _classCallCheck(this, InternalPartnerDeviceLicense);

            var _this = _possibleConstructorReturn(this, (InternalPartnerDeviceLicense.__proto__ || Object.getPrototypeOf(InternalPartnerDeviceLicense)).apply(this, arguments));

            // normal class body definition here
            _this.actions = {
                invalid: function invalid() {
                    Ember.get(this, 'toast').error("Please Correct Form Errors");
                }
            };
            return _this;
        }

        _createClass(InternalPartnerDeviceLicense, [{
            key: "saveDeviceLicenseChange",
            value: regeneratorRuntime.mark(function saveDeviceLicenseChange(deviceLicenseChange) {
                return regeneratorRuntime.wrap(function saveDeviceLicenseChange$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                if (Ember.get(deviceLicenseChange, 'validations.isValid')) {
                                    _context.next = 3;
                                    break;
                                }

                                Ember.get(this, 'toast').error("Please Correct Form Errors");
                                return _context.abrupt("return");

                            case 3:
                                _context.prev = 3;
                                _context.next = 6;
                                return deviceLicenseChange.save();

                            case 6:
                                // success
                                Ember.get(this, 'toast').info("Device Updated");
                                this.send('refreshAll');
                                this.transitionToRoute('internal.partner-device.index');
                                _context.next = 15;
                                break;

                            case 11:
                                _context.prev = 11;
                                _context.t0 = _context["catch"](3);

                                console.log('Save fail', _context.t0);
                                // failure
                                Ember.get(this, 'toast').error("Error Updating Device");

                            case 15:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, saveDeviceLicenseChange, this, [[3, 11]]);
            })
        }]);

        return InternalPartnerDeviceLicense;
    }(Ember.Controller.extend(_partnerDevice.default, {
        // most of the common functionality of this controller
        // can be found in /mixins/controllers/partner-device.js
        toast: Ember.inject.service(),
        router: Ember.inject.service()
    }));

    exports.default = InternalPartnerDeviceLicense;

    __decorate([_emberConcurrencyDecorators.task], InternalPartnerDeviceLicense.prototype, "saveDeviceLicenseChange", null);
});
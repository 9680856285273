define('darwin-portal/mixins/routes/sortable', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        queryParams: {
            sort: {
                refreshModel: true
            }
        },
        getSortParam: function getSortParam(params) {
            var sort = params.sort;
            delete params.sort;
            return sort;
        }
    });
});
define("darwin-portal/routes/internal/properties/index", ["exports", "darwin-portal/mixins/routes/paginatable", "darwin-portal/mixins/routes/searchable", "darwin-portal/mixins/routes/sortable", "ember-concurrency-decorators"], function (exports, _paginatable, _searchable, _sortable, _emberConcurrencyDecorators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var InternalPropertiesIndex = function (_EmberRoute$extend) {
        _inherits(InternalPropertiesIndex, _EmberRoute$extend);

        function InternalPropertiesIndex() {
            _classCallCheck(this, InternalPropertiesIndex);

            return _possibleConstructorReturn(this, (InternalPropertiesIndex.__proto__ || Object.getPrototypeOf(InternalPropertiesIndex)).apply(this, arguments));
        }

        _createClass(InternalPropertiesIndex, [{
            key: "model",
            value: function model(params) {
                return { properties: Ember.get(this, 'getProperties').perform(params) };
            }
        }, {
            key: "getProperties",
            value: regeneratorRuntime.mark(function getProperties(params) {
                var sort, page, result;
                return regeneratorRuntime.wrap(function getProperties$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                sort = this.getSortParam(params);
                                page = this.getPageParams(params);
                                _context.next = 4;
                                return this.store.query('property', {
                                    include: "devices",
                                    filter: params,
                                    page: page,
                                    sort: sort
                                });

                            case 4:
                                result = _context.sent;
                                return _context.abrupt("return", result);

                            case 6:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, getProperties, this);
            })
        }]);

        return InternalPropertiesIndex;
    }(Ember.Route.extend(_paginatable.default, _searchable.default, _sortable.default, {
        // anything which *must* be merged to prototype here
    }));

    exports.default = InternalPropertiesIndex;

    __decorate([_emberConcurrencyDecorators.task], InternalPropertiesIndex.prototype, "getProperties", null);
});
define('darwin-portal/controllers/internal/firmware-groups/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalFirmwareGroupsIndex = function (_EmberController$ext) {
        _inherits(InternalFirmwareGroupsIndex, _EmberController$ext);

        function InternalFirmwareGroupsIndex() {
            _classCallCheck(this, InternalFirmwareGroupsIndex);

            return _possibleConstructorReturn(this, (InternalFirmwareGroupsIndex.__proto__ || Object.getPrototypeOf(InternalFirmwareGroupsIndex)).apply(this, arguments));
        }

        return InternalFirmwareGroupsIndex;
    }(Ember.Controller.extend({
        firmwareGroups: Ember.computed.alias('model.firmwareGroups.value'),
        loading: Ember.computed.alias('model.firmwareGroups.isRunning')
    }));

    exports.default = InternalFirmwareGroupsIndex;
});
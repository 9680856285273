define('darwin-portal/utils/account/action-routes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    // Actions for managing own account
    var ACTION_ROUTES = exports.ACTION_ROUTES = [{
        title: 'Update Password',
        route: 'internal.account.update-password'
    }];
    // Actions for admins supporting other accounts
    var ADMIN_ACTION_ROUTES = exports.ADMIN_ACTION_ROUTES = [{
        title: "Customer Details",
        route: "internal.accounts.show.index",
        currentWhen: "internal.accounts.show.index"
    }, {
        title: "Edit Customer",
        route: "internal.accounts.show.edit",
        currentWhen: "internal.accounts.show.edit"
    }, {
        title: "Remove Customer",
        route: "internal.accounts.show.delete",
        currentWhen: "internal.accounts.show.delete"
    }, {
        title: "Devices",
        route: "internal.accounts.show.devices",
        currentWhen: "internal.accounts.show.devices"
    }, {
        title: "Export Customer Data",
        route: "internal.accounts.show.dataprotection-export",
        currentWhen: "internal.accounts.show.dataprotection-export"
    }];
});
define('darwin-portal/utils/partner-devices/sort-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SORT_OPTIONS = [{
        label: 'Property',
        value: 'property'
    }, {
        label: 'Last Service',
        value: 'lastServiceAt'
    }, {
        label: 'Status',
        value: 'status'
    }];
    exports.default = SORT_OPTIONS;
});
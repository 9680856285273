define('darwin-portal/components/switch-partner-dropdown', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var SwitchPartnerDropdown = function (_EmberComponent$exte) {
        _inherits(SwitchPartnerDropdown, _EmberComponent$exte);

        function SwitchPartnerDropdown() {
            _classCallCheck(this, SwitchPartnerDropdown);

            return _possibleConstructorReturn(this, (SwitchPartnerDropdown.__proto__ || Object.getPrototypeOf(SwitchPartnerDropdown)).apply(this, arguments));
        }

        return SwitchPartnerDropdown;
    }(Ember.Component.extend(_bemComponent.default, {
        // Attributes
        base: "account-switcher",
        // Actions
        actions: {
            onPartnerSelect: function onPartnerSelect(value) {
                var action = Ember.get(this, "onSelect");
                return action(value);
            }
        }
    }));

    exports.default = SwitchPartnerDropdown;

    ;
});
define('darwin-portal/utils/get-bem-classes', ['exports', 'ember-bem-sauce/utils/get-bem-classes'], function (exports, _getBemClasses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _getBemClasses.default;
    }
  });
});
define('darwin-portal/components/dashboard/map-tile', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Properties
    base: 'tile',
    size: 'medium',
    map: true,
    classNames: [],
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*size', 'map', 'loading']);
    },

    // Computed
    mapOptions: Ember.computed(function () {
      return {
        pitch: 30,
        attributionControl: false,
        center: [-6.2, 53.4],
        logoPosition: 'bottom-left' // we also hide this with css
      };
    }),
    isLoading: Ember.computed.alias('loading')
  }).reopenClass({
    positionalParams: ['label', 'number']
  });
});
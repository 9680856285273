define('darwin-portal/models/alarm', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var Alarm = function (_DS$Model$extend) {
        _inherits(Alarm, _DS$Model$extend);

        function Alarm() {
            _classCallCheck(this, Alarm);

            return _possibleConstructorReturn(this, (Alarm.__proto__ || Object.getPrototypeOf(Alarm)).apply(this, arguments));
        }

        return Alarm;
    }(_emberData.default.Model.extend({
        // Attributes
        location: (0, _attr.default)('string'),
        priority: (0, _attr.default)('boolean'),
        raisedAt: (0, _attr.default)('date'),
        severity: (0, _attr.default)('string'),
        summary: (0, _attr.default)('string'),
        type: (0, _attr.default)('string'),
        gasSafeEngineerRequired: (0, _attr.default)('string'),
        resolutionText: (0, _attr.default)('string'),
        faults: (0, _attr.default)(),
        faultsSorted: Ember.computed('faults', function () {
            var faults = Ember.get(this, 'faults');
            var severity = Ember.get(this, 'severity');
            if (severitiesAreEqual(faults, severity)) {
                return sortByRaisedAt(faults);
            }
            return sortBySeverity(faults);
        }),
        highestSeverity: Ember.computed('faultsSorted', function () {
            var faultsSorted = Ember.get(this, 'faultsSorted');
            var severity = Ember.get(this, 'severity');
            if (faultsSorted.length !== 0) {
                return faultsSorted[0].Severity;
            }
            return severity;
        }),
        highestSeveritySummary: Ember.computed('faultsSorted', function () {
            var faultsSorted = Ember.get(this, 'faultsSorted');
            var summary = Ember.get(this, 'summary');
            if (faultsSorted.length !== 0) {
                return faultsSorted[0].OemMessage;
            }
            return summary;
        }),
        // Relationships
        device: _emberData.default.belongsTo('device'),
        property: _emberData.default.belongsTo('property'),
        mapReady: Ember.computed('property.latLongSet', function () {
            return Ember.get(this, 'property.latLongSet');
        }),
        codes: Ember.computed('faults', function () {
            var faults = Ember.get(this, 'faults');
            if (faults && faults.length > 0) {
                return faults.map(function (x) {
                    return x.FaultCode;
                });
            }
            return [];
        }),
        propertyAddress: Ember.computed('property.{addressLine1,addressCity,addressPostcode}', function () {
            var postcode = Ember.get(this, 'property.addressPostcode');
            var postcodeString = postcode ? ', ' + postcode : '';
            return Ember.get(this, 'property.addressLine1') + ', ' + Ember.get(this, 'property.addressCity') + postcodeString;
        })
    }));

    exports.default = Alarm;

    var severitiesAreEqual = function severitiesAreEqual(faults, severity) {
        var previousSeverity = severity;
        var result = true;
        faults.forEach(function (fault) {
            if (fault.Severity !== previousSeverity) {
                result = false;
            }
        });
        return result;
    };
    var sortByRaisedAt = function sortByRaisedAt(faults) {
        faults.sort(function (faultA, faultB) {
            var raisedAtA = faultA.ReportedAt;
            var raisedAtB = faultB.ReportedAt;
            return raisedAtB - raisedAtA;
        });
        return faults;
    };
    var sortBySeverity = function sortBySeverity(faults) {
        faults.sort(function (faultA, faultB) {
            var severities = ["Information", "Warning", "Critical"];
            var severityA = faultA.Severity;
            var indexOfSeverityA = severities.indexOf(severityA);
            var severityB = faultB.Severity;
            var indexOfSeverityB = severities.indexOf(severityB);
            if (indexOfSeverityA > indexOfSeverityB) {
                return -1;
            }
            if (indexOfSeverityA < indexOfSeverityB) {
                return 1;
            }
            return 0;
        });
        return faults;
    };
});
define('darwin-portal/components/account-form', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var AccountForm = function (_EmberComponent$exte) {
        _inherits(AccountForm, _EmberComponent$exte);

        function AccountForm() {
            _classCallCheck(this, AccountForm);

            var _this = _possibleConstructorReturn(this, (AccountForm.__proto__ || Object.getPrototypeOf(AccountForm)).apply(this, arguments));

            _this.actions = {
                submit: _this.onSubmit,
                update: _this.onSubmit,
                cancel: _this.onCancel,
                invalid: function invalid() {
                    return Ember.get(this, "onInvalid")();
                }
            };
            return _this;
        }

        return AccountForm;
    }(Ember.Component.extend(_bemComponent.default, {
        // anything which *must* be merged to prototype here
        base: 'account-form'
    }));

    exports.default = AccountForm;

    ;
});
define('darwin-portal/abilities/partner', ['exports', 'ember-can'], function (exports, _emberCan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCan.Ability.extend({
        // Service
        authorizer: Ember.inject.service('authorizer'),
        user: Ember.computed.alias('session.Email'),
        // Computed
        isAdmin: Ember.computed.alias('authorizer.isIdealAdmin'),
        // Computed
        isUser: Ember.computed.alias('authorizer.isIdealUser'),
        // Computed
        isIdeal: Ember.computed.alias('authorizer.isIdeal'),
        isPartnerAdmin: Ember.computed.alias('authorizer.isPartnerAdmin'),
        // any ideal users can create a partner
        canCreate: Ember.computed.alias('isIdeal'),
        // only admins can read a specific partner
        canRead: Ember.computed.alias('isIdeal'),
        // only admins can update a specific partner
        canUpdate: Ember.computed.alias('isAdmin'),
        // only admins can archive a specific partner
        canArchive: Ember.computed.alias('isAdmin'),
        // any ideal users can navigate to the partners list
        canNavigate: Ember.computed.alias('isIdeal'),
        canReadProperties: Ember.computed.alias('isIdeal'),
        canReadDevices: Ember.computed.alias('isIdeal'),
        canReadUsers: Ember.computed.alias('isIdeal'),
        canReadAudits: Ember.computed.alias('isAdmin')
    });
});
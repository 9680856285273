define('darwin-portal/utils/partner-users/sort-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SORT_OPTIONS = [{
        label: 'First Name',
        value: 'firstName'
    }, {
        label: 'Created At',
        value: 'createdAt'
    }, {
        label: 'Last Active',
        value: 'lastActive'
    }];
    exports.default = SORT_OPTIONS;
});
define('darwin-portal/mixins/validations/partner-device', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        "property.partner": [(0, _emberCpValidations.validator)('presence', true)],
        printedSerialNumber: [(0, _emberCpValidations.validator)('presence', true)],
        lastServiceAt: [(0, _emberCpValidations.validator)('presence', true)],
        serviceIntervalDays: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
            regex: /^[1-9]\d*$/
        })],
        "property.addressLine1": [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            min: 2
        })],
        "property.addressCity": [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            min: 2
        })],
        "property.addressCountry": [(0, _emberCpValidations.validator)('presence', true)],
        "property.addressPostcode": [(0, _emberCpValidations.validator)('format', {
            // very basic regex for matching uk postcodes and eircodes
            regex: /^([0-9A-Za-z]{3,4}?\s?[0-9A-Za-z]{3,4})$/,
            message: 'Postcode must be valid',
            allowBlank: true
        })],
        "property.contactNumber": [(0, _emberCpValidations.validator)('format', {
            type: 'phone',
            regex: /^\+{0,1}[0-9, ]*$/,
            allowBlank: true
        })],
        "property.latitude": [(0, _emberCpValidations.validator)('length', {
            max: 10
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,10})?))$/,
            message: 'Latitude value must be valid',
            allowBlank: true
        })],
        "property.longitude": [(0, _emberCpValidations.validator)('length', {
            max: 11
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,10})?))$/,
            message: 'Longitude value must be valid',
            allowBlank: true
        })]
    });
});
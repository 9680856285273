define('darwin-portal/components/dashboard/traffic-tile', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Properties
    base: 'tile',
    size: 'medium',
    classNames: [],
    loadingLabel: 'Loading...',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*size', 'loading']);
    },

    // Computed
    isLoading: Ember.computed.alias('loading')
  }).reopenClass({
    positionalParams: ['label']
  });
});
define('darwin-portal/routes/external/login', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var ExternalLogin = function (_EmberRoute$extend) {
        _inherits(ExternalLogin, _EmberRoute$extend);

        function ExternalLogin() {
            _classCallCheck(this, ExternalLogin);

            return _possibleConstructorReturn(this, (ExternalLogin.__proto__ || Object.getPrototypeOf(ExternalLogin)).apply(this, arguments));
        }

        _createClass(ExternalLogin, [{
            key: 'model',
            value: function model() {
                return this.store.createRecord('portal-user');
            }
        }]);

        return ExternalLogin;
    }(Ember.Route.extend({
        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            Ember.set(controller, "isLoggingIn", false);
        }
        // anything which *must* be merged to prototype here
    }));

    exports.default = ExternalLogin;
});
define('darwin-portal/components/dashboard/traffic-tile/light-link', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend(_bemComponent.default, {
    // Properties
    base: 'traffic__light',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style']);
    },

    /**
     * Lifting this code from https://github.com/emberjs/ember.js/blob/v3.7.2/packages/%40ember/-internals/glimmer/lib/components/link-to.ts#L312
     *
     * To keep a nice usable component with 3 positional params we need to customise the param logic.
     *
     * e.g. we want to be able to do
     * {{light-link 12 "label" "style" "route.name" p1 p2...}}
     **/
    didReceiveAttrs: function didReceiveAttrs() {
      var queryParams = void 0;
      var params = Ember.get(this, 'params');

      if (params) {
        // Do not mutate params in place
        params = params.slice();
      }

      (false && !(params && params.length) && Ember.assert('You must provide one or more parameters to the link-to component.', params && params.length));


      var disabledWhen = Ember.get(this, 'disabledWhen');
      if (disabledWhen !== undefined) {
        this.set('disabled', disabledWhen);
      }

      // ASSUME NO CHANGE IN BLOCK MODE OR INLINE
      // 1. count comes first, then number then icon.
      this.set('number', params.shift());
      this.set('linkTitle', params.shift());
      this.set('style', params.shift());

      // 2. `targetRouteName` is now always at index 0.
      this.set('targetRouteName', params[0]);

      // 3. The last argument (if still remaining) is the `queryParams` object.
      var lastParam = params[params.length - 1];

      if (lastParam && lastParam.isQueryParams) {
        queryParams = params.pop();
      } else {
        queryParams = {
          values: {}
        };
      }
      this.set('queryParams', queryParams);

      // 4. Any remaining indices (excepting `targetRouteName` at 0) are `models`.
      params.shift();
      this.set('models', params);
    },

    // Computed
    label: Ember.computed.alias('linkTitle')
  });
});
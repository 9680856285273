define('darwin-portal/components/system-faults-list', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var SystemFaultsList = function (_EmberComponent$exte) {
        _inherits(SystemFaultsList, _EmberComponent$exte);

        function SystemFaultsList() {
            _classCallCheck(this, SystemFaultsList);

            return _possibleConstructorReturn(this, (SystemFaultsList.__proto__ || Object.getPrototypeOf(SystemFaultsList)).apply(this, arguments));
        }

        return SystemFaultsList;
    }(Ember.Component.extend(_bemComponent.default, {
        base: 'system-faults-list',
        subtitle: Ember.computed('showResolved', function () {
            if (Ember.get(this, 'showResolved')) {
                return 'Resolved Faults';
            }
            return 'Faults';
        }),
        actions: {
            toggleList: function toggleList() {
                var action = Ember.get(this, 'onToggle');
                if (action) {
                    return action();
                }
            },
            selectFault: function selectFault(fault) {
                var action = Ember.get(this, 'onSelectFault');
                if (action) {
                    return action(fault);
                }
            }
        }
    }));

    exports.default = SystemFaultsList;

    ;
});
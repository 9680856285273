define('darwin-portal/components/ideal-user-actions', ['exports', 'darwin-portal/mixins/components/menu-filter'], function (exports, _menuFilter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var IdealUserActions = function (_EmberComponent$exte) {
        _inherits(IdealUserActions, _EmberComponent$exte);

        function IdealUserActions() {
            _classCallCheck(this, IdealUserActions);

            var _this = _possibleConstructorReturn(this, (IdealUserActions.__proto__ || Object.getPrototypeOf(IdealUserActions)).apply(this, arguments));

            // normal class body definition here
            _this._items = [{
                title: 'View User',
                route: 'internal.ideal-user.index',
                ability: "read ideal-user"
            }, {
                title: 'Update User',
                route: 'internal.ideal-user.edit',
                ability: "update ideal-user"
            },
            /*
                {
                  title: 'User Activities',
                  // needs updating
                  route: 'internal.ideal-user.activities',
                  ability: "read activities in ideal-user",
                },
                {
                  title: 'Audit Trail',
                  // needs updating
                  route: 'internal.ideal-user.audits',
                  ability: "read audits in ideal-user",
                },
            */
            {
                title: 'Archive User',
                route: 'internal.ideal-user.archive',
                ability: "archive ideal-user"
            }];
            return _this;
        }

        return IdealUserActions;
    }(Ember.Component.extend(_menuFilter.default, {
        // anything which *must* be merged to prototype here
        style: 'dark'
    }));

    exports.default = IdealUserActions;

    ;
});
define('darwin-portal/abilities/partner-device', ['exports', 'ember-can'], function (exports, _emberCan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCan.Ability.extend({
        authorizer: Ember.inject.service('authorizer'),
        user: Ember.computed.alias('session.Email'),
        canCreate: Ember.computed.alias('authorizer.isActiveUser'),
        canNavigate: Ember.computed.alias('authorizer.isActiveUser'),
        canRead: Ember.computed.alias('authorizer.isPartner'),
        canArchive: Ember.computed.or('authorizer.isAdmin', 'authorizer.isPartnerInstaller'),
        canReadData: Ember.computed.alias('authorizer.isIdealOrPartner'),
        canUpdateFirmware: Ember.computed.alias('authorizer.isIdealAdmin'),
        canViewGasConsumption: Ember.computed.alias('authorizer.isIdealAdmin')
    });
});
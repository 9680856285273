define('darwin-portal/abilities/property', ['exports', 'ember-can'], function (exports, _emberCan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCan.Ability.extend({
        authorizer: Ember.inject.service('authorizer'),
        isActiveUser: Ember.computed.alias('authorizer.isActiveUser'),
        isAdmin: Ember.computed.alias('authorizer.isAdmin'),
        isIdeal: Ember.computed.alias('authorizer.isIdeal'),
        isIdealOrPartnerAdmin: Ember.computed.alias('authorizer.isIdealOrPartnerAdmin'),
        // any ideal users can add a device
        canCreate: Ember.computed.alias('isActiveUser'),
        // any ideal users can read a property but partner users can only view thier own
        canRead: Ember.computed.alias('isActiveUser'),
        // any user can update a property but partner users can only update their own
        canUpdate: Ember.computed.alias('isActiveUser'),
        // only ideal or partner admins can archive a property
        canArchive: Ember.computed.alias('isIdealOrPartnerAdmin'),
        // any user can update a properties journal but partner admins and users can only update their own
        canUpdateJournal: Ember.computed.alias('isActiveUser'),
        // only ideal or partner admins can read a properties audit log
        canReadAudits: Ember.computed.alias('isAdmin'),
        // ideal users and partner admins can navigate to a partners property list
        canNavigate: Ember.computed.alias('isActiveUser'),
        // only ideal users can see a list of all properties for all partners
        canNavigateAll: Ember.computed.alias('isIdeal'),
        // ideal users and partner admins can view a list of related devices
        canReadDevices: Ember.computed.alias('isActiveUser'),
        // ideal users and partner admins can toggle a property as priority
        canSetPriority: Ember.computed.alias('isIdealOrPartnerAdmin')
    });
});
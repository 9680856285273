define('darwin-portal/components/device-boiler-usage-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var DeviceBoilerUsageTable = function (_EmberComponent$exte) {
    _inherits(DeviceBoilerUsageTable, _EmberComponent$exte);

    function DeviceBoilerUsageTable() {
      _classCallCheck(this, DeviceBoilerUsageTable);

      return _possibleConstructorReturn(this, (DeviceBoilerUsageTable.__proto__ || Object.getPrototypeOf(DeviceBoilerUsageTable)).apply(this, arguments));
    }

    return DeviceBoilerUsageTable;
  }(Ember.Component.extend({
    // anything which *must* be merged to prototype here
  }));

  exports.default = DeviceBoilerUsageTable;

  ;
});
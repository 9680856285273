define('darwin-portal/controllers/internal/partners/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPartnersNew = function (_EmberController$ext) {
        _inherits(InternalPartnersNew, _EmberController$ext);

        function InternalPartnersNew() {
            _classCallCheck(this, InternalPartnersNew);

            var _this = _possibleConstructorReturn(this, (InternalPartnersNew.__proto__ || Object.getPrototypeOf(InternalPartnersNew)).apply(this, arguments));

            _this.actions = {
                submit: function submit(newPartner) {
                    var _this2 = this;

                    newPartner.save().then(function () {
                        // this timeout allows time for the search database to update
                        Ember.run.later(function () {
                            _this2.transitionToRoute('internal.partners.index');
                        }, 500);
                    }, function () {
                        // failure
                    });
                }
            };
            return _this;
        }

        return InternalPartnersNew;
    }(Ember.Controller.extend({
        // anything which *must* be merged to prototype here
    }));

    exports.default = InternalPartnersNew;
});
define('darwin-portal/utils/partner-devices/max-flow-temp-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var FLOW_OPTIONS = [{
        label: 'Unchanged',
        value: -1
    }, {
        label: '30°',
        value: 30
    }, {
        label: '35°',
        value: 35
    }, {
        label: '40°',
        value: 40
    }, {
        label: '45°',
        value: 45
    }, {
        label: '50°',
        value: 50
    }, {
        label: '55°',
        value: 55
    }, {
        label: '60°',
        value: 60
    }, {
        label: '65°',
        value: 65
    }, {
        label: '70°',
        value: 70
    }, {
        label: '75°',
        value: 75
    }, {
        label: '80°',
        value: 80
    }];
    exports.default = FLOW_OPTIONS;
});
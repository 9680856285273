define('darwin-portal/models/halo-firmware', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var attr = _emberData.default.attr;

    var HaloFirmware = function (_DS$Model$extend) {
        _inherits(HaloFirmware, _DS$Model$extend);

        function HaloFirmware() {
            _classCallCheck(this, HaloFirmware);

            return _possibleConstructorReturn(this, (HaloFirmware.__proto__ || Object.getPrototypeOf(HaloFirmware)).apply(this, arguments));
        }

        return HaloFirmware;
    }(_emberData.default.Model.extend({
        // Attributes
        firmwareState: attr('string'),
        firmware: Ember.computed('firmwareState', function () {
            var state = Ember.get(this, 'firmwareState');
            switch (state) {
                case 'AwaitingFile':
                    return 'Waiting for files to be uploaded';
                case 'Processing':
                    return 'Processing Firmware';
                case 'Ready':
                    return 'Ready to Deploy';
                default:
                    return 'Firmware State Unknown';
            }
        }),
        name: attr('string'),
        description: attr('string'),
        processingLog: attr('string'),
        createdAt: attr('date'),
        createdBy: attr('string'),
        FirmwareUrls: attr('raw'),
        stepsForUpload: Ember.computed('FirmwareUrls', function () {
            var urls = Ember.get(this, 'FirmwareUrls');
            if (!urls) {
                return null;
            }
            var checksumRecord = urls.PresignedFileSha;
            var fileRecord = urls.PresignedFile;
            // this shows manual upload steps in the UI
            return '        test -f ./' + checksumRecord.fields.Key + ' &&         curl -i -X POST         -F \'Content-Type=null\'         -F \'key=' + checksumRecord.fields.Key + '\'         -F \'X-Amz-Date=' + checksumRecord.fields['X-Amz-Date'] + '\'         -F \'X-Amz-Signature=' + checksumRecord.fields['X-Amz-Signature'] + '\'         -F \'X-Amz-Algorithm=' + checksumRecord.fields['X-Amz-Algorithm'] + '\'         -F \'X-Amz-Credential=' + checksumRecord.fields['X-Amz-Credential'] + '\'         -F \'X-Amz-Security-Token=' + checksumRecord.fields['X-Amz-Security-Token'] + '\'         -F \'Policy=' + checksumRecord.fields.Policy + '\'         -F "file=@' + checksumRecord.fields.Key + '"         -f         ' + checksumRecord.url + '         && (echo \u2705  Firmware SHA File Uploaded Successfully)         || (echo \u274C  Uploading the file failed. ; exit 1 )         || echo "\u274C  Signed Firmware SHA file (./' + checksumRecord.fields.Key + ') does not exist"         &&                 test -f ./' + fileRecord.fields.Key + ' &&         curl -i -X POST         -F \'Content-Type=null\'         -F \'key=' + fileRecord.fields.Key + '\'         -F \'X-Amz-Date=' + fileRecord.fields['X-Amz-Date'] + '\'         -F \'X-Amz-Signature=' + fileRecord.fields['X-Amz-Signature'] + '\'         -F \'X-Amz-Algorithm=' + fileRecord.fields['X-Amz-Algorithm'] + '\'         -F \'X-Amz-Credential=' + fileRecord.fields['X-Amz-Credential'] + '\'         -F \'X-Amz-Security-Token=' + fileRecord.fields['X-Amz-Security-Token'] + '\'         -F \'Policy=' + fileRecord.fields.Policy + '\'         -F "file=@' + fileRecord.fields.Key + '"         -f         ' + fileRecord.url + '         && (echo \u2705  Firmware File Uploaded Successfully)         && (echo \u2705  Follow the next step on the portal)         || (echo \u274C  Uploading the file failed. ; exit 1 )         || echo "\u274C  Signed Firmware file (./' + fileRecord.fields.Key + ') does not exist"         ';
        })
    }));

    exports.default = HaloFirmware;
});
define('darwin-portal/abilities/dashboard', ['exports', 'ember-can'], function (exports, _emberCan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCan.Ability.extend({
        // Service
        authorizer: Ember.inject.service('authorizer'),
        user: Ember.computed.alias('session.Email'),
        canNavigate: Ember.computed.alias('authorizer.isActiveUser'),
        // all active users can see the dashboard
        canView: Ember.computed.alias('authorizer.isActiveUser'),
        canViewIdealTiles: Ember.computed.alias('authorizer.isIdeal')
    });
});
define('darwin-portal/mixins/routes/paginatable', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        // Attributes
        queryParams: {
            page: {
                refreshModel: true
            },
            size: {
                refreshModel: true
            }
        },
        // Methods
        getPageParams: function getPageParams(params) {
            var page = {
                number: params.page,
                size: params.size
            };
            delete params.page;
            delete params.size;
            return page;
        }
    });
});
define('darwin-portal/utils/partner-devices/filter-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var FILTER_OPTIONS = [{
        label: 'Halo Only',
        value: 'isHalo'
    }, {
        label: 'Online',
        value: 'online'
    }, {
        label: 'Offline',
        value: 'offline'
    }, {
        label: 'Has Alarm',
        value: 'hasAlarm'
    }];
    exports.default = FILTER_OPTIONS;
});
define('darwin-portal/components/system-status-sidebar', ['exports', 'ember-bem-sauce/mixins/bem-component', 'moment'], function (exports, _bemComponent, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var SystemStatusSidebar = function (_EmberComponent$exte) {
        _inherits(SystemStatusSidebar, _EmberComponent$exte);

        function SystemStatusSidebar() {
            _classCallCheck(this, SystemStatusSidebar);

            return _possibleConstructorReturn(this, (SystemStatusSidebar.__proto__ || Object.getPrototypeOf(SystemStatusSidebar)).apply(this, arguments));
        }

        return SystemStatusSidebar;
    }(Ember.Component.extend(_bemComponent.default, {
        // anything which *must* be merged to prototype here
        base: 'system-status-sidebar',
        systemDetails: Ember.computed('device', 'home', function () {
            try {
                var device = Ember.get(this, 'device');
                var home = Ember.get(this, 'home');
                var deviceProperties = [];
                if (device && device.type === "Halo") {
                    if (device.type) {
                        deviceProperties.push({ title: 'Device Type:', value: home && home.type ? home.type : device.type });
                    }
                    if (device.status) {
                        deviceProperties.push({ title: 'Status:', value: device.status });
                    }
                    if (device.serialNumber) {
                        deviceProperties.push({ title: 'Serial Number:', value: device.serialNumber });
                    }
                    if (device.firmwareGroup && device.firmwareGroup.name) {
                        deviceProperties.push({ title: 'Firmware Group:', value: device.firmwareGroup.name });
                    }
                    if (device.registeredAt) {
                        deviceProperties.push({ title: 'Registered On:', value: (0, _moment.default)(device.registeredAt).format("DD/MM/YYYY") });
                    }
                    if (device.lastReportedAt) {
                        deviceProperties.push({ title: 'Last Message Received:', value: (0, _moment.default)(device.lastReportedAt).fromNow() });
                    }
                    if (device.lastConnectedAt) {
                        deviceProperties.push({ title: 'Last Reconnected:', value: (0, _moment.default)(device.lastConnectedAt).fromNow() });
                    }
                    return deviceProperties;
                }
            } catch (error) {
                console.log('Property Retrieval Went Wrong - ', error);
                return [];
            }
        })
    }));

    exports.default = SystemStatusSidebar;

    ;
});
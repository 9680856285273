define('darwin-portal/helpers/format-postcode', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.formatPostcode = formatPostcode;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function formatPostcode(_ref) /*, hash*/{
        var _ref2 = _slicedToArray(_ref, 2),
            postcode = _ref2[0],
            part = _ref2[1];

        if (postcode) {
            var parts = postcode.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);
            if (parts) {
                parts.shift();
                if (part === 0) {
                    // return first portion of postcode
                    return parts[0];
                }
                if (part === 1) {
                    // return last portion of postcode
                    return parts[1];
                }
                return parts.join(' ');
            }
        }
    }
    exports.default = Ember.Helper.helper(formatPostcode);
});
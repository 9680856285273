define("darwin-portal/controllers/internal/accounts/show/dataprotection-export", ["exports", "ember-concurrency-decorators"], function (exports, _emberConcurrencyDecorators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };
    var __await = undefined && undefined.__await || function (v) {
        return this instanceof __await ? (this.v = v, this) : new __await(v);
    };
    var __asyncGenerator = undefined && undefined.__asyncGenerator || function (thisArg, _arguments, generator) {
        if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
        var g = generator.apply(thisArg, _arguments || []),
            i,
            q = [];
        return i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () {
            return this;
        }, i;
        function verb(n) {
            if (g[n]) i[n] = function (v) {
                return new Promise(function (a, b) {
                    q.push([n, v, a, b]) > 1 || resume(n, v);
                });
            };
        }
        function resume(n, v) {
            try {
                step(g[n](v));
            } catch (e) {
                settle(q[0][3], e);
            }
        }
        function step(r) {
            r.value instanceof __await ? Promise.resolve(r.value.v).then(fulfill, reject) : settle(q[0][2], r);
        }
        function fulfill(value) {
            resume("next", value);
        }
        function reject(value) {
            resume("throw", value);
        }
        function settle(f, v) {
            if (f(v), q.shift(), q.length) resume(q[0][0], q[0][1]);
        }
    };

    var InternalAccountsShowDataprotectionExport = function (_EmberController$ext) {
        _inherits(InternalAccountsShowDataprotectionExport, _EmberController$ext);

        function InternalAccountsShowDataprotectionExport() {
            _classCallCheck(this, InternalAccountsShowDataprotectionExport);

            return _possibleConstructorReturn(this, (InternalAccountsShowDataprotectionExport.__proto__ || Object.getPrototypeOf(InternalAccountsShowDataprotectionExport)).apply(this, arguments));
        }

        _createClass(InternalAccountsShowDataprotectionExport, [{
            key: "sendDataprotectionExport",
            value: function sendDataprotectionExport(account) {
                return __asyncGenerator(this, arguments, regeneratorRuntime.mark(function sendDataprotectionExport_1() {
                    var sendService, toastService, errorMessage;
                    return regeneratorRuntime.wrap(function sendDataprotectionExport_1$(_context) {
                        while (1) {
                            switch (_context.prev = _context.next) {
                                case 0:
                                    sendService = Ember.get(this, 'sendDataprotectionExportService');
                                    toastService = Ember.get(this, 'toast');
                                    _context.prev = 2;
                                    _context.next = 5;
                                    return __await(sendService.request(Ember.get(account, 'id')));

                                case 5:
                                    toastService.info("Data export sent");
                                    _context.next = 12;
                                    break;

                                case 8:
                                    _context.prev = 8;
                                    _context.t0 = _context["catch"](2);
                                    errorMessage = _context.t0.responseJSON.errors[0].title;

                                    toastService.error("Data export failed : " + errorMessage);

                                case 12:
                                case "end":
                                    return _context.stop();
                            }
                        }
                    }, sendDataprotectionExport_1, this, [[2, 8]]);
                }));
            }
        }]);

        return InternalAccountsShowDataprotectionExport;
    }(Ember.Controller.extend({
        // anything which *must* be merged to prototype here
        toast: Ember.inject.service(),
        sendDataprotectionExportService: Ember.inject.service('send-dataprotection-export'),
        account: Ember.computed.alias('model.account.value'),
        loading: Ember.computed.alias('model.account.isRunning')
    }));

    exports.default = InternalAccountsShowDataprotectionExport;

    __decorate([_emberConcurrencyDecorators.task], InternalAccountsShowDataprotectionExport.prototype, "sendDataprotectionExport", null);
});
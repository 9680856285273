define('darwin-portal/models/partner-user', ['exports', 'ember-data', 'ember-data/attr', 'darwin-portal/mixins/validations/partner-user'], function (exports, _emberData, _attr, _partnerUser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var PartnerUser = function (_DS$Model$extend) {
        _inherits(PartnerUser, _DS$Model$extend);

        function PartnerUser() {
            _classCallCheck(this, PartnerUser);

            return _possibleConstructorReturn(this, (PartnerUser.__proto__ || Object.getPrototypeOf(PartnerUser)).apply(this, arguments));
        }

        return PartnerUser;
    }(_emberData.default.Model.extend(_partnerUser.default, {
        // Attributes
        email: (0, _attr.default)('user-email'),
        firstName: (0, _attr.default)('string'),
        lastName: (0, _attr.default)('string'),
        partnerRole: (0, _attr.default)('string'),
        verified: (0, _attr.default)('boolean'),
        token: (0, _attr.default)('string'),
        password: (0, _attr.default)('string'),
        dailyAlertSub: (0, _attr.default)('boolean', { defaultValue: false }),
        weeklyAlertSub: (0, _attr.default)('boolean', { defaultValue: false }),
        monthlyAlertSub: (0, _attr.default)('boolean', { defaultValue: false }),
        infoAlertSub: (0, _attr.default)('boolean', { defaultValue: false }),
        warningAlertSub: (0, _attr.default)('boolean', { defaultValue: false }),
        criticalAlertSub: (0, _attr.default)('boolean', { defaultValue: false }),
        lastActive: (0, _attr.default)('date'),
        notes: (0, _attr.default)('string'),
        createdAt: (0, _attr.default)('date'),
        updatedAt: (0, _attr.default)('date'),
        createdBy: (0, _attr.default)('string'),
        updatedBy: (0, _attr.default)('string'),
        activated: (0, _attr.default)('boolean'),
        notificationsEnabled: Ember.computed('dailyAlertSub', 'weeklyAlertSub', 'monthlyAlertSub', 'infoAlertSub', 'warningAlertSub', 'criticalAlertSub', function () {
            return Ember.get(this, 'dailyAlertSub') || Ember.get(this, 'weeklyAlertSub') || Ember.get(this, 'monthlyAlertSub') || Ember.get(this, 'infoAlertSub') || Ember.get(this, 'warningAlertSub') || Ember.get(this, 'criticalAlertSub');
        }),
        // Relationships
        partner: _emberData.default.belongsTo('partner'),
        role: Ember.computed.alias('partnerRole'),
        name: Ember.computed('firstName', 'lastName', function () {
            return Ember.get(this, 'firstName') + ' ' + Ember.get(this, 'lastName');
        }),
        avatar: Ember.computed(function () {
            return 'http://api.faceholder.io/faces?id=' + Ember.get(this, 'id');
        })
    }));

    exports.default = PartnerUser;
});
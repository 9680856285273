define('darwin-portal/routes/internal/logout', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalLogout = function (_EmberRoute$extend) {
        _inherits(InternalLogout, _EmberRoute$extend);

        function InternalLogout() {
            _classCallCheck(this, InternalLogout);

            return _possibleConstructorReturn(this, (InternalLogout.__proto__ || Object.getPrototypeOf(InternalLogout)).apply(this, arguments));
        }

        return InternalLogout;
    }(Ember.Route.extend({
        session: Ember.inject.service('session'),
        authorizer: Ember.inject.service('authorizer'),
        activate: function activate() {
            var _this2 = this;

            Ember.run.later(function () {
                Ember.get(_this2, 'session').invalidate();
                Ember.get(_this2, 'authorizer').removeContext();
                localStorage.removeItem("partner_id");
            }, 1000);
        }
    }));

    exports.default = InternalLogout;
});
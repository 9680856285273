define('darwin-portal/transforms/date-array', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var DateArray = _emberData.default.Transform.extend({
        deserialize: function deserialize(serialized) {
            return Ember.typeOf(serialized) == "array" ? serialized.map(function (i) {
                return new Date(i);
            }) : [];
        },
        serialize: function serialize(deserialized) {
            var type = Ember.typeOf(deserialized);
            if (type == 'array') {
                return deserialized;
            } else {
                return [];
            }
        }
    });
    exports.default = DateArray;
});
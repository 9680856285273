define('darwin-portal/models/home', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var Home = function (_DS$Model$extend) {
        _inherits(Home, _DS$Model$extend);

        function Home() {
            _classCallCheck(this, Home);

            return _possibleConstructorReturn(this, (Home.__proto__ || Object.getPrototypeOf(Home)).apply(this, arguments));
        }

        return Home;
    }(_emberData.default.Model.extend({
        deviceRegistrationId: (0, _attr.default)('string'),
        serialNumber: (0, _attr.default)('string'),
        connectedDevice: (0, _attr.default)('boolean'),
        upgradePurchasePossible: (0, _attr.default)('boolean'),
        weatherCompensationEnabled: (0, _attr.default)('boolean'),
        friendlyName: (0, _attr.default)('string'),
        boiler: (0, _attr.default)(),
        si: (0, _attr.default)(),
        zones: (0, _attr.default)(),
        zigbeeRepeaters: (0, _attr.default)(),
        controlFaults: (0, _attr.default)(),
        type: (0, _attr.default)('string'),
        telemetry: (0, _attr.default)(),
        allFaults: Ember.computed('controlFaults', 'boiler', function () {
            var controlFaults = Ember.get(this, 'controlFaults').map(function (x) {
                return {
                    description: x.Description,
                    gasSafeEngineerRequired: false,
                    resolutionText: x.ResolutionText,
                    severity: x.Severity,
                    raisedAt: x.RaisedAt,
                    type: x.Type
                };
            });
            var boilerFaults = Ember.get(this, 'boiler.Faults') || [];
            return [].concat(_toConsumableArray(controlFaults), _toConsumableArray(boilerFaults)).sort(function (a, b) {
                return b.raisedAt - a.raisedAt;
            });
        })
    }));

    exports.default = Home;
});
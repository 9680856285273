define('darwin-portal/controllers/internal/ideal-users/new', ['exports', 'darwin-portal/utils/ideal-users/role-options'], function (exports, _roleOptions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    var _get = function get(object, property, receiver) {
        if (object === null) object = Function.prototype;
        var desc = Object.getOwnPropertyDescriptor(object, property);

        if (desc === undefined) {
            var parent = Object.getPrototypeOf(object);

            if (parent === null) {
                return undefined;
            } else {
                return get(parent, property, receiver);
            }
        } else if ("value" in desc) {
            return desc.value;
        } else {
            var getter = desc.get;

            if (getter === undefined) {
                return undefined;
            }

            return getter.call(receiver);
        }
    };

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalIdealUsersNew = function (_EmberController$ext) {
        _inherits(InternalIdealUsersNew, _EmberController$ext);

        function InternalIdealUsersNew() {
            _classCallCheck(this, InternalIdealUsersNew);

            var _this = _possibleConstructorReturn(this, (InternalIdealUsersNew.__proto__ || Object.getPrototypeOf(InternalIdealUsersNew)).apply(this, arguments));

            _this.actions = {
                submit: function submit(newProperty) {
                    var _this2 = this;

                    newProperty.save().then(function () {
                        Ember.get(_this2, 'toast').info("User Created");
                        // this timeout allows time for the search database to update
                        Ember.run.later(function () {
                            _this2.transitionToRoute('internal.ideal-users.index');
                        }, 500);
                    }, function () {
                        // failure
                        Ember.get(_this2, 'toast').error("Error Creating User");
                    });
                },
                invalid: function invalid() {
                    Ember.get(this, 'toast').error("Please Correct Form Errors");
                }
            };
            return _this;
        }

        _createClass(InternalIdealUsersNew, [{
            key: 'init',
            value: function init() {
                _get(InternalIdealUsersNew.prototype.__proto__ || Object.getPrototypeOf(InternalIdealUsersNew.prototype), 'init', this).call(this);
                Ember.set(this, 'userRoleOptions', _roleOptions.default);
            }
        }]);

        return InternalIdealUsersNew;
    }(Ember.Controller.extend({
        // anything which *must* be merged to prototype here
        toast: Ember.inject.service()
    }));

    exports.default = InternalIdealUsersNew;
});
define('darwin-portal/abilities/device', ['exports', 'ember-can'], function (exports, _emberCan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCan.Ability.extend({
        // Service
        authorizer: Ember.inject.service('authorizer'),
        user: Ember.computed.alias('session.Email'),
        // Computed
        isAdmin: Ember.computed.alias('authorizer.isIdealAdmin'),
        // Computed
        isUser: Ember.computed.alias('authorizer.isIdealUser'),
        // Computed
        isIdeal: Ember.computed.alias('authorizer.isIdeal'),
        isActiveUser: Ember.computed.alias('authorizer.isActiveUser'),
        // any ideal users can add a device
        canCreate: Ember.computed.alias('isActiveUser'),
        // any ideal users can read a device
        canRead: Ember.computed.alias('isActiveUser'),
        // only admins can update a device
        canUpdate: Ember.computed.alias('isActiveUser'),
        // only admins can archive a device
        canArchive: Ember.computed.alias('isActiveUser'),
        // only admins can list all devices
        canNavigate: Ember.computed.alias('isActiveUser'),
        // only admins can list all devices
        canChangeMaxFlowTemp: Ember.computed.alias('authorizer.isIdealOrPartnerAdmin'),
        canUnpairPrt: Ember.computed.alias('isAdmin')
    });
});
define("darwin-portal/controllers/internal/partner-device/prt", ["exports", "darwin-portal/mixins/controllers/partner-device", "ember-concurrency-decorators", "darwin-portal/helpers/format-titlecase", "moment"], function (exports, _partnerDevice, _emberConcurrencyDecorators, _formatTitlecase, _moment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    /** @format */
    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var PRTScreen = function (_EmberController$ext) {
        _inherits(PRTScreen, _EmberController$ext);

        function PRTScreen() {
            _classCallCheck(this, PRTScreen);

            return _possibleConstructorReturn(this, (PRTScreen.__proto__ || Object.getPrototypeOf(PRTScreen)).apply(this, arguments));
        }

        _createClass(PRTScreen, [{
            key: "unpairPrt",
            value: regeneratorRuntime.mark(function unpairPrt(deviceRegistrationId, zone) {
                var _deviceRegistrationId, _deviceRegistrationId2, _timestamp, siId, prtId, requestId, unpairPrtService, zones, updatedZones;

                return regeneratorRuntime.wrap(function unpairPrt$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                _deviceRegistrationId = deviceRegistrationId.split('_'), _deviceRegistrationId2 = _slicedToArray(_deviceRegistrationId, 2), _timestamp = _deviceRegistrationId2[0], siId = _deviceRegistrationId2[1];
                                prtId = zone.ZoneId[0];
                                requestId = siId + "_" + prtId;
                                unpairPrtService = this.get('unpairPrtService');
                                _context.next = 6;
                                return unpairPrtService.request(requestId, "DELETE");

                            case 6:
                                zones = Ember.get(this, 'model.home.value.zones');
                                updatedZones = zones.filter(function (x) {
                                    return x.ZoneId[0] !== zone.ZoneId[0];
                                });

                                Ember.set(this, 'model.home.value.zones', updatedZones);
                                Ember.get(this, 'toast').info(zone.FriendlyName + " removed");
                                this.transitionToRoute('internal.partner-device.system-dashboard');

                            case 11:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, unpairPrt, this);
            })
        }]);

        return PRTScreen;
    }(Ember.Controller.extend(_partnerDevice.default, {
        // anything which *must* be merged to prototype here
        loading: Ember.computed.alias('model.home.isRunning'),
        unpairPrtService: Ember.inject.service('unpair-prt'),
        queryParams: 'id',
        id: null,
        selectedZoneId: undefined,
        zones: undefined,
        zoneOptions: Ember.computed('zones', function () {
            var zones = Ember.get(this, 'zones');
            if (zones) {
                var result = zones.map(function (x) {
                    return {
                        label: x.ZoneId[1] === 5 ? 'Halo Heating' : 'Halo Hot Water',
                        value: x.ZoneId
                    };
                });
                return result;
            } else {
                return [];
            }
        }),
        schedule: undefined,
        prt: Ember.computed('home', function () {
            var _this2 = this;

            var device = Ember.get(this, 'home');
            if (device && this.id) {
                var result = device.zones.find(function (zone) {
                    return zone.ZoneId[0].toLowerCase() === _this2.id.toLowerCase();
                });
                // if the model identifier is a single zone Combi with DHW, fetch the DHW zone
                var dhwZone = result.ModelIdentifier === 'ELBSingleCHHW' ? device.zones.find(function (zone) {
                    return zone.ZoneId[1] === 6;
                }) : null;
                Ember.set(this, 'zones', [result, dhwZone]);
                if (!Ember.get(this, 'selectedZoneId')) {
                    Ember.set(this, 'selectedZoneId', result.ZoneId);
                    Ember.set(this, 'schedule', parseSchedule(result));
                }
                var dhwMode = function dhwMode() {
                    var mode = dhwZone.TemperatureMode;
                    var activeDay = parseSchedule(dhwZone).find(function (days) {
                        var events = days.events;
                        var active = events.find(function (event) {
                            return event.active === true;
                        });
                        return active;
                    });
                    var currentEvent = activeDay.events.find(function (event) {
                        return event.active === true;
                    });
                    var isAutoOffDhw = !currentEvent.on;
                    if (dhwZone.HolidayMode === 3 || mode === 'Off') {
                        return 'OFF';
                    }
                    if (mode === 'Auto') {
                        if (isAutoOffDhw) {
                            return 'OFF';
                        }
                        return 'ON';
                    }
                    if (mode === 'OverrideNext' || mode === 'OverridePlus') {
                        return 'ON';
                    }
                    return mode;
                };
                return Object.assign({ prtName: dhwZone ? 'Halo Heating & Hot Water' : result.FriendlyName + " (PRT)", flameState: result.Flame ? 'On' : 'Off', batteryLevel: (0, _formatTitlecase.toTitleCase)(result.BatteryLevel) + " (Voltage: " + result.BatteryVoltage + ")", overrideEnd: (0, _moment.default)(result.OverrideEndtime).format("DD/MM/YYYY hh:mmA"), holidayMode: result.HolidayMode === 3 ? "Active" : "Inactive", holidayModeEnd: (0, _moment.default)(result.OverrideEndtime).format("DD/MM/YYYY hh:mmA"), roomThermistor: result.LocalTemperature ? (result.LocalTemperature / 100).toFixed(1) + "\xB0" : 'Unknown', setpoint: result.HeatingSetpoint ? (result.HeatingSetpoint / 100).toFixed(1) + "\xB0" : 'Unknown', dhwZone: dhwZone ? Object.assign({}, dhwZone, { flameState: dhwZone.Flame ? 'On' : 'Off', mode: dhwMode(), overrideEnd: (0, _moment.default)(dhwZone.OverrideEndtime).format("DD/MM/YYYY hh:mmA"), schedule: parseSchedule(dhwZone), flowTemp: device.telemetry && device.telemetry.MeanFlowTemp ? device.telemetry.MeanFlowTemp.toFixed(1) + "\xB0" : 'N/A', temperatureMode: dhwZone.TemperatureMode === 'OverrideNext' || dhwZone.TemperatureMode === 'OverridePlus' ? 'Boost' : dhwZone.TemperatureMode }) : null, schedule: parseSchedule(result), activeFaults: device.controlFaults.filter(function (cf) {
                        return cf.ZoneId && cf.ZoneId[0] === result.ZoneId[0] && cf.ZoneId[1] == result.ZoneId[1];
                    }), childLock: {
                        text: result.ChildLock ? 'Active' : 'Inactive',
                        color: result.ChildLock ? 'online' : 'offline'
                    }, optimisedStart: {
                        text: result.OptimisedStartEnabled ? 'Active' : 'Inactive',
                        color: result.OptimisedStartEnabled ? 'online' : 'offline'
                    }, optimisedStop: {
                        text: result.OptimisedStopEnabled ? 'Active' : 'Inactive',
                        color: result.OptimisedStopEnabled ? 'online' : 'offline'
                    }, delayedStart: {
                        text: result.DelayedStartEnabled ? 'Active' : 'Inactive',
                        color: result.DelayedStartEnabled ? 'online' : 'offline'
                    }, identifyMode: {
                        text: result.IdentifyModeActive ? 'Active' : 'Inactive',
                        color: result.IdentifyModeActive ? 'online' : 'offline'
                    } }, result);
            }
            return null;
        }),
        toast: Ember.inject.service(),
        actions: {
            unpairPrt: function unpairPrt(deviceRegistrationId, zone) {
                var unpairService = Ember.get(this, 'unpairPrt');
                unpairService.perform(deviceRegistrationId, zone);
            },
            onZoneSelected: function onZoneSelected(zoneId) {
                Ember.set(this, 'selectedZoneId', zoneId);
                var zones = Ember.get(this, 'zones');
                if (zones && zones.length > 0) {
                    var selectedZone = zones.find(function (x) {
                        return x.ZoneId === zoneId;
                    });
                    Ember.set(this, 'schedule', parseSchedule(selectedZone));
                }
            }
        }
    }));

    exports.default = PRTScreen;

    __decorate([_emberConcurrencyDecorators.task], PRTScreen.prototype, "unpairPrt", null);
    var currentDay = (0, _moment.default)().format('dddd');
    var midnight = (0, _moment.default)().clone().startOf('day');
    var minutesSinceMidnight = (0, _moment.default)().diff(midnight, 'minutes');
    var parseSchedule = function parseSchedule(zone) {
        return zone.Schedule.map(function (x) {
            if (currentDay !== x.day) {
                return Object.assign({}, x, { events: x.events.map(function (x) {
                        return Object.assign({}, x, { active: false });
                    }) });
            }
            var activeFound = false;
            return Object.assign({}, x, { events: x.events.map(function (y, i) {
                    if (i === 0 && y.startTime >= minutesSinceMidnight || minutesSinceMidnight < y.endTime && !activeFound || !y.endTime && !activeFound) {
                        activeFound = true;
                        return Object.assign({}, y, { active: true });
                    } else {
                        return Object.assign({}, y, { active: false });
                    }
                }) });
        });
    };
});
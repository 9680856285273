define('darwin-portal/mixins/adapters/admin-adapter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        namespace: 'portal/admin',
        pathForType: function pathForType(type) {
            return this._super(type).replace('admin-', '');
        },
        findRecord: function findRecord(store, type, id, snapshot) {
            if (Ember.get(snapshot, 'adapterOptions')) {
                if (Ember.get(snapshot.adapterOptions, 'partnerId')) {
                    var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
                    var query = {
                        filter: {
                            partnerId: Ember.get(snapshot.adapterOptions, 'partnerId')
                        }
                    };
                    return this.ajax(url, 'GET', { data: query });
                } else {
                    return this._super.apply(this, arguments);
                }
            } else {
                return this._super.apply(this, arguments);
            }
        }
    });
});
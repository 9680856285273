define('darwin-portal/components/device-building-performance-chart', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var DeviceBuildingPerformanceChart = function (_EmberComponent$exte) {
        _inherits(DeviceBuildingPerformanceChart, _EmberComponent$exte);

        function DeviceBuildingPerformanceChart() {
            _classCallCheck(this, DeviceBuildingPerformanceChart);

            var _this = _possibleConstructorReturn(this, (DeviceBuildingPerformanceChart.__proto__ || Object.getPrototypeOf(DeviceBuildingPerformanceChart)).apply(this, arguments));

            _this.options = {
                tooltips: {
                    mode: 'index'
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'hour',
                            displayFormats: {
                                hour: 'DD/MM hA'
                            }
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Temperature (C)'
                        }
                    }]
                }
            };
            _this.data = Ember.computed('telemetry', function () {
                var telemetry = _this.get('telemetry');
                var flowTempData = telemetry.map(function (a) {
                    return {
                        y: a.flowWaterTemperatureMean,
                        x: a.timestamp
                    };
                });
                var returnTempData = telemetry.map(function (a) {
                    return {
                        y: a.returnWaterTemperatureMean,
                        x: a.timestamp
                    };
                });
                var controlSetpointData = telemetry.map(function (a) {
                    return {
                        y: a.controlSetpointMean,
                        x: a.timestamp
                    };
                });
                var roomTempData = telemetry.map(function (a) {
                    return {
                        y: a.remoteRoomTemperature1Mean,
                        x: a.timestamp
                    };
                });
                var labels = telemetry.map(function (a) {
                    return a.timestamp;
                });
                return {
                    labels: labels,
                    datasets: [{
                        label: 'Flow Temperature',
                        data: flowTempData,
                        borderWidth: 1,
                        borderColor: '#00698E',
                        backgroundColor: '#00698E',
                        fill: false,
                        pointRadius: 1
                    }, {
                        label: 'Return Temperature',
                        data: returnTempData,
                        borderWidth: 1,
                        borderColor: '#6f3089',
                        backgroundColor: '#6f3089',
                        fill: false,
                        pointRadius: 2
                    }, {
                        label: 'Boiler Setpoint',
                        data: controlSetpointData,
                        borderWidth: 1,
                        borderColor: '#388659',
                        backgroundColor: '#388659',
                        fill: false,
                        pointRadius: 2
                    }, {
                        label: 'Room Temperature',
                        data: roomTempData,
                        borderWidth: 1,
                        borderColor: '#8F2D56',
                        backgroundColor: '#8F2D56',
                        fill: false,
                        pointRadius: 1
                    }]
                };
            });
            return _this;
        }

        return DeviceBuildingPerformanceChart;
    }(Ember.Component.extend({}));

    exports.default = DeviceBuildingPerformanceChart;
});
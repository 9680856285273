define('darwin-portal/models/device', ['exports', 'ember-data', 'ember-data/attr', 'darwin-portal/mixins/validations/partner-device', 'moment'], function (exports, _emberData, _attr, _partnerDevice, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var Device = function (_DS$Model$extend) {
        _inherits(Device, _DS$Model$extend);

        function Device() {
            _classCallCheck(this, Device);

            return _possibleConstructorReturn(this, (Device.__proto__ || Object.getPrototypeOf(Device)).apply(this, arguments));
        }

        return Device;
    }(_emberData.default.Model.extend(_partnerDevice.default, {
        type: (0, _attr.default)('string'),
        serialNumber: (0, _attr.default)('string'),
        printedSerialNumber: (0, _attr.default)('string'),
        firmware: (0, _attr.default)('string'),
        status: (0, _attr.default)('string'),
        lastSeenAt: (0, _attr.default)('date'),
        lastReportedAt: (0, _attr.default)('date'),
        lastConnectedAt: (0, _attr.default)('date'),
        insertedAt: (0, _attr.default)('date'),
        registeredAt: (0, _attr.default)('date'),
        updatedAt: (0, _attr.default)('date'),
        lastServiceAt: (0, _attr.default)('date', {
            defaultValue: function defaultValue() {
                return null;
            }
        }),
        serviceIntervalDays: (0, _attr.default)('number'),
        inLockout: (0, _attr.default)('boolean'),
        maxFlowTemp: (0, _attr.default)('number'),
        boilerModel: (0, _attr.default)('string'),
        boilerModelName: (0, _attr.default)(),
        maxCapacity: (0, _attr.default)(),
        boilerProductTypeId: (0, _attr.default)(),
        minFanSpeed: (0, _attr.default)(),
        maxCHfanSpeed: (0, _attr.default)(),
        maxChFanSpeed: Ember.computed.alias('maxCHfanSpeed'),
        maxDhWfanSpeed: (0, _attr.default)(),
        maxDhwFanSpeed: Ember.computed.alias('maxDhWfanSpeed'),
        telemetry: _emberData.default.hasMany('telemetry'),
        haloTelemetry: _emberData.default.hasMany('halo-telemetry'),
        haloGasConsumption: _emberData.default.attr(),
        siCommands: _emberData.default.hasMany('si-command'),
        property: _emberData.default.belongsTo('property'),
        resetCount: (0, _attr.default)('number'),
        // These may change when integrated with API
        alarmCount: (0, _attr.default)('number'),
        isHalo: Ember.computed.equal('type', 'Halo'),
        haloCloudLicense: (0, _attr.default)('boolean'),
        firmwareGroup: _emberData.default.belongsTo('firmware-group', { async: false }),
        signalStrength: Ember.computed('telemetry', function () {
            var telemetry = this.get('telemetry').sortBy('timestamp').get('lastObject');
            if (telemetry) {
                return telemetry.signalStrength;
            }
            return 0;
        }),
        currentTelemetry: Ember.computed('telemetry', function () {
            var telemetry = this.get('telemetry').sortBy('timestamp').get('lastObject');
            if (telemetry) {
                return telemetry;
            }
            return null;
        }),
        signalQuality: Ember.computed('telemetry', function () {
            var telemetry = this.get('telemetry').sortBy('timestamp').get('lastObject');
            if (telemetry) {
                return telemetry.signalQuality;
            }
            return 0;
        }),
        serviceDueAt: Ember.computed('lastServiceAt', 'serviceIntervalDays', function () {
            var lastServiceAt = Ember.get(this, 'lastServiceAt');
            var serviceIntervalDays = Ember.get(this, 'serviceIntervalDays');
            if (lastServiceAt && serviceIntervalDays) {
                return (0, _moment.default)(lastServiceAt).add(serviceIntervalDays, 'days').toDate();
            } else {
                return undefined;
            }
        }),
        propertyAddress: Ember.computed('property.{addressLine1,addressCity,addressPostcode}', function () {
            var postcode = Ember.get(this, 'property.addressPostcode');
            var postcodeString = postcode ? ', ' + postcode : '';
            return Ember.get(this, 'property.addressLine1') + ', ' + Ember.get(this, 'property.addressCity') + postcodeString;
        }),
        displaySerialNumber: Ember.computed('serialNumber', 'printedSerialNumber', function () {
            if (Ember.get(this, 'printedSerialNumber')) {
                return Ember.get(this, 'printedSerialNumber');
            } else {
                return Ember.get(this, 'serialNumber');
            }
        }),
        displayTitle: Ember.computed('isHalo', 'propertyAddress', 'displaySerialNumber', function () {
            var isHalo = Ember.get(this, 'isHalo');
            var propertyAddress = Ember.get(this, 'propertyAddress');
            var displaySerialNumber = Ember.get(this, 'displaySerialNumber');
            if (isHalo) {
                return displaySerialNumber;
            } else {
                return propertyAddress;
            }
        }),
        resetLimitExceeded: Ember.computed('resetCount', function () {
            return Ember.get(this, 'resetCount') >= 3;
        })
    }));

    exports.default = Device;
});
define('darwin-portal/controllers/internal/accounts/show/devices', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalAccountsShowDevices = function (_EmberController$ext) {
        _inherits(InternalAccountsShowDevices, _EmberController$ext);

        function InternalAccountsShowDevices() {
            _classCallCheck(this, InternalAccountsShowDevices);

            return _possibleConstructorReturn(this, (InternalAccountsShowDevices.__proto__ || Object.getPrototypeOf(InternalAccountsShowDevices)).apply(this, arguments));
        }

        return InternalAccountsShowDevices;
    }(Ember.Controller.extend({
        // anything which *must* be merged to prototype here
        account: Ember.computed.alias('model.account.value'),
        loading: Ember.computed.alias('model.account.isRunning')
    }));

    exports.default = InternalAccountsShowDevices;
});
define('darwin-portal/routes/internal/halo-firmware/index-redirect', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalHaloFirmware = function (_EmberRoute$extend) {
        _inherits(InternalHaloFirmware, _EmberRoute$extend);

        function InternalHaloFirmware() {
            _classCallCheck(this, InternalHaloFirmware);

            return _possibleConstructorReturn(this, (InternalHaloFirmware.__proto__ || Object.getPrototypeOf(InternalHaloFirmware)).apply(this, arguments));
        }

        _createClass(InternalHaloFirmware, [{
            key: 'beforeModel',
            value: function beforeModel() {
                // automatically pluralise if a user hits the wrong url
                this.transitionTo('internal.halo-firmwares');
            }
        }]);

        return InternalHaloFirmware;
    }(Ember.Route.extend({
        // anything which *must* be merged to prototype here
    }));

    exports.default = InternalHaloFirmware;
});
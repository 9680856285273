define('darwin-portal/services/send-dataprotection-export', ['exports', 'darwin-portal/mixins/services/ajax-service'], function (exports, _ajaxService) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var SendDataprotectionExport = function (_EmberService$extend) {
        _inherits(SendDataprotectionExport, _EmberService$extend);

        function SendDataprotectionExport() {
            _classCallCheck(this, SendDataprotectionExport);

            return _possibleConstructorReturn(this, (SendDataprotectionExport.__proto__ || Object.getPrototypeOf(SendDataprotectionExport)).apply(this, arguments));
        }

        return SendDataprotectionExport;
    }(Ember.Service.extend(_ajaxService.default, {
        endpoint: 'portal/accounts/send-dataprotection-export',
        type: 'send-dataprotection-export'
    }));

    exports.default = SendDataprotectionExport;
});
define('darwin-portal/serializers/device-license-change', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var DeviceLicenseChange = function (_DS$JSONAPISerializer) {
        _inherits(DeviceLicenseChange, _DS$JSONAPISerializer);

        function DeviceLicenseChange() {
            _classCallCheck(this, DeviceLicenseChange);

            var _this = _possibleConstructorReturn(this, (DeviceLicenseChange.__proto__ || Object.getPrototypeOf(DeviceLicenseChange)).apply(this, arguments));

            _this.attrs = {
                existingNotes: 'Notes',
                paymentReference: 'PaymentReference'
            };
            return _this;
        }

        return DeviceLicenseChange;
    }(_emberData.default.JSONAPISerializer.extend({}));

    exports.default = DeviceLicenseChange;
});
define('darwin-portal/models/admin-partner-user', ['exports', 'darwin-portal/models/partner-user'], function (exports, _partnerUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var AdminPartnerUser = function (_PartnerUser) {
    _inherits(AdminPartnerUser, _PartnerUser);

    function AdminPartnerUser() {
      _classCallCheck(this, AdminPartnerUser);

      return _possibleConstructorReturn(this, (AdminPartnerUser.__proto__ || Object.getPrototypeOf(AdminPartnerUser)).apply(this, arguments));
    }

    return AdminPartnerUser;
  }(_partnerUser.default);

  exports.default = AdminPartnerUser;
});
define('darwin-portal/mixins/controllers/paginatable', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        // Attributes
        queryParams: ['page', 'size'],
        page: 1,
        size: 25,
        // Actions
        actions: {
            paginateResults: function paginateResults(props) {
                // @ts-ignore: ember typescript definition requires route which we don't need
                this.transitionToRoute({
                    queryParams: props
                });
            }
        }
    });
});
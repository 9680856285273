define('darwin-portal/utils/halo-firmware/action-routes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var ACTION_ROUTES = [{
        title: 'View Firmware',
        route: 'internal.halo-firmware.read',
        ability: 'read halo-firmware'
    }];
    exports.default = ACTION_ROUTES;
});
define("darwin-portal/helpers/traffic-light-by-string", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.trafficLightByString = trafficLightByString;
    function trafficLightByString(str) {
        switch (str.toLowerCase()) {
            // green
            case "excellent":
                return "online";
            case "high":
                return "online";
            // amber
            case "unknown":
                return "orange";
            case "low":
                return "orange";
            case "good":
                return "orange";
            // offline
            case "poor":
                return "offline";
            // hide
            default:
                return "null";
        }
    }
});
define('darwin-portal/components/prt-section', ['exports', 'darwin-portal/helpers/find-option-by', 'ember-bem-sauce/mixins/bem-component'], function (exports, _findOptionBy, _bemComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var PrtSection = function (_EmberComponent$exte) {
        _inherits(PrtSection, _EmberComponent$exte);

        function PrtSection() {
            _classCallCheck(this, PrtSection);

            return _possibleConstructorReturn(this, (PrtSection.__proto__ || Object.getPrototypeOf(PrtSection)).apply(this, arguments));
        }

        return PrtSection;
    }(Ember.Component.extend(_bemComponent.default, {
        base: 'prt-section',
        formattedTitle: Ember.computed('selectedZoneId', function () {
            var title = Ember.get(this, 'title');
            var selectedZoneId = Ember.get(this, 'selectedZoneId');
            var zoneOptions = Ember.get(this, 'zoneOptions');
            if (selectedZoneId && zoneOptions) {
                return title + ': ' + (0, _findOptionBy.findOptionBy)([zoneOptions, 'value', selectedZoneId]).label;
            }
            return title;
        })
    }));

    exports.default = PrtSection;

    ;
});
define("darwin-portal/controllers/internal/ideal-user/edit", ["exports", "darwin-portal/utils/ideal-users/role-options", "ember-concurrency-decorators"], function (exports, _roleOptions, _emberConcurrencyDecorators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    var _get = function get(object, property, receiver) {
        if (object === null) object = Function.prototype;
        var desc = Object.getOwnPropertyDescriptor(object, property);

        if (desc === undefined) {
            var parent = Object.getPrototypeOf(object);

            if (parent === null) {
                return undefined;
            } else {
                return get(parent, property, receiver);
            }
        } else if ("value" in desc) {
            return desc.value;
        } else {
            var getter = desc.get;

            if (getter === undefined) {
                return undefined;
            }

            return getter.call(receiver);
        }
    };

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var InternalIdealUserEdit = function (_EmberController$ext) {
        _inherits(InternalIdealUserEdit, _EmberController$ext);

        function InternalIdealUserEdit() {
            _classCallCheck(this, InternalIdealUserEdit);

            var _this = _possibleConstructorReturn(this, (InternalIdealUserEdit.__proto__ || Object.getPrototypeOf(InternalIdealUserEdit)).apply(this, arguments));

            // Controllers
            _this.userController = Ember.inject.controller("internal.ideal-user");
            // normal class body definition here
            _this.actions = {
                invalid: function invalid() {
                    Ember.get(this, 'toast').error("Please Correct Form Errors");
                }
            };
            return _this;
        }

        _createClass(InternalIdealUserEdit, [{
            key: "init",
            value: function init() {
                _get(InternalIdealUserEdit.prototype.__proto__ || Object.getPrototypeOf(InternalIdealUserEdit.prototype), "init", this).call(this);
                Ember.set(this, "userRoleOptions", _roleOptions.default);
            }
        }, {
            key: "saveUser",
            value: regeneratorRuntime.mark(function saveUser(idealUser) {
                return regeneratorRuntime.wrap(function saveUser$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                _context.prev = 0;
                                _context.next = 3;
                                return idealUser.save();

                            case 3:
                                // success
                                Ember.get(this, 'toast').info("User Updated");
                                _context.next = 9;
                                break;

                            case 6:
                                _context.prev = 6;
                                _context.t0 = _context["catch"](0);

                                // failure
                                Ember.get(this, 'toast').error("Error Updating Users");

                            case 9:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, saveUser, this, [[0, 6]]);
            })
        }, {
            key: "resendEmail",
            value: regeneratorRuntime.mark(function resendEmail(id) {
                var resendService;
                return regeneratorRuntime.wrap(function resendEmail$(_context2) {
                    while (1) {
                        switch (_context2.prev = _context2.next) {
                            case 0:
                                _context2.prev = 0;
                                resendService = this.get('resendService');
                                _context2.next = 4;
                                return resendService.request(id);

                            case 4:
                                // success
                                Ember.get(this, 'toast').info("Email Send");
                                _context2.next = 10;
                                break;

                            case 7:
                                _context2.prev = 7;
                                _context2.t0 = _context2["catch"](0);

                                // failure
                                Ember.get(this, 'toast').error("Error Sending Email");

                            case 10:
                            case "end":
                                return _context2.stop();
                        }
                    }
                }, resendEmail, this, [[0, 7]]);
            })
        }]);

        return InternalIdealUserEdit;
    }(Ember.Controller.extend({
        // Services
        resendService: Ember.inject.service('resend-activation'),
        toast: Ember.inject.service(),
        // Computed
        loading: Ember.computed.alias('userController.loading'),
        user: Ember.computed.alias('userController.user')
    }));

    exports.default = InternalIdealUserEdit;

    __decorate([_emberConcurrencyDecorators.task], InternalIdealUserEdit.prototype, "saveUser", null);
    __decorate([_emberConcurrencyDecorators.task], InternalIdealUserEdit.prototype, "resendEmail", null);
});
define('darwin-portal/models/ideal-user', ['exports', 'ember-data', 'darwin-portal/mixins/validations/ideal-user'], function (exports, _emberData, _idealUser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var attr = _emberData.default.attr,
        Model = _emberData.default.Model;

    var IdealUser = function (_Model$extend) {
        _inherits(IdealUser, _Model$extend);

        function IdealUser() {
            _classCallCheck(this, IdealUser);

            return _possibleConstructorReturn(this, (IdealUser.__proto__ || Object.getPrototypeOf(IdealUser)).apply(this, arguments));
        }

        return IdealUser;
    }(Model.extend(_idealUser.default, {
        // Attributes
        email: attr('user-email'),
        firstName: attr('string'),
        lastName: attr('string'),
        role: attr('string'),
        verified: attr('boolean'),
        token: attr('string'),
        password: attr('string'),
        lastActive: attr('date'),
        notes: attr('string'),
        createdAt: attr('date'),
        updatedAt: attr('date'),
        createdBy: attr('string'),
        updatedBy: attr('string'),
        // Relationships
        activities: _emberData.default.hasMany('activity'),
        // Computed
        name: Ember.computed('firstName', 'lastName', function () {
            return Ember.get(this, 'firstName') + ' ' + Ember.get(this, 'lastName');
        }),
        avatar: Ember.computed(function () {
            return 'http://api.faceholder.io/faces?id=' + Ember.get(this, 'id');
        })
    }));

    exports.default = IdealUser;
});
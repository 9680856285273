define('darwin-portal/components/uxs-form-datetime', ['exports', 'ember-ux-sauce/components/uxs-form-datetime'], function (exports, _uxsFormDatetime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsFormDatetime.default;
    }
  });
});
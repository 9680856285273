define('darwin-portal/mixins/validations/objects/forgot-password-form', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
            type: 'email'
        })]
    });
});
define('darwin-portal/utils/admin-partner-users/action-routes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var ACTION_ROUTES = [{
        title: 'View User',
        route: 'internal.partner.user.index',
        ability: 'read partner-user'
    }, {
        title: 'Update User',
        // needs updating
        route: 'internal.partner.user.index',
        ability: 'update partner-user'
    }, {
        title: 'User Activities',
        // needs updating
        route: 'internal.partner.user.index',
        ability: 'read activities in partner-user'
    }, {
        title: 'Audit Trail',
        // needs updating
        route: 'internal.partner.user.index',
        ability: 'read audits in partner-user'
    }, {
        title: 'Archive User',
        // needs updating
        route: 'internal.partner.user.index',
        ability: 'archive partner-user'
    }];
    exports.default = ACTION_ROUTES;
});
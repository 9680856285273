define('darwin-portal/controllers/internal/partner-device/si', ['exports', 'darwin-portal/mixins/controllers/partner-device', 'moment'], function (exports, _partnerDevice, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var SIScreen = function (_EmberController$ext) {
        _inherits(SIScreen, _EmberController$ext);

        function SIScreen() {
            _classCallCheck(this, SIScreen);

            return _possibleConstructorReturn(this, (SIScreen.__proto__ || Object.getPrototypeOf(SIScreen)).apply(this, arguments));
        }

        return SIScreen;
    }(Ember.Controller.extend(_partnerDevice.default, {
        // anything which *must* be merged to prototype here
        loading: Ember.computed.alias('model.home.isRunning'),
        si: Ember.computed('home', function () {
            var home = Ember.get(this, 'home');
            if (home) {
                var device = home.si;
                return Object.assign({ pairingMode: {
                        text: device.PairingModeState == 1 ? 'Active' : 'Inactive',
                        status: device.PairingModeState == 0 ? 'offline' : 'online'
                    }, weatherCompensation: {
                        text: home.weatherCompensationEnabled ? 'Active' : 'Inactive',
                        status: home.weatherCompensationEnabled ? 'online' : 'offline'
                    }, powerupTime: (0, _moment.default)(device.PowerupTime).format("DD/MM/YYYY hh:mmA"), override: {
                        text: device.UserOverridenOn ? 'Active' : 'Inactive',
                        status: !device.UserOverridenOn ? 'offline' : 'online'
                    }, inFailSafe: {
                        text: device.InFailsafe ? 'Active' : 'Inactive',
                        status: !device.InFailsafe ? 'offline' : 'online'
                    }, useHeatCurve: {
                        text: device.HeatCurveEnabled ? 'Active' : 'Inactive',
                        status: !device.HeatCurveEnabled ? 'offline' : 'online'
                    }, heatCurveNumber: device.HeatCurveNumber ? device.HeatCurveNumber : 'N/A', serial: home.serialNumber }, device);
            }
            return null;
        }),
        toast: Ember.inject.service()
    }));

    exports.default = SIScreen;
});
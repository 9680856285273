define('darwin-portal/initializers/request-headers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        Ember.$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
            if (!jqXHR.crossDomain) {
                var service = "service:session";
                var session = application.lookup ? application.lookup(service) : application.__container__.lookup(service);
                var authorizer = "service:authorizer";
                var authorizerSession = application.lookup ? application.lookup(authorizer) : application.__container__.lookup(authorizer);
                var access_token = session.data.authenticated.access_token;

                if (Ember.isPresent(access_token)) {
                    jqXHR.setRequestHeader('Authorization', 'Bearer ' + access_token);
                }
                options.contentType = 'application/vnd.api+json';
                options.beforeSend = function (jqXHR) {
                    jqXHR.setRequestHeader('Accept', 'application/vnd.api+json');
                    jqXHR.setRequestHeader('Content-Type', 'application/vnd.api+json');
                };
                var context = authorizerSession.getContext();
                if (options.type !== "OPTIONS" && context && context.type === "Partner") {
                    jqXHR.setRequestHeader('X-Partner-Id', context.id);
                }
            }
        });
    }
    exports.default = {
        initialize: initialize
    };
});
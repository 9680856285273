define('darwin-portal/abilities/firmware', ['exports', 'ember-can'], function (exports, _emberCan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCan.Ability.extend({
        authorizer: Ember.inject.service('authorizer'),
        canNavigate: Ember.computed.alias('authorizer.isIdealAdmin')
    });
});
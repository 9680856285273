define('darwin-portal/controllers/internal', ['exports', 'darwin-portal/mixins/components/menu-filter'], function (exports, _menuFilter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    var _get = function get(object, property, receiver) {
        if (object === null) object = Function.prototype;
        var desc = Object.getOwnPropertyDescriptor(object, property);

        if (desc === undefined) {
            var parent = Object.getPrototypeOf(object);

            if (parent === null) {
                return undefined;
            } else {
                return get(parent, property, receiver);
            }
        } else if ("value" in desc) {
            return desc.value;
        } else {
            var getter = desc.get;

            if (getter === undefined) {
                return undefined;
            }

            return getter.call(receiver);
        }
    };

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var Internal = function (_EmberController$ext) {
        _inherits(Internal, _EmberController$ext);

        function Internal() {
            _classCallCheck(this, Internal);

            var _this = _possibleConstructorReturn(this, (Internal.__proto__ || Object.getPrototypeOf(Internal)).apply(this, arguments));

            _this._items = [{
                label: "Dashboard",
                route: "internal.dashboard",
                ability: "navigate to dashboard"
            }, {
                label: "Ideal Users",
                route: "internal.ideal-users",
                currentWhen: "internal.ideal-users internal.ideal-user",
                ability: "navigate to ideal-user"
            }, {
                label: "Users",
                route: "internal.partner-users",
                currentWhen: "internal.partner-users",
                ability: "navigate to partner-user"
            }, {
                label: "Partners",
                route: "internal.partners",
                currentWhen: "internal.partners",
                ability: "navigate to partner"
            }, {
                label: "Customers",
                route: "internal.accounts",
                currentWhen: "internal.accounts",
                ability: "navigate all to account"
            }, {
                label: "Properties",
                route: "internal.properties.index",
                currentWhen: "internal.properties",
                ability: "navigate to property"
            }, {
                label: "Devices",
                route: "internal.partner-devices.index",
                currentWhen: "internal.partner-devices internal.partner-device",
                ability: "navigate to partner-device"
            }, {
                label: "Firmware",
                currentWhen: "internal.firmware-groups internal.halo-firmwares",
                ability: "navigate to firmware",
                dropdownItems: [{
                    label: "Firmware",
                    mobileLabel: "Firmware",
                    route: "internal.halo-firmwares"
                }, {
                    label: "Groups",
                    mobileLabel: "Firmware Groups",
                    route: "internal.firmware-groups"
                }]
            }, {
                label: "Alarms",
                route: "internal.alarms.index",
                currentWhen: "internal.alarms internal.alarm",
                ability: "navigate to alarm"
            }, {
                label: "My Account",
                route: "internal.account.update-password",
                currentWhen: "internal.account",
                ability: "navigate to account"
            }, {
                label: "Logout",
                route: "internal.logout",
                currentWhen: "internal.logout"
            }];
            return _this;
        }

        _createClass(Internal, [{
            key: 'init',
            value: function init() {
                // hack to get observer to trigger
                this.get('authorizer');
                this.addObserver('authorizer.context', this, function () {
                    // TODO - move this functionality into MenuFilter
                    var items = Ember.get(this, '_items');
                    var ccan = this.get('can');
                    var filtered = items.filter(function (x) {
                        if (x.ability) {
                            return ccan.can(x.ability);
                        } else {
                            return true;
                        }
                    });
                    Ember.set(this, 'items', filtered);
                });
                _get(Internal.prototype.__proto__ || Object.getPrototypeOf(Internal.prototype), 'init', this).call(this);
            }
        }]);

        return Internal;
    }(Ember.Controller.extend(_menuFilter.default, {
        // Attributes
        name: 'Darwin',
        authorizer: Ember.inject.service('authorizer'),
        showIpAddressWarning: Ember.computed('authorizer.isIdeal', 'model.ipInIdealUserWhitelist', function () {
            var session = Ember.get(this, 'model');
            if (!session) {
                return false;
            }
            var isIdeal = Ember.get(this, 'authorizer.isIdeal');
            var ipInWhitelist = Ember.get(session, 'ipInIdealUserWhitelist');
            if (isIdeal && !ipInWhitelist) {
                return true;
            }
            return false;
        })
    }));

    exports.default = Internal;

    ;
});
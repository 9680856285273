define('darwin-portal/abilities/account', ['exports', 'ember-can'], function (exports, _emberCan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCan.Ability.extend({
        authorizer: Ember.inject.service('authorizer'),
        isActiveUser: Ember.computed.alias('authorizer.isActiveUser'),
        canNavigate: Ember.computed.alias('isActiveUser'),
        canNavigateAll: Ember.computed.alias('authorizer.isIdeal'),
        canRead: Ember.computed.alias('authorizer.isIdeal')
    });
});
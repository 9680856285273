define('darwin-portal/utils/ideal-users/sort-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SORT_OPTIONS = [{
        label: 'Name',
        value: 'firstName'
    },
    // {
    //   label: 'Last logged in',
    //   value: 'lastActive',
    // },
    {
        label: 'Created at',
        value: 'createdAt'
    }];
    exports.default = SORT_OPTIONS;
});
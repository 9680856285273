define('darwin-portal/controllers/internal/partner/partner-devices', ['exports', 'darwin-portal/mixins/controllers/paginatable', 'darwin-portal/mixins/controllers/sortable', 'darwin-portal/utils/partner-users/sort-options', 'darwin-portal/mixins/controllers/searchable'], function (exports, _paginatable, _sortable, _sortOptions, _searchable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPartnerPartnerDevices = function (_EmberController$ext) {
        _inherits(InternalPartnerPartnerDevices, _EmberController$ext);

        function InternalPartnerPartnerDevices() {
            _classCallCheck(this, InternalPartnerPartnerDevices);

            return _possibleConstructorReturn(this, (InternalPartnerPartnerDevices.__proto__ || Object.getPrototypeOf(InternalPartnerPartnerDevices)).apply(this, arguments));
        }

        return InternalPartnerPartnerDevices;
    }(Ember.Controller.extend(_paginatable.default, _sortable.default, _searchable.default, {
        // Computed
        partner: Ember.computed.alias('partnerController.partner'),
        devices: Ember.computed.alias('model.devices.value'),
        meta: Ember.computed.alias('model.devices.value.meta'),
        loading: Ember.computed.or('loadingDevices', 'loadingPartner'),
        loadingDevices: Ember.computed.alias('model.devices.isRunning'),
        loadingPartner: Ember.computed.alias('partnerController.loading'),
        // Properties
        sort: 'firstName',
        sortOptions: _sortOptions.default,
        // Controllers
        partnerController: Ember.inject.controller('internal.partner')
    }));

    exports.default = InternalPartnerPartnerDevices;
});
define('darwin-portal/components/dashboard/system-status-tile', ['exports', 'ember-concurrency', 'ember-bem-sauce/mixins/bem-component', 'moment', 'darwin-portal/helpers/check-if-aws-service-used'], function (exports, _emberConcurrency, _bemComponent, _moment, _checkIfAwsServiceUsed) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var SystemStatusComponent = function (_EmberComponent$exte) {
        _inherits(SystemStatusComponent, _EmberComponent$exte);

        function SystemStatusComponent() {
            _classCallCheck(this, SystemStatusComponent);

            return _possibleConstructorReturn(this, (SystemStatusComponent.__proto__ || Object.getPrototypeOf(SystemStatusComponent)).apply(this, arguments));
        }

        return SystemStatusComponent;
    }(Ember.Component.extend(_bemComponent.default, {
        base: 'system-dashboard-tiles',
        loadStatusService: Ember.inject.service('load-system-status'),
        // State
        awsStatusText: '',
        awsStatusDescription: '',
        awsStatusColour: 0,
        awsServiceMessages: [],
        init: function init() {
            var _this2 = this;

            this._super.apply(this, arguments);
            Ember.run.schedule('render', function () {
                var status = _this2.loadStatus.perform();
            });
        },

        // Tasks
        loadStatus: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
            var loadStatusService, property, onSuccess, onError;
            return regeneratorRuntime.wrap(function _callee$(_context) {
                while (1) {
                    switch (_context.prev = _context.next) {
                        case 0:
                            onError = function onError() {
                                property.set('awsStatusText', 'Outage');
                                property.set('awsStatusDescription', 'Users may not be able to access the app or the portal');
                                property.set('awsStatusColour', 'offline');
                                property.set('awsServiceMessages', ['We can`t connect to AWS, some or all services may be unavailable']);
                            };

                            onSuccess = function onSuccess(data) {
                                var issuesAffectingIdeal = [];
                                if (data.current.length > 0) {
                                    var issues = data.current.reverse();
                                    issuesAffectingIdeal = issues.filter(function (message) {
                                        return (0, _checkIfAwsServiceUsed.checkIfAWSServiceUsed)(message.service);
                                    }).map(function (message) {
                                        var fromNow = _moment.default.unix(message.date).fromNow();
                                        return fromNow + ' - ' + message.summary + ' - ' + message.service_name;
                                    });
                                }
                                if (issuesAffectingIdeal.length === 0) {
                                    property.set('awsStatusText', 'Online');
                                    property.set('awsStatusDescription', 'All AWS Services online');
                                    property.set('awsStatusColour', 'online');
                                    property.set('awsServiceMessages', ['All AWS services are currently online and available']);
                                } else if (issuesAffectingIdeal.length > 0) {
                                    property.set('awsStatusText', 'Degraded');
                                    property.set('awsStatusDescription', 'Users may see degraded performance in the app and on the portal');
                                    property.set('awsStatusColour', 'orange');
                                    property.set('awsServiceMessages', issuesAffectingIdeal);
                                }
                            };

                            loadStatusService = this.get('loadStatusService');
                            property = this;
                            _context.next = 6;
                            return loadStatusService.request('', "GET", onSuccess, onError);

                        case 6:
                        case 'end':
                            return _context.stop();
                    }
                }
            }, _callee, this);
        }))
    }));

    exports.default = SystemStatusComponent;
});
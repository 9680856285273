define('darwin-portal/components/uxs-list-menu', ['exports', 'ember-ux-sauce/components/uxs-list-menu'], function (exports, _uxsListMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsListMenu.default;
    }
  });
});
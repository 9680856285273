define('darwin-portal/utils/partner-devices/action-routes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var ACTION_ROUTES = exports.ACTION_ROUTES = [{
        title: 'View Device',
        route: 'internal.partner-device.index',
        name: 'index',
        ability: 'create partner-device'
    }, {
        title: 'Update service period',
        route: 'internal.partner-device.set-service',
        name: 'set-service'
    }, {
        title: 'Device Alarms',
        route: 'internal.partner-device.alarms',
        name: 'alarms',
        ability: 'navigate alarm'
    }, {
        title: 'Customers',
        route: 'internal.partner-device.accounts',
        name: 'accounts',
        ability: 'navigate all to account'
    }, {
        title: 'System Dashboard',
        route: 'internal.partner-device.system-dashboard',
        name: 'system-dashboard',
        ability: 'read data partner-device'
    }, {
        title: 'Firmware',
        route: 'internal.partner-device.firmware',
        name: 'firmware',
        ability: 'update firmware partner-device'
    }, {
        title: 'System Faults',
        route: 'internal.partner-device.system-faults',
        name: 'system-faults',
        ability: 'read data partner-device'
    }, {
        title: "Cloud Connection",
        route: 'internal.partner-device.license',
        name: 'license',
        ability: 'navigate all to account'
    }, {
        title: 'SI Executions',
        route: 'internal.partner-device.executions',
        name: 'si-executions',
        ability: 'read data partner-device'
    }, {
        title: 'Device Dashboard',
        route: 'internal.partner-device.boiler-dashboard',
        name: 'building-performance',
        ability: 'read data partner-device'
    }, {
        title: 'Gas Consumption',
        route: 'internal.partner-device.gas-consumption',
        name: 'gas-consumption',
        ability: 'view gas consumption partner-device'
    }, {
        title: 'Archive Device',
        route: 'internal.partner-device.archive',
        name: 'archive',
        ability: 'archive partner-device'
    }];
    var DATA_ACTION_ROUTES = exports.DATA_ACTION_ROUTES = [{
        title: 'Device Dashboard',
        route: 'internal.partner-device.boiler-dashboard',
        name: 'building-performance',
        ability: 'read data partner-device'
    }, {
        title: 'Building Performance',
        route: 'internal.partner-device.building-performance',
        name: 'building-performance',
        ability: 'read data partner-device'
    }, {
        title: 'Boiler Performance',
        route: 'internal.partner-device.boiler-performance',
        name: 'boiler-performance',
        ability: 'read data partner-device'
    }, {
        title: 'Boiler Usage',
        route: 'internal.partner-device.boiler-usage',
        name: 'boiler-usage',
        ability: 'read data partner-device'
    }, {
        title: 'View Device',
        route: 'internal.partner-device.index',
        name: 'index',
        ability: 'create partner-device'
    }];
    exports.default = ACTION_ROUTES;
});
define('darwin-portal/utils/properties/action-routes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var ACTION_ROUTES = [{
        title: 'View Property',
        route: 'internal.property.index',
        ability: 'read property'
    }, {
        title: 'Property Devices',
        route: 'internal.property.devices',
        ability: 'read devices of property'
    }, {
        title: 'Update Property',
        route: 'internal.property.edit',
        ability: 'update property'
    }];
    exports.default = ACTION_ROUTES;
});
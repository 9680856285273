define('darwin-portal/mixins/controllers/sortable', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        queryParams: ['sort'],
        sort: '',
        actions: {
            sortBy: function sortBy(attribute) {
                var sort = Ember.get(this, 'sort');
                if (sort === attribute) {
                    attribute = '-' + sort;
                }
                // @ts-ignore: ember typescript definition requires route which we don't need
                this.transitionToRoute({
                    queryParams: {
                        sort: attribute
                    }
                });
            }
        }
    });
});
define("darwin-portal/controllers/external/set-password", ["exports", "ember-concurrency-decorators"], function (exports, _emberConcurrencyDecorators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var ExternalSetPassword = function (_EmberController$ext) {
        _inherits(ExternalSetPassword, _EmberController$ext);

        function ExternalSetPassword() {
            _classCallCheck(this, ExternalSetPassword);

            var _this = _possibleConstructorReturn(this, (ExternalSetPassword.__proto__ || Object.getPrototypeOf(ExternalSetPassword)).apply(this, arguments));

            _this.loginController = Ember.inject.controller('external/login');
            _this.session = Ember.inject.service('session');
            _this.authState = Ember.inject.service('auth-state');
            return _this;
        }

        _createClass(ExternalSetPassword, [{
            key: "setPasswordTask",
            value: regeneratorRuntime.mark(function setPasswordTask(model) {
                var state, password, authResp, errorMessage;
                return regeneratorRuntime.wrap(function setPasswordTask$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                state = this.get('authState').state;

                                if (!state) {
                                    _context.next = 20;
                                    break;
                                }

                                if (state.userAttributes.email) {
                                    delete state.userAttributes.email;
                                }
                                password = Ember.get(model, 'password');
                                _context.prev = 4;
                                _context.next = 7;
                                return this.get('session').authenticate('authenticator:cognito', { password: password, state: state });

                            case 7:
                                authResp = _context.sent;

                                this.store.unloadAll('set-password');
                                return _context.abrupt("return", authResp);

                            case 12:
                                _context.prev = 12;
                                _context.t0 = _context["catch"](4);
                                errorMessage = void 0;

                                if (_context.t0.name === "InvalidPasswordException") {
                                    errorMessage = "Password does not meet complexity requirements";
                                } else if (_context.t0.name == "InvalidParameterException") {
                                    errorMessage = "All fields must be completed";
                                } else if (_context.t0.message) {
                                    // Typically "New password is required." is returned here if password was empty
                                    errorMessage = _context.t0.message;
                                } else {
                                    errorMessage = "Unknown error";
                                }
                                this.set('errorMessage', errorMessage);

                            case 17:
                                ;
                                _context.next = 21;
                                break;

                            case 20:
                                this.transitionToRoute('external.login');

                            case 21:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, setPasswordTask, this, [[4, 12]]);
            })
        }]);

        return ExternalSetPassword;
    }(Ember.Controller.extend({
        // anything which *must* be merged to prototype here
    }));

    exports.default = ExternalSetPassword;

    __decorate([_emberConcurrencyDecorators.dropTask], ExternalSetPassword.prototype, "setPasswordTask", null);
});
define('darwin-portal/controllers/internal/partner-device', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPartnerDevice = function (_EmberController$ext) {
        _inherits(InternalPartnerDevice, _EmberController$ext);

        function InternalPartnerDevice() {
            _classCallCheck(this, InternalPartnerDevice);

            return _possibleConstructorReturn(this, (InternalPartnerDevice.__proto__ || Object.getPrototypeOf(InternalPartnerDevice)).apply(this, arguments));
        }

        return InternalPartnerDevice;
    }(Ember.Controller.extend({
        // Computed
        device: Ember.computed.alias('model.device.value'),
        home: Ember.computed.alias('model.home.value'),
        loading: Ember.computed.alias('model.device.isRunning'),
        queryParams: ['back'],
        back: 'list'
    }));

    exports.default = InternalPartnerDevice;
});
define('darwin-portal/components/mapbox-gl-popup', ['exports', 'ember-mapbox-gl/components/mapbox-gl-popup'], function (exports, _mapboxGlPopup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mapboxGlPopup.default;
    }
  });
});
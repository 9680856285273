define('darwin-portal/models/account', ['exports', 'ember-data', 'darwin-portal/mixins/validations/account'], function (exports, _emberData, _account) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var attr = _emberData.default.attr;

    var Account = function (_DS$Model$extend) {
        _inherits(Account, _DS$Model$extend);

        function Account() {
            _classCallCheck(this, Account);

            return _possibleConstructorReturn(this, (Account.__proto__ || Object.getPrototypeOf(Account)).apply(this, arguments));
        }

        return Account;
    }(_emberData.default.Model.extend(_account.default, {
        firstName: attr('string'),
        lastName: attr('string'),
        fullName: Ember.computed('firstName', 'lastName', function () {
            return Ember.get(this, 'firstName') + ' ' + Ember.get(this, 'lastName');
        }),
        email: attr('string'),
        lastConnection: attr('date'),
        createdAt: attr('date'),
        termsAgreedAt: attr('date'),
        termsVersion: attr('number'),
        devices: _emberData.default.hasMany('device'),
        acceptedMarketing: attr('boolean')
    }));

    exports.default = Account;
});
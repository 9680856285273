define("darwin-portal/objects/reset-password-form", ["exports", "darwin-portal/mixins/validations/objects/reset-password-form"], function (exports, _resetPasswordForm) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend(_resetPasswordForm.default, {
        email: null,
        verificationCode: null,
        password: null
    });
});
define('darwin-portal/components/device-boiler-usage-chart', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    /*
     * @format
     */
    var diff = function diff(arr) {
        return arr.slice(1).map(function (item, i) {
            return {
                y: item.y - arr[i].y,
                x: item.x
            };
        });
    };

    var DeviceBoilerUsageChart = function (_EmberComponent$exte) {
        _inherits(DeviceBoilerUsageChart, _EmberComponent$exte);

        function DeviceBoilerUsageChart() {
            _classCallCheck(this, DeviceBoilerUsageChart);

            var _this = _possibleConstructorReturn(this, (DeviceBoilerUsageChart.__proto__ || Object.getPrototypeOf(DeviceBoilerUsageChart)).apply(this, arguments));

            _this.options = {
                tooltips: {
                    mode: 'index'
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'hour',
                            displayFormats: {
                                hour: 'DD/MM hA'
                            }
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: false
                        }
                    }]
                }
            };
            _this.data = Ember.computed('telemetry', function () {
                var telemetry = _this.get('telemetry');
                var successfulBurnerStarts = diff(telemetry.map(function (x) {
                    return { y: x.numSuccessfulBurnerStarts, x: x.timestamp };
                }));
                var unsuccessfulBurnerStarts = diff(telemetry.map(function (x) {
                    return { y: x.numUnsuccessfulBurnerStarts, x: x.timestamp };
                }));
                var flameLowSignals = diff(telemetry.map(function (x) {
                    return { y: x.numFlameLowSignals, x: x.timestamp };
                }));
                var chPumpStarts = diff(telemetry.map(function (x) {
                    return { y: x.numChPumpStarts, x: x.timestamp };
                }));
                var dhwPumpStarts = diff(telemetry.map(function (x) {
                    return { y: x.numDhwPumpStarts, x: x.timestamp };
                }));
                var dhwBurnerStarts = diff(telemetry.map(function (x) {
                    return { y: x.numDhwBurnerStarts, x: x.timestamp };
                }));
                var burnerOperationsHours = telemetry.map(function (x) {
                    return {
                        y: x.burnerOperationHours,
                        x: x.timestamp
                    };
                });
                var labels = telemetry.map(function (a) {
                    return a.timestamp;
                });
                return {
                    labels: labels,
                    datasets: [{
                        label: 'Successful Burner Starts',
                        data: successfulBurnerStarts,
                        borderWidth: 1,
                        borderColor: '#00698E',
                        backgroundColor: '#00698E',
                        fill: false,
                        pointRadius: 1
                    }, {
                        label: 'Unsuccessful Burner Starts',
                        data: unsuccessfulBurnerStarts,
                        borderWidth: 1,
                        borderColor: '#FFBC42',
                        backgroundColor: '#FFBC42',
                        fill: false,
                        pointRadius: 1
                    }, {
                        label: 'Flame Low Signals',
                        data: flameLowSignals,
                        borderWidth: 1,
                        borderColor: '#D81159',
                        backgroundColor: '#D81159',
                        fill: false,
                        pointRadius: 1
                    }, {
                        label: 'CH Pump Starts',
                        data: chPumpStarts,
                        borderWidth: 1,
                        borderColor: '#8F2D56',
                        backgroundColor: '#8F2D56',
                        fill: false,
                        pointRadius: 1
                    }, {
                        label: 'DHW Pump Starts',
                        data: dhwPumpStarts,
                        borderWidth: 1,
                        borderColor: '#73D2DE',
                        backgroundColor: '#73D2DE',
                        fill: false,
                        pointRadius: 1
                    }, {
                        label: 'DHW Burner Starts',
                        data: dhwBurnerStarts,
                        borderWidth: 1,
                        borderColor: '#FF8552',
                        backgroundColor: '#FF8552',
                        fill: false,
                        pointRadius: 1
                    }, {
                        label: 'Burner Operation Hours',
                        data: burnerOperationsHours,
                        borderWidth: 1,
                        borderColor: '#17B890',
                        backgroundColor: '#17B890',
                        fill: false,
                        pointRadius: 1
                    }]
                };
            });
            return _this;
        }

        return DeviceBoilerUsageChart;
    }(Ember.Component.extend({}));

    exports.default = DeviceBoilerUsageChart;
});
define("darwin-portal/models/telemetry", ["exports", "ember-data", "ember-data/attr", "moment"], function (exports, _emberData, _attr, _moment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var Telemetry = function (_DS$Model$extend) {
        _inherits(Telemetry, _DS$Model$extend);

        function Telemetry() {
            _classCallCheck(this, Telemetry);

            return _possibleConstructorReturn(this, (Telemetry.__proto__ || Object.getPrototypeOf(Telemetry)).apply(this, arguments));
        }

        return Telemetry;
    }(_emberData.default.Model.extend({
        FirmwareVersion: (0, _attr.default)("string"),
        boilerStatus: (0, _attr.default)("string"),
        burnerOperationHours: (0, _attr.default)("number"),
        chFanSpeedAggregate: (0, _attr.default)("number"),
        chPumpOperationHours: (0, _attr.default)("number"),
        dhwBurnerOperationHours: (0, _attr.default)("number"),
        dhwFanSpeedAggregate: (0, _attr.default)("number"),
        numChPumpStarts: (0, _attr.default)("number"),
        numDhwPumpStarts: (0, _attr.default)("number"),
        numDhwBurnerStarts: (0, _attr.default)("number"),
        numFlameLowSignals: (0, _attr.default)("number"),
        numSuccessfulBurnerStarts: (0, _attr.default)("number"),
        numUnsuccessfulBurnerStarts: (0, _attr.default)("number"),
        oemFaultCode: (0, _attr.default)("string"),
        flowWaterTemperatureMean: (0, _attr.default)("number"),
        flowWaterTemperatureMax: (0, _attr.default)("number"),
        flowWaterTemperatureMin: (0, _attr.default)("number"),
        returnWaterTemperatureMean: (0, _attr.default)("number"),
        returnWaterTemperatureMax: (0, _attr.default)("number"),
        returnWaterTemperatureMin: (0, _attr.default)("number"),
        waterPressureMean: (0, _attr.default)("number"),
        waterPressureMax: (0, _attr.default)("number"),
        waterPressureMin: (0, _attr.default)("number"),
        dhwWaterFlowMean: (0, _attr.default)("number"),
        dhwWaterFlowMax: (0, _attr.default)("number"),
        dhwWaterFlowMin: (0, _attr.default)("number"),
        dhwWaterTemperatureMean: (0, _attr.default)("number"),
        dhwWaterTemperatureMax: (0, _attr.default)("number"),
        dhwWaterTemperatureMin: (0, _attr.default)("number"),
        exhaustTemperatureMean: (0, _attr.default)("number"),
        exhaustTemperatureMax: (0, _attr.default)("number"),
        exhaustTemperatureMin: (0, _attr.default)("number"),
        relativeModulationLevelMean: (0, _attr.default)("number"),
        relativeModulationLevelMax: (0, _attr.default)("number"),
        relativeModulationLevelMin: (0, _attr.default)("number"),
        fanSpeedActualMean: (0, _attr.default)("number"),
        fanSpeedActualMax: (0, _attr.default)("number"),
        fanSpeedActualMin: (0, _attr.default)("number"),
        controlSetpointMean: (0, _attr.default)("number"),
        controlSetpointMax: (0, _attr.default)("number"),
        controlSetpointMin: (0, _attr.default)("number"),
        remoteRoomTemperature1Mean: (0, _attr.default)("number"),
        remoteRoomTemperature1Max: (0, _attr.default)("number"),
        remoteRoomTemperature1Min: (0, _attr.default)("number"),
        remoteRoomSetpoint1Mean: (0, _attr.default)("number"),
        remoteRoomSetpoint1Max: (0, _attr.default)("number"),
        remoteRoomSetpoint1Min: (0, _attr.default)("number"),
        signalStrength: (0, _attr.default)("number"),
        signalQuality: (0, _attr.default)("number"),
        timestamp: (0, _attr.default)("date"),
        IsOtprtConnected: (0, _attr.default)("boolean"),
        ChFlameOnDuration: (0, _attr.default)("number"),
        DhwFlameOnDuration: (0, _attr.default)("number"),
        OtMasterStatus: (0, _attr.default)("number"),
        gasConsumptionKwh: (0, _attr.default)('number'),
        // Computed
        dateAndHour: Ember.computed("timestamp", function () {
            // Return the timestamp as a date/hour string so we can
            // group telemetry by the hour
            return (0, _moment.default)(Ember.get(this, "timestamp")).format("YYYY-MM-DD HH");
        })
    }));

    exports.default = Telemetry;
});
define("darwin-portal/routes/internal/firmware-group/devices", ["exports", "ember-concurrency-decorators"], function (exports, _emberConcurrencyDecorators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var InternalFirmwareGroupDevices = function (_EmberRoute$extend) {
        _inherits(InternalFirmwareGroupDevices, _EmberRoute$extend);

        function InternalFirmwareGroupDevices() {
            _classCallCheck(this, InternalFirmwareGroupDevices);

            return _possibleConstructorReturn(this, (InternalFirmwareGroupDevices.__proto__ || Object.getPrototypeOf(InternalFirmwareGroupDevices)).apply(this, arguments));
        }

        _createClass(InternalFirmwareGroupDevices, [{
            key: "model",
            value: function model(params, transition) {
                var firmwareGroupName = transition.params['internal.firmware-group'].firmware_group_name;
                return {
                    firmwareGroup: Ember.get(this, 'getFirmwareGroup').perform(firmwareGroupName),
                    groupDevices: Ember.get(this, 'getGroupDevices').perform(firmwareGroupName)
                };
            }
        }, {
            key: "getFirmwareGroup",
            value: regeneratorRuntime.mark(function getFirmwareGroup(firmwareGroupName) {
                return regeneratorRuntime.wrap(function getFirmwareGroup$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                _context.next = 2;
                                return this.store.findRecord('firmware-group', firmwareGroupName);

                            case 2:
                                return _context.abrupt("return", _context.sent);

                            case 3:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, getFirmwareGroup, this);
            })
        }, {
            key: "getGroupDevices",
            value: regeneratorRuntime.mark(function getGroupDevices(firmwareGroupName) {
                var result;
                return regeneratorRuntime.wrap(function getGroupDevices$(_context2) {
                    while (1) {
                        switch (_context2.prev = _context2.next) {
                            case 0:
                                _context2.next = 2;
                                return this.store.query('firmware-group-device', {
                                    filter: {
                                        firmwareGroup: firmwareGroupName
                                    }
                                });

                            case 2:
                                result = _context2.sent;
                                return _context2.abrupt("return", {
                                    devices: result.toArray(),
                                    cursor: result.meta.cursor
                                });

                            case 4:
                            case "end":
                                return _context2.stop();
                        }
                    }
                }, getGroupDevices, this);
            })
        }]);

        return InternalFirmwareGroupDevices;
    }(Ember.Route.extend({
        // anything which *must* be merged to prototype here
    }));

    exports.default = InternalFirmwareGroupDevices;

    __decorate([_emberConcurrencyDecorators.task], InternalFirmwareGroupDevices.prototype, "getFirmwareGroup", null);
    __decorate([_emberConcurrencyDecorators.task], InternalFirmwareGroupDevices.prototype, "getGroupDevices", null);
});
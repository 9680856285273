define('darwin-portal/services/authorizer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var Authorizer = function (_EmberService$extend) {
        _inherits(Authorizer, _EmberService$extend);

        function Authorizer() {
            _classCallCheck(this, Authorizer);

            var _this = _possibleConstructorReturn(this, (Authorizer.__proto__ || Object.getPrototypeOf(Authorizer)).apply(this, arguments));

            _this.partnerRole = "";
            _this.idealRole = "";
            _this.context = undefined;
            //  partnerRole = computed(function() {
            //    const partners = this.store.peekAll('portal-session-partner');
            //    if (partners && partners.firstObject) {
            //      return partners.firstObject.role;
            //    } else {
            //      return "";
            //    }
            //  });
            // normal class body definition here
            _this.isIdealAdmin = Ember.computed('idealRole', function () {
                //const account = this.store.peekAll("portal-session");
                //const firstObj = account.firstObject;
                if (this.idealRole) {
                    return this.idealRole === "Admin";
                }
                return false;
            });
            _this.isIdealUser = Ember.computed('idealRole', function () {
                // const account = this.store.peekAll("portal-session");
                // const firstObj = account.firstObject;
                if (this.idealRole) {
                    return this.idealRole === "User";
                }
                return false;
            });
            _this.isIdeal = Ember.computed('isIdealAdmin', 'isIdealUser', function () {
                var res = Ember.get(this, "isIdealAdmin") === true || Ember.get(this, "isIdealUser") === true;
                return res;
            });
            _this.isPartnerAdmin = Ember.computed("partnerRole", function () {
                return Ember.get(this, "partnerRole") === "Admin";
            });
            _this.isPartnerUser = Ember.computed("partnerRole", function () {
                return Ember.get(this, "partnerRole") === "User";
            });
            _this.isPartnerInstaller = Ember.computed("partnerRole", function () {
                return Ember.get(this, "partnerRole") === "Installer";
            });
            _this.isPartner = Ember.computed("isPartnerAdmin", "isPartnerUser", "isPartnerInstaller", function () {
                return Ember.get(this, "isPartnerAdmin") || Ember.get(this, "isPartnerUser") || Ember.get(this, "isPartnerInstaller");
            });
            _this.isActiveUser = Ember.computed("isPartner", "isIdeal", function () {
                return Ember.get(this, "isPartner") || Ember.get(this, "isIdeal");
            });
            _this.isAdmin = Ember.computed("isIdealAdmin", "isPartnerAdmin", function () {
                return Ember.get(this, "isIdealAdmin") || Ember.get(this, "isPartnerAdmin");
            });
            _this.isIdealOrPartnerAdmin = Ember.computed("isIdeal", "isPartnerAdmin", function () {
                return Ember.get(this, "isIdeal") || Ember.get(this, "isPartnerAdmin");
            });
            _this.isIdealOrPartner = Ember.computed("isIdeal", "isPartnerAdmin", "isPartnerUser", function () {
                return Ember.get(this, "isIdeal") || Ember.get(this, "isPartnerAdmin") || Ember.get(this, "isPartnerUser");
            });
            return _this;
        }

        _createClass(Authorizer, [{
            key: 'getContext',
            value: function getContext() {
                if (Ember.get(this, 'context')) {
                    return Ember.get(this, 'context');
                } else if (localStorage.getItem('current_context')) {
                    // @ts-ignore
                    var storageContext = JSON.parse(localStorage.getItem('current_context'));
                    this.setContext(storageContext);
                    return storageContext;
                } else {
                    return undefined;
                }
            }
        }, {
            key: 'setContext',
            value: function setContext(context) {
                if (context.type === "Ideal" && context.role) {
                    localStorage.removeItem("partner_id");
                    Ember.set(this, 'partnerRole', "");
                    Ember.set(this, 'idealRole', context.role);
                } else if (context.type === "Partner" && context.role) {
                    localStorage.setItem("partner_id", context.id);
                    Ember.set(this, 'idealRole', "");
                    Ember.set(this, 'partnerRole', context.role);
                } else {
                    console.log("context not hooked up correctly");
                }
                Ember.set(this, 'context', context);
                localStorage.setItem("current_context", JSON.stringify(context));
            }
        }, {
            key: 'removeContext',
            value: function removeContext() {
                localStorage.removeItem("current_context");
                localStorage.removeItem("partner_id");
            }
        }, {
            key: 'update',
            value: function update(newPartnerRole, newIdealRole) {
                Ember.set(this, 'idealRole', newIdealRole);
                Ember.set(this, 'partnerRole', newPartnerRole);
            }
        }]);

        return Authorizer;
    }(Ember.Service.extend({
        // anything which *must* be merged to prototype here
        store: Ember.inject.service("store")
    }));

    exports.default = Authorizer;
});
define('darwin-portal/mixins/controllers/filterable', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        queryParams: ['hasAlarm', 'offline', 'isHalo', 'online'],
        hasAlarm: false,
        offline: false,
        isHalo: false,
        online: false,
        filters: Ember.computed('hasAlarm', 'offline', 'isHalo', 'online', function () {
            return [Ember.get(this, 'hasAlarm') ? 'hasAlarm' : null, Ember.get(this, 'offline') ? 'offline' : null, Ember.get(this, 'isHalo') ? 'isHalo' : null, Ember.get(this, 'online') ? 'online' : null].filter(function (x) {
                return x !== null;
            });
        }),
        actions: {
            filterBy: function filterBy(attribute) {
                var queryParams = {};
                queryParams[attribute] = !this[attribute];
                // @ts-ignore: ember typescript definition requires route which we don't need
                this.transitionToRoute({ queryParams: queryParams });
            }
        }
    });
});
define('darwin-portal/components/system-dashboard-tiles', ['exports', 'ember-bem-sauce/mixins/bem-component', 'darwin-portal/helpers/format-titlecase', 'darwin-portal/helpers/traffic-light-by-string', 'moment'], function (exports, _bemComponent, _formatTitlecase, _trafficLightByString, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var SystemDashboardTiles = function (_EmberComponent$exte) {
        _inherits(SystemDashboardTiles, _EmberComponent$exte);

        function SystemDashboardTiles() {
            _classCallCheck(this, SystemDashboardTiles);

            return _possibleConstructorReturn(this, (SystemDashboardTiles.__proto__ || Object.getPrototypeOf(SystemDashboardTiles)).apply(this, arguments));
        }

        return SystemDashboardTiles;
    }(Ember.Component.extend(_bemComponent.default, {
        // anything which *must* be merged to prototype here
        base: 'system-dashboard-tiles',
        cloudLicenceColor: Ember.computed('home', function () {
            try {
                var home = Ember.get(this, 'home');
                var connected = home.connectedDevice;
                if (connected) {
                    return "number--green";
                } else {
                    return 'number--red';
                }
            } catch (_a) {
                return "number--grey";
            }
        }),
        cloudLicenceText: Ember.computed('home', function () {
            try {
                var home = Ember.get(this, 'home');
                var connected = home.connectedDevice;
                if (connected) {
                    return 'Licensed';
                } else {
                    return 'Unlicensed';
                }
            } catch (_a) {
                return '';
            }
        }),
        boilerData: Ember.computed('home', function () {
            try {
                var home = Ember.get(this, 'home');
                if (home.boiler) {
                    var boiler = home.boiler;
                    var openThermStatus = boiler.OpenThermCommunication ? 'Ok' : 'Warning';
                    var openThermColor = boiler.OpenThermCommunication ? 'online' : 'offline';
                    var faults = boiler.Faults && boiler.Faults.length ? boiler.Faults.length : 0;
                    var faultColor = faults === 0 ? 'online' : 'offline';
                    var waterPressureFault = void 0;
                    if (boiler.Faults) {
                        waterPressureFault = boiler.Faults.filter(function (fault) {
                            if (fault.faultCode === "F1") {
                                return true;
                            }
                        }).length > 0;
                    }
                    return {
                        type: boiler.BoilerType,
                        opentherm: boiler.OpenThermCommunication,
                        faultCount: '0',
                        statusElements: [{
                            title: "Open Therm Comms",
                            statusText: openThermStatus,
                            color: openThermColor
                        }, {
                            title: "Water Pressure",
                            statusText: waterPressureFault ? 'Low' : 'Ok',
                            color: waterPressureFault ? 'offline' : 'online'
                        }, {
                            title: "Active Faults",
                            statusText: faults,
                            color: faultColor
                        }]
                    };
                }
            } catch (e) {
                console.log('errored with', e);
                return { type: 'Unknown Boiler' };
            }
        }),
        prtData: Ember.computed('home', function () {
            try {
                var home = Ember.get(this, 'home');
                if (home.zones) {
                    return home.zones.filter(function (x) {
                        return x.ZoneId[1] == 5;
                    }).map(function (zone) {
                        // faults for this zone
                        var prtControlFaults = home.controlFaults.filter(function (x) {
                            return x.ZoneId && x.ZoneId[0] == zone.ZoneId[0];
                        });
                        var faultColor = prtControlFaults.length === 0 ? 'online' : 'offline';
                        return {
                            id: zone.ZoneId[0],
                            name: zone.FriendlyName,
                            model: zone.ModelIdentifier,
                            statusElements: [{
                                title: "Zigbee Signal",
                                statusText: zone.ConnectionStrength + ' (' + zone.LastMessageRssi + ' RSSI)',
                                color: null
                            }, {
                                title: "Battery",
                                statusText: (0, _formatTitlecase.toTitleCase)(zone.BatteryLevel),
                                color: (0, _trafficLightByString.trafficLightByString)(zone.BatteryLevel)
                            }, {
                                title: "Firmware",
                                statusText: zone.FirmwareVersionPrt,
                                color: null
                            }, {
                                title: "Active Faults",
                                statusText: prtControlFaults.length,
                                color: faultColor
                            }]
                        };
                    });
                }
            } catch (e) {
                console.log('errored with', e);
                return [];
            }
        }),
        zigbeeBoosters: Ember.computed('home', function () {
            try {
                var home = Ember.get(this, 'home');
                if (home.zigbeeRepeaters) {
                    return home.zigbeeRepeaters.map(function (repeater) {
                        return {
                            statusElements: [{
                                title: "Connected",
                                statusText: repeater.ConnectionStatus ? 'Online' : 'Offline',
                                color: repeater.ConnectionStatus ? 'online' : 'offline'
                            }, {
                                title: "Firmware Version",
                                statusText: repeater.FirmwareVersionRepeaterZ,
                                color: null
                            }, {
                                title: "Device ID",
                                statusText: repeater.DeviceId,
                                color: null
                            }]
                        };
                    });
                }
            } catch (e) {
                console.log('errored with', e);
                return [];
            }
        }),
        smartInterfaceData: Ember.computed('device', 'home', function () {
            try {
                var device = Ember.get(this, 'device');
                var home = Ember.get(this, 'home');
                if (device && home) {
                    var si = home.si;
                    var deviceLastSeenDays = (0, _moment.default)().diff((0, _moment.default)(device.lastReportedAt), 'days');
                    var faultColor = home.controlFaults.length === 0 ? 'online' : 'offline';
                    return {
                        statusElements: [{
                            title: "Last Message Received",
                            statusText: (0, _moment.default)(device.lastReportedAt).fromNow(),
                            color: deviceLastSeenDays < 1 ? "Online" : "Offline"
                        }, {
                            title: "WIFI Signal",
                            statusText: si.ConnectionStrength + ' (' + si.WifiRssi + ' RSSI)',
                            color: null
                        }, {
                            title: "Cloud",
                            statusText: device.status,
                            color: device.status
                        }, {
                            title: "Firmware",
                            statusText: si.FirmwareVersionSi || "Unknown",
                            color: null
                        }, {
                            title: "Active Faults",
                            statusText: home.controlFaults.length,
                            color: faultColor
                        }]
                    };
                }
            } catch (e) {
                console.log('errored with', e);
                return {};
            }
        })
    }));

    exports.default = SystemDashboardTiles;

    ;
});
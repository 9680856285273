define('darwin-portal/utils/ideal-users/role-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var ROLE_OPTIONS = [{
        label: 'Ideal Admin',
        value: 'Admin'
    }, {
        label: 'Ideal Support',
        value: 'User'
    }];
    exports.default = ROLE_OPTIONS;
});
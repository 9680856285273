define('darwin-portal/helpers/current-date', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.currentDate = currentDate;
    function currentDate() /*, hash*/{
        return Date.now();
    }
    exports.default = Ember.Helper.helper(currentDate);
});
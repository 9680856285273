define('darwin-portal/components/uxs-archive-form', ['exports', 'ember-ux-sauce/components/uxs-archive-form'], function (exports, _uxsArchiveForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsArchiveForm.default;
    }
  });
});
define('darwin-portal/components/uxs-datalist', ['exports', 'ember-ux-sauce/components/uxs-datalist'], function (exports, _uxsDatalist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsDatalist.default;
    }
  });
});
define('darwin-portal/controllers/internal/partner-device/boiler-usage', ['exports', 'darwin-portal/mixins/controllers/partner-device'], function (exports, _partnerDevice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var InternalPartnerDeviceBoilerUsage = function (_EmberController$ext) {
    _inherits(InternalPartnerDeviceBoilerUsage, _EmberController$ext);

    function InternalPartnerDeviceBoilerUsage() {
      _classCallCheck(this, InternalPartnerDeviceBoilerUsage);

      return _possibleConstructorReturn(this, (InternalPartnerDeviceBoilerUsage.__proto__ || Object.getPrototypeOf(InternalPartnerDeviceBoilerUsage)).apply(this, arguments));
    }

    return InternalPartnerDeviceBoilerUsage;
  }(Ember.Controller.extend(_partnerDevice.default, {
    // anything which *must* be merged to prototype here
  }));

  exports.default = InternalPartnerDeviceBoilerUsage;
});
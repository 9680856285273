define('darwin-portal/abilities/ideal-user', ['exports', 'ember-can'], function (exports, _emberCan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCan.Ability.extend({
        // Service
        authorizer: Ember.inject.service('authorizer'),
        user: Ember.computed.alias('session.Email'),
        // Computed
        isAdmin: Ember.computed.alias('authorizer.isIdealAdmin'),
        // Computed
        isUser: Ember.computed.alias('authorizer.isIdealUser'),
        // Computed
        isIdeal: Ember.computed.alias('authorizer.isIdeal'),
        // only admins can create an ideal user
        canCreate: Ember.computed.alias('isAdmin'),
        // only admins can read an ideal user
        canRead: Ember.computed.alias('isAdmin'),
        // only admins can update an ideal user
        canUpdate: Ember.computed.alias('isAdmin'),
        // only admins can archive an ideal user
        canArchive: Ember.computed.alias('isAdmin'),
        // only admins can navigate to the ideal users list
        canNavigate: Ember.computed.alias('isAdmin'),
        // only admins can view an ideal users audit trail
        canReadAudits: Ember.computed.alias('isAdmin'),
        // only admins can view an ideal users activities
        canReadActivities: Ember.computed.alias('isAdmin')
    });
});
define('darwin-portal/utils/firmware-groups/action-routes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var ACTION_ROUTES = [{
        title: 'View',
        route: 'internal.firmware-group.index',
        ability: 'read firmware-group'
    }, {
        title: 'Devices',
        route: 'internal.firmware-group.devices',
        ability: 'read firmware-group'
    }, {
        title: 'Update',
        route: 'internal.firmware-group.update',
        ability: 'update firmware-group'
    }, {
        title: 'Delete',
        route: 'internal.firmware-group.delete',
        ability: 'delete firmware-group'
    }];
    exports.default = ACTION_ROUTES;
});
define('darwin-portal/mixins/validations/account', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
            type: 'email'
        })],
        firstName: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            ignoreBlank: true
        }), (0, _emberCpValidations.validator)('length', {
            min: 2
        })],
        lastName: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            ignoreBlank: true
        }), (0, _emberCpValidations.validator)('length', {
            min: 2
        })]
    });
});
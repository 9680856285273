define("darwin-portal/controllers/internal/partner-device/index", ["exports", "ember-concurrency-decorators", "darwin-portal/mixins/controllers/partner-device", "darwin-portal/utils/partner-devices/max-flow-temp-options"], function (exports, _emberConcurrencyDecorators, _partnerDevice, _maxFlowTempOptions) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    var _get = function get(object, property, receiver) {
        if (object === null) object = Function.prototype;
        var desc = Object.getOwnPropertyDescriptor(object, property);

        if (desc === undefined) {
            var parent = Object.getPrototypeOf(object);

            if (parent === null) {
                return undefined;
            } else {
                return get(parent, property, receiver);
            }
        } else if ("value" in desc) {
            return desc.value;
        } else {
            var getter = desc.get;

            if (getter === undefined) {
                return undefined;
            }

            return getter.call(receiver);
        }
    };

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var InternalPartnerDeviceIndex = function (_EmberController$ext) {
        _inherits(InternalPartnerDeviceIndex, _EmberController$ext);

        function InternalPartnerDeviceIndex() {
            _classCallCheck(this, InternalPartnerDeviceIndex);

            var _this = _possibleConstructorReturn(this, (InternalPartnerDeviceIndex.__proto__ || Object.getPrototypeOf(InternalPartnerDeviceIndex)).apply(this, arguments));

            _this.actions = {
                invalid: function invalid() {
                    Ember.get(this, 'toast').error("Please Correct Form Errors");
                },
                updateSelectedMaxFlow: function updateSelectedMaxFlow(value) {
                    Ember.set(this, 'selectedMaxFlow', value);
                },

                toggleModal: function toggleModal() {
                    this.toggleProperty('isShowingModal');
                }
            };
            return _this;
        }

        _createClass(InternalPartnerDeviceIndex, [{
            key: "init",
            value: function init() {
                _get(InternalPartnerDeviceIndex.prototype.__proto__ || Object.getPrototypeOf(InternalPartnerDeviceIndex.prototype), "init", this).call(this);
                Ember.set(this, "maxFlowOptions", _maxFlowTempOptions.default);
            }
        }, {
            key: "resetBoiler",
            value: regeneratorRuntime.mark(function resetBoiler(device) {
                var resetBoilerService, message;
                return regeneratorRuntime.wrap(function resetBoiler$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                _context.prev = 0;
                                resetBoilerService = this.get('resetBoilerService');
                                _context.next = 4;
                                return resetBoilerService.request(device.id);

                            case 4:
                                device.incrementProperty('resetCount');
                                Ember.get(this, 'toast').info('Boiler Reset');
                                _context.next = 13;
                                break;

                            case 8:
                                _context.prev = 8;
                                _context.t0 = _context["catch"](0);
                                message = 'Error Resetting Boiler';

                                if (_context.t0.status === 400) {
                                    message = 'Reset Limit Exceeded';
                                }
                                Ember.get(this, 'toast').error(message);

                            case 13:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, resetBoiler, this, [[0, 8]]);
            })
        }, {
            key: "postSelectedMaxFlow",
            value: regeneratorRuntime.mark(function postSelectedMaxFlow(device) {
                var flowTempService, message;
                return regeneratorRuntime.wrap(function postSelectedMaxFlow$(_context2) {
                    while (1) {
                        switch (_context2.prev = _context2.next) {
                            case 0:
                                _context2.prev = 0;

                                this.toggleProperty('isShowingModal');
                                Ember.get(this, 'toast').info("Setting Max Flow Temp: " + this.selectedMaxFlow);
                                flowTempService = this.get('flowTempService');
                                _context2.next = 6;
                                return flowTempService.tempRequest(device.id, this.selectedMaxFlow);

                            case 6:
                                //reset the selectedMaxFlow back to its original
                                Ember.set(this, 'selectedMaxFlow', -1);
                                Ember.set(this, device.maxFlowTemp, this.selectedMaxFlow);
                                _context2.next = 14;
                                break;

                            case 10:
                                _context2.prev = 10;
                                _context2.t0 = _context2["catch"](0);
                                message = 'Error Updating Boiler';

                                Ember.get(this, 'toast').error(message);

                            case 14:
                            case "end":
                                return _context2.stop();
                        }
                    }
                }, postSelectedMaxFlow, this, [[0, 10]]);
            })
        }]);

        return InternalPartnerDeviceIndex;
    }(Ember.Controller.extend(_partnerDevice.default, {
        // most of the common functionality of this controller
        // can be found in /mixins/controllers/partner-device.js
        selectedMaxFlow: -1,
        resetBoilerService: Ember.inject.service('reset-boiler'),
        flowTempService: Ember.inject.service('set-flow-temp'),
        toast: Ember.inject.service(),
        isShowingModal: false,
        cantUpdateFlowTemp: Ember.computed('selectedMaxFlow', function () {
            if (this.device && this.device.maxFlowTemp && this.device.maxFlowTemp > 0) return this.selectedMaxFlow === this.device.maxFlowTemp || this.selectedMaxFlow === -1;else return this.selectedMaxFlow === -1;
        }),
        hasFirmware: Ember.computed('device', 'device.currentTelemetry', function () {
            return this.device && this.device.currentTelemetry && this.device.currentTelemetry.FirmwareVersion;
        }),
        deviceFlowTemp: Ember.computed('device', 'device.maxFlowTemp', function () {
            if (this.device && this.device.maxFlowTemp && this.device.maxFlowTemp > 0) {
                return "Current Max Flow Temp: " + this.device.maxFlowTemp + "°C";
            } else return "Current Max Flow Temp: Unset";
        })
    }));

    exports.default = InternalPartnerDeviceIndex;

    __decorate([_emberConcurrencyDecorators.task], InternalPartnerDeviceIndex.prototype, "resetBoiler", null);
    __decorate([_emberConcurrencyDecorators.task], InternalPartnerDeviceIndex.prototype, "postSelectedMaxFlow", null);
});
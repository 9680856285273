define('darwin-portal/helpers/get-routes-for', ['exports', 'darwin-portal/utils/ideal-users/action-routes', 'darwin-portal/utils/partners/action-routes', 'darwin-portal/utils/properties/action-routes', 'darwin-portal/utils/firmware-groups/action-routes', 'darwin-portal/utils/account/action-routes', 'darwin-portal/utils/partner-devices/action-routes'], function (exports, _actionRoutes, _actionRoutes2, _actionRoutes3, _actionRoutes4, _actionRoutes5, _actionRoutes6) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Helper.extend({
        can: Ember.inject.service(),
        compute: function compute(_ref) {
            var _ref2 = _slicedToArray(_ref, 3),
                model = _ref2[0],
                type = _ref2[1],
                resource = _ref2[2];

            var items = [];
            var ccan = this.get('can');
            if (model === "ideal-user") {
                if (type === "resource") {
                    items = _actionRoutes.default;
                }
            }
            if (model === "partner") {
                if (type === "resource") {
                    items = _actionRoutes2.default;
                }
            }
            if (model === "property") {
                if (type === "resource") {
                    items = _actionRoutes3.default;
                }
            }
            if (model === "device") {
                if (type === "resource") {
                    var excludedActionNames = [];
                    if (resource) {
                        if (Ember.get(resource, 'isHalo')) {
                            excludedActionNames = ['set-service', 'building-performance', 'alarms'];
                        } else {
                            excludedActionNames = ['accounts', 'license', 'si-executions', 'system-dashboard', 'system-faults', 'firmware', 'gas-consumption'];
                        }
                        items = _actionRoutes6.ACTION_ROUTES.filter(function (action) {
                            return !excludedActionNames.includes(action.name);
                        });
                    } else {
                        items = [];
                    }
                } else if (type === "data") {
                    items = _actionRoutes6.DATA_ACTION_ROUTES;
                }
            }
            if (model === "account") {
                items = _actionRoutes5.ACTION_ROUTES;
            }
            if (model === "account-admin") {
                items = _actionRoutes5.ADMIN_ACTION_ROUTES;
            }
            if (model === "firmware-group") {
                items = _actionRoutes4.default;
            }
            return items.filter(function (x) {
                if (x.ability) {
                    return ccan.can(x.ability);
                } else {
                    return true;
                }
            });
            ;
        }
    });
});
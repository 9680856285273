define('darwin-portal/components/account-details', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var AccountDetails = function (_EmberComponent$exte) {
        _inherits(AccountDetails, _EmberComponent$exte);

        function AccountDetails() {
            _classCallCheck(this, AccountDetails);

            return _possibleConstructorReturn(this, (AccountDetails.__proto__ || Object.getPrototypeOf(AccountDetails)).apply(this, arguments));
        }

        return AccountDetails;
    }(Ember.Component.extend({
        // anything which *must* be merged to prototype here
        marketingColour: Ember.computed('account', function () {
            var acceptedMarketing = Ember.get(this, 'account.acceptedMarketing');
            return acceptedMarketing ? 'online' : 'offline';
        }),
        marketingStatus: Ember.computed('account', function () {
            var acceptedMarketing = Ember.get(this, 'account.acceptedMarketing');
            return acceptedMarketing ? 'Yes' : 'No';
        }),
        timeSinceLastUse: Ember.computed('account', function () {
            var lastConnection = Ember.get(this, 'account.lastConnection');
            if (lastConnection) {
                var timeSince = (0, _moment.default)(lastConnection).fromNow();
                var date = (0, _moment.default)(lastConnection).format("DD/MM/YYYY");
                var combined = '(' + timeSince + ') ' + date;
                return combined;
            }
            return "Never used app";
        }),
        termsAcceptDate: Ember.computed('account', function () {
            var termsAgreedAt = Ember.get(this, 'account.termsAgreedAt');
            if (termsAgreedAt) {
                var date = (0, _moment.default)(termsAgreedAt).format("DD/MM/YYYY hh:mmA");
                return date;
            }
            return "No acceptance date recorded";
        }),
        dateAccountCreated: Ember.computed('account', function () {
            var createdAt = Ember.get(this, 'account.createdAt');
            if (createdAt) {
                var date = (0, _moment.default)(createdAt).format("DD/MM/YYYY hh:mmA");
                return date;
            }
            return "No creation date available";
        })
    }));

    exports.default = AccountDetails;

    ;
});
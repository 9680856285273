define('darwin-portal/services/uxs-list-subheading-cache', ['exports', 'ember-ux-sauce/services/uxs-list-subheading-cache'], function (exports, _uxsListSubheadingCache) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsListSubheadingCache.default;
    }
  });
});
define('darwin-portal/mixins/validations/set-password', ['exports', 'ember-cp-validations', 'darwin-portal/utils/validations/password-regex'], function (exports, _emberCpValidations, _passwordRegex) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
            regex: _passwordRegex.default,
            message: 'Password must match requirements'
        })]
    });
});
define("darwin-portal/utils/accounts/filter-options", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var FILTER_OPTIONS = [
    // {
    //   label: 'Offline',
    //   value: 'offline'
    // },
  ];
  exports.default = FILTER_OPTIONS;
});
define('darwin-portal/abilities/partner-user', ['exports', 'ember-can'], function (exports, _emberCan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCan.Ability.extend({
        authorizer: Ember.inject.service('authorizer'),
        user: Ember.computed.alias('session.Email'),
        canNavigate: Ember.computed.alias('authorizer.isPartnerAdmin'),
        canUpdate: Ember.computed.alias('authorizer.isPartnerAdmin'),
        canRead: Ember.computed.alias('authorizer.isPartnerAdmin'),
        canArchive: Ember.computed.alias('authorizer.isPartnerAdmin'),
        canReadLastActive: Ember.computed.alias('authorizer.isAdmin')
    });
});
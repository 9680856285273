define('darwin-portal/utils/partners/action-routes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var ACTION_ROUTES = [{
        title: 'View Partner',
        route: 'internal.partner.index',
        ability: "read partner"
    },
    /*
      {
        title: 'Update Partner',
        route: 'internal.partner.edit',
        ability: "update partner",
      },
    */
    {
        title: 'Partner Users',
        route: 'internal.partner.users.index',
        ability: "read users in partner"
    }, {
        title: 'Partner Properties',
        route: 'internal.partner.properties',
        ability: "read properties in partner"
    }, {
        title: 'Partner Devices',
        route: 'internal.partner.partner-devices',
        ability: "read devices in partner"
    },
    /*
      {
        title: 'Audit Log',
        route: 'internal.partner.audits',
        ability: "read audits in ideal-user",
      },
    */
    {
        title: 'Archive Partner',
        route: 'internal.partner.archive',
        ability: "archive partner"
    }];
    exports.default = ACTION_ROUTES;
});
define("darwin-portal/routes/internal/alarms/index", ["exports", "darwin-portal/mixins/routes/paginatable", "darwin-portal/mixins/routes/searchable", "darwin-portal/mixins/routes/sortable", "ember-concurrency-decorators"], function (exports, _paginatable, _searchable, _sortable, _emberConcurrencyDecorators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var InternalAlarmsIndex = function (_EmberRoute$extend) {
        _inherits(InternalAlarmsIndex, _EmberRoute$extend);

        function InternalAlarmsIndex() {
            _classCallCheck(this, InternalAlarmsIndex);

            return _possibleConstructorReturn(this, (InternalAlarmsIndex.__proto__ || Object.getPrototypeOf(InternalAlarmsIndex)).apply(this, arguments));
        }

        _createClass(InternalAlarmsIndex, [{
            key: "model",
            value: function model(params) {
                return { alarms: Ember.get(this, 'getAlarms').perform(params) };
            }
        }, {
            key: "resetController",
            value: function resetController(controller, isExiting) {
                if (isExiting) {
                    // isExiting would be false if only the route's model was changing
                    controller.set('alarmSeverity', null);
                }
            }
        }, {
            key: "getAlarms",
            value: regeneratorRuntime.mark(function getAlarms(params) {
                var sort, page, result;
                return regeneratorRuntime.wrap(function getAlarms$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                sort = this.getSortParam(params);
                                page = this.getPageParams(params);

                                if (params.showResolved === undefined) {
                                    params.showResolved = false;
                                }
                                result = void 0;

                                if (!params.showResolved) {
                                    _context.next = 10;
                                    break;
                                }

                                _context.next = 7;
                                return this.store.query('resolved-alarm', {
                                    include: "devices",
                                    filter: params,
                                    page: page,
                                    sort: sort
                                });

                            case 7:
                                result = _context.sent;
                                _context.next = 13;
                                break;

                            case 10:
                                _context.next = 12;
                                return this.store.query('alarm', {
                                    include: "devices",
                                    filter: params,
                                    page: page,
                                    sort: "-priority," + sort
                                });

                            case 12:
                                result = _context.sent;

                            case 13:
                                return _context.abrupt("return", result);

                            case 14:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, getAlarms, this);
            })
        }]);

        return InternalAlarmsIndex;
    }(Ember.Route.extend(_paginatable.default, _searchable.default, _sortable.default, {
        // anything which *must* be merged to prototype here
        queryParams: {
            alarmSeverity: {
                refreshModel: true
            },
            priority: {
                refreshModel: true
            },
            showResolved: {
                refreshModel: true
            }
        }
    }));

    exports.default = InternalAlarmsIndex;

    __decorate([_emberConcurrencyDecorators.task], InternalAlarmsIndex.prototype, "getAlarms", null);
});
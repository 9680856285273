define('darwin-portal/controllers/internal/dashboard/index', ['exports', 'darwin-portal/utils/tasks'], function (exports, _tasks) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalDashboardIndex = function (_EmberController$ext) {
        _inherits(InternalDashboardIndex, _EmberController$ext);

        function InternalDashboardIndex() {
            _classCallCheck(this, InternalDashboardIndex);

            var _this = _possibleConstructorReturn(this, (InternalDashboardIndex.__proto__ || Object.getPrototypeOf(InternalDashboardIndex)).apply(this, arguments));

            // normal class body definition here
            //
            _this.internalController = Ember.inject.controller("internal");
            _this.actions = {
                switchPartner: function switchPartner(context) {
                    var auth = Ember.get(this, "authorizer");
                    auth.setContext(context);
                },
                selectDevice: function selectDevice(type, device) {
                    var route = void 0;
                    switch (type) {
                        case 'online':
                            route: 'internal.partner-device.index';
                            break;
                        case 'offline':
                            route: 'internal.partner-device.index';
                            break;
                        case 'alerts':
                            route: 'internal.partner-device.alarms';
                            break;
                        default:
                            route: 'internal.partner-device.index';
                            break;
                    }
                    this.transitionToRoute(route, Ember.get(device, 'id'));
                },
                selectRecentAlarmType: function selectRecentAlarmType(type) {
                    Ember.set(this, 'recentAlarmType', type);
                    Ember.set(this, 'model.recentAlarms', Ember.get(this, 'getRecentAlarms').perform(type));
                },
                refreshRecentAlarms: function refreshRecentAlarms() {
                    var alarmType = Ember.get(this, 'recentAlarmType');
                    Ember.set(this, 'model.recentAlarms', Ember.get(this, 'getRecentAlarms').perform(alarmType));
                },
                searchCustomers: function searchCustomers(searchTerm) {
                    this.transitionToRoute('internal.accounts', {
                        queryParams: { search: searchTerm }
                    });
                }
            };
            return _this;
        }

        _createClass(InternalDashboardIndex, [{
            key: '_getDeviceCountForMaps',
            value: function _getDeviceCountForMaps(propertyName) {
                var deviceLocations = Ember.get(this, propertyName);
                if (Ember.isEmpty(deviceLocations)) {
                    return "";
                }
                return deviceLocations.features.length;
            }
        }]);

        return InternalDashboardIndex;
    }(Ember.Controller.extend({
        authorizer: Ember.inject.service('authorizer'),
        getRecentAlarms: _tasks.getRecentAlarms,
        recentAlarmOptions: [{
            value: 'active',
            label: 'Active'
        }, {
            value: 'resolved',
            label: 'Resolved'
        }],
        queryParams: ['recentAlarmType'],
        recentAlarmType: 'active',
        totalDevicesOnline: Ember.computed('model.devicesCoords.value', function () {
            return this._getDeviceCountForMaps('model.devicesCoords.value');
        }),
        totalDevicesOffline: Ember.computed('model.devicesOfflineCoords.value', function () {
            return this._getDeviceCountForMaps('model.devicesOfflineCoords.value');
        }),
        totalDevicesInAlert: Ember.computed('model.devicesAlertsCoords.value', function () {
            return this._getDeviceCountForMaps('model.devicesAlertsCoords.value');
        }),
        currentContext: Ember.computed.alias('authorizer.context'),
        contexts: Ember.computed('internalController.model', function () {
            var portalSession = Ember.get(this, 'internalController.model');
            var partnerContexts = portalSession.partners.map(function (x) {
                return x.toJSON();
            }).map(function (x) {
                var partnerContext = {
                    id: x.partnerId,
                    name: x.name,
                    role: x.partnerRole,
                    type: "Partner"
                };
                return partnerContext;
            });
            var contexts = partnerContexts || [];
            if (portalSession.idealRole) {
                var idealContext = {
                    id: "ideal",
                    name: "Ideal",
                    role: portalSession.idealRole,
                    type: "Ideal"
                };
                contexts.unshift(idealContext);
            }
            return contexts;
        }),
        userName: Ember.computed('internalController.model', function () {
            var portalSession = Ember.get(this, 'internalController.model');
            return portalSession.firstName + ' ' + portalSession.lastName;
        })
    }));

    exports.default = InternalDashboardIndex;
});
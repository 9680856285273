define('darwin-portal/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'darwin-portal/config/environment'], function (exports, _applicationRouteMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var Application = function (_EmberRoute$extend) {
        _inherits(Application, _EmberRoute$extend);

        function Application() {
            _classCallCheck(this, Application);

            return _possibleConstructorReturn(this, (Application.__proto__ || Object.getPrototypeOf(Application)).apply(this, arguments));
        }

        return Application;
    }(Ember.Route.extend(_applicationRouteMixin.default, {
        routeAfterAuthentication: 'internal.dashboard',
        sessionInvalidated: function sessionInvalidated() {
            if (!Ember.testing) {
                if (this.get('_isFastBoot')) {
                    this.transitionTo(_environment.default.authenticationURL + '?msg=' + btoa("You have been logged out"));
                } else {
                    window.location.replace(_environment.default.authenticationURL + '?msg=' + btoa("You have been logged out"));
                }
            }
        },

        actions: {
            goBack: function goBack() {
                history.back();
            }
        }
    }));

    exports.default = Application;
});
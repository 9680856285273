define("darwin-portal/controllers/internal/property/archive", ["exports", "ember-concurrency-decorators"], function (exports, _emberConcurrencyDecorators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var InternalPropertyArchive = function (_EmberController$ext) {
        _inherits(InternalPropertyArchive, _EmberController$ext);

        function InternalPropertyArchive() {
            _classCallCheck(this, InternalPropertyArchive);

            var _this = _possibleConstructorReturn(this, (InternalPropertyArchive.__proto__ || Object.getPrototypeOf(InternalPropertyArchive)).apply(this, arguments));

            // Actions
            _this.actions = {
                cancel: function cancel() {
                    this.transitionToRoute('internal.property.index');
                }
            };
            return _this;
        }
        // Tasks


        _createClass(InternalPropertyArchive, [{
            key: "archiveProperty",
            value: regeneratorRuntime.mark(function archiveProperty(property) {
                return regeneratorRuntime.wrap(function archiveProperty$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                _context.prev = 0;

                                property.deleteRecord();
                                _context.next = 4;
                                return property.save();

                            case 4:
                                Ember.get(this, 'toast').info("Property Archived");
                                this.transitionToRoute('internal.propertys.index');
                                _context.next = 11;
                                break;

                            case 8:
                                _context.prev = 8;
                                _context.t0 = _context["catch"](0);

                                // failure
                                Ember.get(this, 'toast').error("Error Archving Property");

                            case 11:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, archiveProperty, this, [[0, 8]]);
            })
        }]);

        return InternalPropertyArchive;
    }(Ember.Controller.extend({
        // Service
        toast: Ember.inject.service(),
        // Controllers
        propertyController: Ember.inject.controller("internal.property"),
        // Computed
        loading: Ember.computed.alias('propertyController.loading'),
        property: Ember.computed.alias('propertyController.property')
    }));

    exports.default = InternalPropertyArchive;

    __decorate([_emberConcurrencyDecorators.task], InternalPropertyArchive.prototype, "archiveProperty", null);
});
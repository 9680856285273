define('darwin-portal/mixins/adapters/base-adapter', ['exports', 'darwin-portal/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        host: _environment.default.APP.apiUrl,
        namespace: 'portal',
        isInvalid: function isInvalid(status, headers, payload) {
            return status === 422 || status === 409;
        }
    });
});
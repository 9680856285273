define('darwin-portal/controllers/internal/partners/index', ['exports', 'darwin-portal/mixins/controllers/paginatable', 'darwin-portal/mixins/controllers/searchable'], function (exports, _paginatable, _searchable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPartnersIndex = function (_EmberController$ext) {
        _inherits(InternalPartnersIndex, _EmberController$ext);

        function InternalPartnersIndex() {
            _classCallCheck(this, InternalPartnersIndex);

            return _possibleConstructorReturn(this, (InternalPartnersIndex.__proto__ || Object.getPrototypeOf(InternalPartnersIndex)).apply(this, arguments));
        }

        return InternalPartnersIndex;
    }(Ember.Controller.extend(_paginatable.default, _searchable.default, {
        // anything which *must* be merged to prototype here
        partners: Ember.computed.alias('model.partners.value'),
        loading: Ember.computed.alias('model.partners.isRunning'),
        meta: Ember.computed.alias('model.partners.value.meta')
    }));

    exports.default = InternalPartnersIndex;
});
define('darwin-portal/mixins/routes/access-check', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        can: Ember.inject.service(),
        beforeModel: function beforeModel() {
            var result = this._super.apply(this, arguments);
            var ability = Ember.get(this, 'ability');
            var can = Ember.get(this, 'can');
            if (can.cannot(ability)) {
                return this.transitionTo('internal');
            }
            return result;
        }
    });
});
define('darwin-portal/controllers/internal/partner-device/system-faults', ['exports', 'darwin-portal/mixins/controllers/partner-device', 'darwin-portal/mixins/controllers/paginatable', 'darwin-portal/mixins/controllers/searchable', 'darwin-portal/mixins/controllers/sortable'], function (exports, _partnerDevice, _paginatable, _searchable, _sortable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPartnerDeviceSystemFaults = function (_EmberController$ext) {
        _inherits(InternalPartnerDeviceSystemFaults, _EmberController$ext);

        function InternalPartnerDeviceSystemFaults() {
            _classCallCheck(this, InternalPartnerDeviceSystemFaults);

            return _possibleConstructorReturn(this, (InternalPartnerDeviceSystemFaults.__proto__ || Object.getPrototypeOf(InternalPartnerDeviceSystemFaults)).apply(this, arguments));
        }

        return InternalPartnerDeviceSystemFaults;
    }(Ember.Controller.extend(_partnerDevice.default, _paginatable.default, _searchable.default, _sortable.default, {
        deviceLoading: Ember.computed.alias('deviceController.loading'),
        systemFaultsLoading: Ember.computed.alias('model.systemFaults.isRunning'),
        systemFaults: Ember.computed('model.systemFaults.value', 'deviceController.home', 'showResolved', function () {
            if (Ember.get(this, 'showResolved')) {
                return Ember.get(this, 'model.systemFaults.value');
            }
            return Ember.get(this, 'deviceController.home.allFaults');
        }),
        meta: Ember.computed.alias('model.systemFaults.value.meta'),
        loading: Ember.computed.or('deviceLoading', 'systemFaultsLoading'),
        // Properties
        sort: '-raisedAt',
        queryParams: ['alarmSeverity', 'showResolved'],
        alarmSeverity: null,
        showResolved: false,
        selectedFault: null,
        actions: {
            toggleShowResolved: function toggleShowResolved() {
                var toggledValue = !Ember.get(this, 'showResolved');
                this.transitionToRoute({
                    queryParams: {
                        showResolved: toggledValue
                    }
                });
            },
            selectFault: function selectFault(fault) {
                Ember.set(this, "selectedFault", fault);
            },
            deselectFault: function deselectFault() {
                Ember.set(this, "selectedFault", null);
            }
        }
    }));

    exports.default = InternalPartnerDeviceSystemFaults;
});
define("darwin-portal/controllers/internal/partner-device/firmware", ["exports", "darwin-portal/mixins/controllers/partner-device", "ember-concurrency-decorators"], function (exports, _partnerDevice, _emberConcurrencyDecorators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var InternalPartnerDeviceFirmware = function (_EmberController$ext) {
        _inherits(InternalPartnerDeviceFirmware, _EmberController$ext);

        function InternalPartnerDeviceFirmware() {
            _classCallCheck(this, InternalPartnerDeviceFirmware);

            var _this = _possibleConstructorReturn(this, (InternalPartnerDeviceFirmware.__proto__ || Object.getPrototypeOf(InternalPartnerDeviceFirmware)).apply(this, arguments));

            _this.actions = {
                updateSelectedFirmwareOption: function updateSelectedFirmwareOption(option) {
                    Ember.set(this, 'selectedFirmwareOption', option);
                }
            };
            return _this;
        }

        _createClass(InternalPartnerDeviceFirmware, [{
            key: "updateFirmwareGroup",
            value: regeneratorRuntime.mark(function updateFirmwareGroup(macAddress, firmwareGroup) {
                var updateFirmwareGroupService;
                return regeneratorRuntime.wrap(function updateFirmwareGroup$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                _context.prev = 0;
                                updateFirmwareGroupService = Ember.get(this, 'updateFirmwareGroupService');
                                _context.next = 4;
                                return updateFirmwareGroupService.request(macAddress, firmwareGroup);

                            case 4:
                                this.send('refreshAll');
                                Ember.get(this, 'toast').info('Firmware Group updated');
                                this.transitionToRoute('internal.partner-device.index');
                                _context.next = 13;
                                break;

                            case 9:
                                _context.prev = 9;
                                _context.t0 = _context["catch"](0);

                                console.log(_context.t0);
                                Ember.get(this, 'toast').error('Error updating Firmware Group');

                            case 13:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, updateFirmwareGroup, this, [[0, 9]]);
            })
        }]);

        return InternalPartnerDeviceFirmware;
    }(Ember.Controller.extend(_partnerDevice.default, {
        firmwareGroups: Ember.computed.alias('model.firmwareGroups.value'),
        controllerLoading: Ember.computed('loading', 'model.firmwareGroups.isRunning', function () {
            var loading = Ember.get(this, 'loading');
            var loadingFirmwareGroups = Ember.get(this, 'model.firmwareGroups.isRunning');
            return loading || loadingFirmwareGroups;
        }),
        updateFirmwareGroupService: Ember.inject.service('update-firmware-group'),
        toast: Ember.inject.service(),
        firmwareGroupOptions: Ember.computed('firmwareGroups', function () {
            var firmwareGroups = Ember.get(this, 'firmwareGroups');
            if (firmwareGroups) {
                var result = firmwareGroups.map(function (x) {
                    return {
                        label: x.name,
                        value: x.id
                    };
                });
                console.log(result);
                return result;
            } else {
                return [];
            }
        }),
        selectedFirmwareOption: undefined,
        deviceObserver: Ember.on('init', Ember.observer('device', 'firmwareGroupOptions', function () {
            var device = Ember.get(this, 'device');
            var firmwareGroupOptions = Ember.get(this, 'firmwareGroupOptions');
            if (device && device.firmwareGroup && firmwareGroupOptions) {
                Ember.set(this, 'selectedFirmwareOption', device.firmwareGroup.id);
            }
        }))
    }));

    exports.default = InternalPartnerDeviceFirmware;

    __decorate([_emberConcurrencyDecorators.task], InternalPartnerDeviceFirmware.prototype, "updateFirmwareGroup", null);
});
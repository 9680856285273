define('darwin-portal/components/uxs-action-menu/desktop', ['exports', 'ember-ux-sauce/components/uxs-action-menu/desktop'], function (exports, _desktop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _desktop.default;
    }
  });
});
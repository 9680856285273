define('darwin-portal/router', ['exports', 'ember-router-scroll', 'darwin-portal/config/environment'], function (exports, _emberRouterScroll, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  }); /** format */

  Router.map(function () {
    this.route('internal', {
      path: '/'
    }, function () {
      this.route('dashboard', {
        path: '/'
      }, function () {
        this.route('index', {
          path: '/'
        });

        this.route('chart');
        this.route('isloading');
      });

      this.route('partner', {
        path: 'partner/:partner_id'
      }, function () {
        this.route('edit');
        this.route('archive');
        this.route('audits');
        this.route('properties');
        this.route('partner-devices');

        this.route('user', {
          path: 'user/:user_id'
        }, function () {
          this.route('edit');
          this.route('archive');
        });

        this.route('users', function () {
          this.route('index', {
            path: '/'
          });
          this.route('new');
        });
      });
      this.route('partners', function () {
        this.route('index', {
          path: '/'
        });
        this.route('new');
      });

      this.route('ideal-users', function () {
        this.route('index', {
          path: '/'
        });
        this.route('new');
      });
      this.route('ideal-user', {
        path: 'ideal-user/:user_id'
      }, function () {
        this.route('archive');
        this.route('edit');
        this.route('activities');
        this.route('audits');
      });

      this.route('accounts', {
        path: '/customers'
      }, function () {
        this.route('index', {
          path: '/'
        });
        this.route('show', {
          path: '/:account_id'
        }, function () {
          this.route('index', {
            path: '/'
          });
          this.route('edit', {
            path: '/edit'
          });
          this.route('delete', {
            path: '/delete'
          });
          this.route('devices');
          this.route('dataprotection-export');
        });
      });

      this.route('properties', function () {
        this.route('index', {
          path: '/'
        });
        this.route('new');
      });
      this.route('property', {
        path: 'property/:property_id'
      }, function () {
        this.route('edit');
        this.route('archive');
        this.route('devices');
        this.route('journal');
        this.route('audit');
      });
      this.route('partner-devices', {
        path: '/devices'
      }, function () {
        this.route('index', {
          path: '/'
        });
        this.route('new', function () {});
        this.route('map');
      });
      this.route('partner-device', {
        path: 'device/:device_id'
      }, function () {
        this.route('index', {
          path: '/'
        });
        this.route('edit');
        this.route('set-service');
        this.route('alarms');
        this.route('accounts', { path: '/customers' });
        this.route('license');
        this.route('data', function () {
          this.route('index', { path: '/' });
          this.route('home');
          this.route('telemetry');
        });
        this.route('executions', function () {
          this.route('index', {
            path: '/'
          });
          this.route('execution', {
            path: '/:si_command_id'
          });
        });
        this.route('home');
        this.route('archive');
        this.route('building-performance');
        this.route('gas-consumption');
        this.route('boiler-performance');
        this.route('boiler-usage');
        this.route('boiler-dashboard');
        this.route('prt');
        this.route('si');
        this.route('system-dashboard');
        this.route('system-faults');
        this.route('firmware');
      });
      this.route('logout');
      this.route('partner-users', function () {
        this.route('new');
      });

      this.route('partner-user', {
        path: 'partner-user/:user_id'
      }, function () {
        this.route('edit');
        this.route('archive');
      });
      this.route('alarms', function () {
        this.route('index', {
          path: '/'
        });
      });
      this.route('alarm', {
        path: 'alarm/:alarm_id'
      });
      this.route('account', function () {
        this.route('update-password');
      });
      this.route('404', {
        path: '/*path'
      });
      this.route('privacy-terms');
      this.route('halo-firmwares', function () {
        this.route('index', {
          path: '/'
        });
        this.route('create');
      });

      this.route('halo-firmware', {
        path: 'halo-firmware/:id'
      }, function () {
        this.route('index', {
          path: '/'
        });
        this.route('upload');
        this.route('delete');
      });

      this.route('firmware-groups', function () {
        this.route('index', {
          path: '/'
        });
        this.route('new');
      });

      this.route('firmware-group', {
        path: '/firmware-group/:firmware_group_name'
      }, function () {
        this.route('index', {
          path: '/'
        });
        this.route('devices');
        this.route('delete');
        this.route('update');
      });
    });

    this.route('external', {
      path: 'auth'
    }, function () {
      this.route('login', {
        path: '/'
      });
      this.route('reset-password');
      this.route('set-password');
      this.route('forgot-password');
      this.route('404', {
        path: '/*path'
      });
    });
  });

  exports.default = Router;
});
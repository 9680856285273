define('darwin-portal/controllers/internal/partner-devices/map', ['exports', 'darwin-portal/mixins/controllers/filterable', 'darwin-portal/mixins/controllers/searchable', 'darwin-portal/utils/partner-devices/filter-options'], function (exports, _filterable, _searchable, _filterOptions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPartnerDevicesMap = function (_EmberController$ext) {
        _inherits(InternalPartnerDevicesMap, _EmberController$ext);

        function InternalPartnerDevicesMap() {
            _classCallCheck(this, InternalPartnerDevicesMap);

            return _possibleConstructorReturn(this, (InternalPartnerDevicesMap.__proto__ || Object.getPrototypeOf(InternalPartnerDevicesMap)).apply(this, arguments));
        }

        return InternalPartnerDevicesMap;
    }(Ember.Controller.extend(_searchable.default, _filterable.default, {
        // Properties
        filterOptions: _filterOptions.default,
        selectedDevice: null,
        // Computed
        devices: Ember.computed.alias('model.devices.value'),
        loading: Ember.computed.alias('model.devices.isRunning'),
        meta: Ember.computed.alias('model.devices.value.meta'),
        hasSelectedDevice: Ember.computed.bool('selectedDevice'),
        // Actions
        actions: {
            selectDevice: function selectDevice(device) {
                Ember.set(this, 'selectedDevice', device);
            },
            clearSelectedDevice: function clearSelectedDevice() {
                Ember.set(this, 'selectedDevice', null);
            }
        }
    }));

    exports.default = InternalPartnerDevicesMap;
});
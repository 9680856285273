define('darwin-portal/components/dashboard/alarm-matrix', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var DashboardAlarmMatrix = function (_EmberComponent$exte) {
        _inherits(DashboardAlarmMatrix, _EmberComponent$exte);

        function DashboardAlarmMatrix() {
            _classCallCheck(this, DashboardAlarmMatrix);

            return _possibleConstructorReturn(this, (DashboardAlarmMatrix.__proto__ || Object.getPrototypeOf(DashboardAlarmMatrix)).apply(this, arguments));
        }

        return DashboardAlarmMatrix;
    }(Ember.Component.extend({
        // anything which *must* be merged to prototype here
        base: 'alarm-matrix',
        tagName: ''
    }));

    exports.default = DashboardAlarmMatrix;

    ;
});
define("darwin-portal/transforms/user-email", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var UserEmail = _emberData.default.Transform.extend({
        deserialize: function deserialize(serialized) {
            return serialized;
        },
        serialize: function serialize(deserialized) {
            if (typeof deserialized === "string") {
                return deserialized.toLowerCase();
            } else {
                return deserialized;
            }
        }
    });
    exports.default = UserEmail;
});
define('darwin-portal/models/property', ['exports', 'ember-data', 'ember-data/attr', 'darwin-portal/mixins/validations/property'], function (exports, _emberData, _attr, _property) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var Property = function (_DS$Model$extend) {
        _inherits(Property, _DS$Model$extend);

        function Property() {
            _classCallCheck(this, Property);

            return _possibleConstructorReturn(this, (Property.__proto__ || Object.getPrototypeOf(Property)).apply(this, arguments));
        }

        return Property;
    }(_emberData.default.Model.extend(_property.default, {
        // Attributes
        propertyCode: (0, _attr.default)('string'),
        addressLine1: (0, _attr.default)('string'),
        addressLine2: (0, _attr.default)('string'),
        addressCity: (0, _attr.default)('string'),
        addressCounty: (0, _attr.default)('string'),
        addressCountry: (0, _attr.default)('string'),
        addressPostcode: (0, _attr.default)('string'),
        latitude: (0, _attr.default)('string'),
        longitude: (0, _attr.default)('string'),
        notes: (0, _attr.default)('string'),
        priority: (0, _attr.default)('boolean', { defaultValue: false }),
        createdAt: (0, _attr.default)('date'),
        createdBy: (0, _attr.default)('string'),
        updatedAt: (0, _attr.default)('date'),
        updatedBy: (0, _attr.default)('string'),
        numberOfDevices: (0, _attr.default)('number'),
        // Computed
        latLongSet: Ember.computed('latitude', 'longitude', function () {
            if (Ember.get(this, 'latitude') && Ember.get(this, 'longitude')) {
                return true;
            } else {
                return false;
            }
        }),
        geoJsonCoords: Ember.computed('latitude', 'longitude', function () {
            var latitude = Ember.get(this, 'latitude');
            var longitude = Ember.get(this, 'longitude');
            if (latitude === undefined || latitude === null || latitude === "") {
                return null;
            }
            if (longitude === undefined || longitude === null || longitude === "") {
                return null;
            }
            return {
                type: 'FeatureCollection',
                features: [{
                    type: 'Feature',
                    geometry: { type: 'Point', coordinates: [longitude, latitude] }
                }]
            };
        }),
        // Relationships
        devices: _emberData.default.hasMany('device'),
        partner: _emberData.default.belongsTo('partner')
    }));

    exports.default = Property;
});
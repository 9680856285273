define("darwin-portal/routes/internal/partner-devices/map", ["exports", "ember-concurrency-decorators", "darwin-portal/mixins/routes/access-check", "darwin-portal/mixins/routes/searchable", "darwin-portal/mixins/routes/paginatable", "darwin-portal/mixins/routes/sortable", "darwin-portal/mixins/routes/filterable"], function (exports, _emberConcurrencyDecorators, _accessCheck, _searchable, _paginatable, _sortable, _filterable) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var InternalPartnerDevicesMap = function (_EmberRoute$extend) {
        _inherits(InternalPartnerDevicesMap, _EmberRoute$extend);

        function InternalPartnerDevicesMap() {
            _classCallCheck(this, InternalPartnerDevicesMap);

            return _possibleConstructorReturn(this, (InternalPartnerDevicesMap.__proto__ || Object.getPrototypeOf(InternalPartnerDevicesMap)).apply(this, arguments));
        }

        _createClass(InternalPartnerDevicesMap, [{
            key: "model",
            value: function model(params /*, transition*/) {
                // use these to remove any left over sorting/paging params
                this.getPageParams(params);
                this.getSortParam(params);
                return { devices: Ember.get(this, 'getDevices').perform(params) };
            }
        }, {
            key: "getDevices",
            value: regeneratorRuntime.mark(function getDevices(params) {
                var result, geoJson;
                return regeneratorRuntime.wrap(function getDevices$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                _context.next = 2;
                                return this.store.query('device', {
                                    include: "property",
                                    filter: params,
                                    page: {
                                        size: 100000,
                                        number: 1
                                    }
                                });

                            case 2:
                                result = _context.sent;
                                geoJson = this._mapDataToGeoJSON(result.toArray());
                                return _context.abrupt("return", geoJson);

                            case 5:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, getDevices, this);
            })
        }, {
            key: "resetController",
            value: function resetController(controller, isExiting) {
                if (isExiting) {
                    // isExiting would be false if only the route's model was changing
                    controller.set('type', null);
                    controller.set('hasAlarm', null);
                    controller.set('offline', null);
                    controller.set('isHalo', null);
                    controller.set('online', null);
                }
            }
        }, {
            key: "_mapDataToGeoJSON",
            value: function _mapDataToGeoJSON(data) {
                var features = data.map(function (x) {
                    return {
                        Data: x,
                        Loc: {
                            Latitude: Ember.get(x, "property.latitude"),
                            Longitude: Ember.get(x, "property.longitude")
                        }
                    };
                }).filter(function (x) {
                    return x.Loc.Latitude && x.Loc.Longitude;
                }).map(function (x) {
                    return {
                        type: 'Feature',
                        properties: {
                            id: x.Data.id,
                            address: x.Data.propertyAddress,
                            type: x.Data.type,
                            serialNumber: x.Data.serialNumber,
                            printedSerialNumber: x.Data.displaySerialNumber,
                            status: x.Data.status,
                            alarmCount: x.Data.alarmCount
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [x.Loc.Longitude, x.Loc.Latitude]
                        }
                    };
                });
                return {
                    type: 'FeatureCollection',
                    features: features
                };
            }
        }]);

        return InternalPartnerDevicesMap;
    }(Ember.Route.extend(_accessCheck.default, _paginatable.default, _sortable.default, _searchable.default, _filterable.default, {
        // anything which *must* be merged to prototype here
        queryParams: {
            type: {
                refreshModel: true
            },
            hasAlarm: {
                refreshModel: true
            },
            offline: {
                refreshModel: true
            },
            isHalo: {
                refreshModel: true
            },
            online: {
                refreshModel: true
            }
        },
        ability: 'read device'
    }));

    exports.default = InternalPartnerDevicesMap;

    __decorate([_emberConcurrencyDecorators.task], InternalPartnerDevicesMap.prototype, "getDevices", null);
});
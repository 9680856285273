define('darwin-portal/helpers/device/signalStrengthDescriptionTranslator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.getSignalStrengthDescription = getSignalStrengthDescription;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function getSignalStrengthDescription(_ref) /*, hash*/{
        var _ref2 = _slicedToArray(_ref, 2),
            signalStrength = _ref2[0],
            deviceStatus = _ref2[1];

        var signalStrengthText = '';
        if (signalStrength) {
            // the sigal strength is measured in -db so 50 is actually -50
            if (signalStrength < 50 && signalStrength > 0) {
                signalStrengthText = 'Excellent';
            } else if (signalStrength < 90) {
                signalStrengthText = 'Good';
            } else if (signalStrength <= 100) {
                signalStrengthText = 'Fair';
            } else if (signalStrength > 100) {
                signalStrengthText = 'Weak';
            }
        } else {
            signalStrengthText = 'Unknown';
        }
        if (deviceStatus === "Online") {
            return signalStrengthText;
        } else {
            return 'Last Known: ' + signalStrengthText;
        }
    }
    exports.default = Ember.Helper.helper(getSignalStrengthDescription);
});
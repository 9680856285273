define('darwin-portal/controllers/internal/properties/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var InternalPropertiesNew = function (_EmberController$ext) {
        _inherits(InternalPropertiesNew, _EmberController$ext);

        function InternalPropertiesNew() {
            _classCallCheck(this, InternalPropertiesNew);

            var _this = _possibleConstructorReturn(this, (InternalPropertiesNew.__proto__ || Object.getPrototypeOf(InternalPropertiesNew)).apply(this, arguments));

            _this.actions = {
                submit: function submit(newProperty) {
                    var _this2 = this;

                    newProperty.save().then(function () {
                        // success
                        _this2.transitionToRoute('internal.properties.index');
                    }, function () {
                        // failure
                    });
                },
                priority: function priority(newProperty) {
                    var priority = newProperty.priority;
                    newProperty.set('priority', !priority);
                }
            };
            return _this;
        }

        return InternalPropertiesNew;
    }(Ember.Controller.extend({
        // anything which *must* be merged to prototype here
    }));

    exports.default = InternalPropertiesNew;
});
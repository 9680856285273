define("darwin-portal/routes/internal/dashboard/index", ["exports", "ember-concurrency-decorators", "darwin-portal/utils/tasks"], function (exports, _emberConcurrencyDecorators, _tasks) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    var _get = function get(object, property, receiver) {
        if (object === null) object = Function.prototype;
        var desc = Object.getOwnPropertyDescriptor(object, property);

        if (desc === undefined) {
            var parent = Object.getPrototypeOf(object);

            if (parent === null) {
                return undefined;
            } else {
                return get(parent, property, receiver);
            }
        } else if ("value" in desc) {
            return desc.value;
        } else {
            var getter = desc.get;

            if (getter === undefined) {
                return undefined;
            }

            return getter.call(receiver);
        }
    };

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    /** @format */
    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var InternalDashboardIndex = function (_EmberRoute$extend) {
        _inherits(InternalDashboardIndex, _EmberRoute$extend);

        function InternalDashboardIndex() {
            _classCallCheck(this, InternalDashboardIndex);

            return _possibleConstructorReturn(this, (InternalDashboardIndex.__proto__ || Object.getPrototypeOf(InternalDashboardIndex)).apply(this, arguments));
        }

        _createClass(InternalDashboardIndex, [{
            key: "init",
            value: function init() {
                // hack to get observer to trigger
                this.get('authorizer');
                _get(InternalDashboardIndex.prototype.__proto__ || Object.getPrototypeOf(InternalDashboardIndex.prototype), "init", this).call(this);
            }
        }, {
            key: "getAlarmsCounts",
            value: regeneratorRuntime.mark(function getAlarmsCounts() {
                var store, alarms;
                return regeneratorRuntime.wrap(function getAlarmsCounts$(_context) {
                    while (1) {
                        switch (_context.prev = _context.next) {
                            case 0:
                                store = Ember.get(this, 'store');
                                _context.next = 3;
                                return store.findAll('dashboard-alarm');

                            case 3:
                                alarms = _context.sent;
                                return _context.abrupt("return", Ember.get(alarms, 'firstObject'));

                            case 5:
                            case "end":
                                return _context.stop();
                        }
                    }
                }, getAlarmsCounts, this);
            })
        }, {
            key: "getHaloDeviceCounts",
            value: regeneratorRuntime.mark(function getHaloDeviceCounts() {
                var store, counts;
                return regeneratorRuntime.wrap(function getHaloDeviceCounts$(_context2) {
                    while (1) {
                        switch (_context2.prev = _context2.next) {
                            case 0:
                                store = Ember.get(this, 'store');
                                _context2.next = 3;
                                return store.findAll('dashboard-halo-device-count');

                            case 3:
                                counts = _context2.sent;
                                return _context2.abrupt("return", Ember.get(counts, 'firstObject'));

                            case 5:
                            case "end":
                                return _context2.stop();
                        }
                    }
                }, getHaloDeviceCounts, this);
            })
        }, {
            key: "getDashboardNumbers",
            value: regeneratorRuntime.mark(function getDashboardNumbers() {
                var store, numbers;
                return regeneratorRuntime.wrap(function getDashboardNumbers$(_context3) {
                    while (1) {
                        switch (_context3.prev = _context3.next) {
                            case 0:
                                store = Ember.get(this, 'store');
                                _context3.next = 3;
                                return store.findAll('dashboard-number');

                            case 3:
                                numbers = _context3.sent;
                                return _context3.abrupt("return", Ember.get(numbers, 'firstObject'));

                            case 5:
                            case "end":
                                return _context3.stop();
                        }
                    }
                }, getDashboardNumbers, this);
            })
        }, {
            key: "getDeviceLocations",
            value: regeneratorRuntime.mark(function getDeviceLocations() {
                var store, data;
                return regeneratorRuntime.wrap(function getDeviceLocations$(_context4) {
                    while (1) {
                        switch (_context4.prev = _context4.next) {
                            case 0:
                                store = Ember.get(this, 'store');
                                _context4.next = 3;
                                return store.queryRecord('dashboard-map-location', {});

                            case 3:
                                data = _context4.sent;
                                return _context4.abrupt("return", this._mapDataToGeoJSON(data));

                            case 5:
                            case "end":
                                return _context4.stop();
                        }
                    }
                }, getDeviceLocations, this);
            })
        }, {
            key: "getActiveAlarms",
            value: regeneratorRuntime.mark(function getActiveAlarms() {
                var store, matrix;
                return regeneratorRuntime.wrap(function getActiveAlarms$(_context5) {
                    while (1) {
                        switch (_context5.prev = _context5.next) {
                            case 0:
                                store = Ember.get(this, 'store');
                                _context5.next = 3;
                                return store.findAll('dashboard-active-alarm');

                            case 3:
                                matrix = _context5.sent;
                                return _context5.abrupt("return", Ember.get(matrix, 'firstObject'));

                            case 5:
                            case "end":
                                return _context5.stop();
                        }
                    }
                }, getActiveAlarms, this);
            })
        }, {
            key: "model",
            value: function model(params) {
                var recentAlarmType = params.recentAlarmType || 'active';
                if (this.can.can('view idealTiles dashboard')) {
                    return {
                        // alarms: get(this, 'getAlarmsCounts').perform(),
                        haloDeviceCounts: Ember.get(this, 'getHaloDeviceCounts').perform()
                    };
                } else {
                    return {
                        alarms: Ember.get(this, 'getAlarmsCounts').perform(),
                        data: Ember.get(this, 'getDashboardNumbers').perform(),
                        devicesCoords: Ember.get(this, 'getDeviceLocations').perform(),
                        activeAlarms: Ember.get(this, 'getActiveAlarms').perform(),
                        recentAlarms: Ember.get(this, 'getRecentAlarms').perform(recentAlarmType)
                    };
                }
            }
        }, {
            key: "_mapDataToGeoJSON",
            value: function _mapDataToGeoJSON(data) {
                if (Ember.isEmpty(data)) {
                    return {
                        type: 'FeatureCollection',
                        features: []
                    };
                }
                return {
                    type: 'FeatureCollection',
                    features: Ember.get(data, 'locations').map(function (location) {
                        return {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'Point',
                                coordinates: [Ember.get(location, 'Longitude'), Ember.get(location, 'Latitude')]
                            }
                        };
                    })
                };
            }
        }]);

        return InternalDashboardIndex;
    }(Ember.Route.extend({
        // anything which *must* be merged to prototype here
        session: Ember.inject.service('session'),
        authorizer: Ember.inject.service('authorizer'),
        can: Ember.inject.service(),
        getRecentAlarms: _tasks.getRecentAlarms,
        queryParams: {
            recentAlarmType: {}
        },
        observeContext: function () {
            this.refresh();
        }.observes('authorizer.context')
    }));

    exports.default = InternalDashboardIndex;

    __decorate([_emberConcurrencyDecorators.task], InternalDashboardIndex.prototype, "getAlarmsCounts", null);
    __decorate([_emberConcurrencyDecorators.task], InternalDashboardIndex.prototype, "getHaloDeviceCounts", null);
    __decorate([_emberConcurrencyDecorators.task], InternalDashboardIndex.prototype, "getDashboardNumbers", null);
    __decorate([_emberConcurrencyDecorators.task], InternalDashboardIndex.prototype, "getDeviceLocations", null);
    __decorate([_emberConcurrencyDecorators.task], InternalDashboardIndex.prototype, "getActiveAlarms", null);
});
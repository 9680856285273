define('darwin-portal/utils/accounts/sort-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SORT_OPTIONS = [{
        label: 'Last Name',
        value: 'lastName'
    }, {
        label: 'Email',
        value: 'email'
    }];
    exports.default = SORT_OPTIONS;
});
define('darwin-portal/mixins/validations/property', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        addressLine1: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            min: 2
        })],
        addressCity: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            min: 2
        })],
        addressCountry: [(0, _emberCpValidations.validator)('presence', true)],
        addressPostcode: [(0, _emberCpValidations.validator)('format', {
            // very basic regex for matching uk postcodes and eircodes
            regex: /^([0-9A-Za-z]{3,4}?\s?[0-9A-Za-z]{3,4})$/,
            message: 'Postcode must be valid',
            allowBlank: true
        })],
        contactNumber: [(0, _emberCpValidations.validator)('format', {
            type: 'phone',
            regex: /^\+{0,1}[0-9, ]*$/,
            allowBlank: true
        })]
    });
});
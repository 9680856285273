define('darwin-portal/abilities/alarm', ['exports', 'ember-can'], function (exports, _emberCan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberCan.Ability.extend({
        authorizer: Ember.inject.service('authorizer'),
        isActiveUser: Ember.computed.alias('authorizer.isActiveUser'),
        isIdeal: Ember.computed.alias('authorizer.isIdeal'),
        isIdealOrPartnerAdmin: Ember.computed.alias('authorizer.isIdealOrPartnerAdmin'),
        isIdealOrPartner: Ember.computed.alias('authorizer.isIdealOrPartner'),
        // any ideal users can read a alarm but partner users can only view thier own
        canRead: Ember.computed.alias('isIdealOrPartner'),
        // ideal users and partner admins can navigate to a partners alarm list
        canNavigate: Ember.computed.alias('isIdealOrPartner'),
        // only ideal users can see a list of all alarms for all partners
        canNavigateAll: Ember.computed.alias('isIdeal'),
        // ideal users and partner admins can view a list of related devices
        canReadDevices: Ember.computed.alias('isActiveUser')
    });
});
define('darwin-portal/components/dialog-prompt', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    defaultRoute: "internal.orders.new.items",

    // Actions
    actions: {
      toggleModal: function toggleModal() /*model*/{
        var action = this.toggleModal;
        if (action) {
          return action();
        }
      },
      confirmPrompt: function confirmPrompt() /*model*/{
        var action = this.confirmPrompt;
        if (action) {
          return action();
        }
      }
    }
  });
});
define('darwin-portal/mixins/controllers/searchable', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        // Attributes
        queryParams: ['search'],
        search: ''
    });
});
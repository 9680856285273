define('darwin-portal/models/partner', ['exports', 'ember-data', 'ember-data/attr', 'darwin-portal/mixins/validations/partner'], function (exports, _emberData, _attr, _partner) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var Partner = function (_DS$Model$extend) {
        _inherits(Partner, _DS$Model$extend);

        function Partner() {
            _classCallCheck(this, Partner);

            return _possibleConstructorReturn(this, (Partner.__proto__ || Object.getPrototypeOf(Partner)).apply(this, arguments));
        }

        return Partner;
    }(_emberData.default.Model.extend(_partner.default, {
        name: (0, _attr.default)('string'),
        contactNumber: (0, _attr.default)('string'),
        website: (0, _attr.default)('string'),
        preFixText: (0, _attr.default)('string'),
        postFixText: (0, _attr.default)('string'),
        notes: (0, _attr.default)('string'),
        addressLine1: (0, _attr.default)('string'),
        addressLine2: (0, _attr.default)('string'),
        addressCity: (0, _attr.default)('string'),
        addressCounty: (0, _attr.default)('string'),
        addressCountry: (0, _attr.default)('string'),
        addressPostcode: (0, _attr.default)('string'),
        createdAt: (0, _attr.default)('date'),
        updatedAt: (0, _attr.default)('date'),
        createdBy: (0, _attr.default)('string'),
        updatedBy: (0, _attr.default)('string'),
        lat: (0, _attr.default)('number'),
        long: (0, _attr.default)('number'),
        partner_user_count: (0, _attr.default)('number'),
        // Relationships
        partnerUsers: _emberData.default.hasMany('partner-user'),
        properties: _emberData.default.hasMany('property'),
        shortAddress: Ember.computed('addressCity', 'addressCounty', 'adressCountry', function () {
            var fields = [Ember.get(this, 'addressCity'), Ember.get(this, 'addressCounty'), Ember.get(this, 'addressCountry')].filter(function (x) {
                return x;
            });
            return fields.join(', ');
        })
    }));

    exports.default = Partner;
});
define('darwin-portal/mixins/validations/portal-user', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        username: [(0, _emberCpValidations.validator)('presence', true)],
        password: [(0, _emberCpValidations.validator)('presence', true)]
    });
});
define('darwin-portal/controllers/internal/halo-firmwares/create', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var FirmwareCreation = function (_EmberController$ext) {
        _inherits(FirmwareCreation, _EmberController$ext);

        function FirmwareCreation() {
            _classCallCheck(this, FirmwareCreation);

            var _this = _possibleConstructorReturn(this, (FirmwareCreation.__proto__ || Object.getPrototypeOf(FirmwareCreation)).apply(this, arguments));

            // normal class body definition here
            _this.actions = {
                submit: function submit(newFirmware) {
                    var _this2 = this;

                    var nameRegex = new RegExp(/^\w+$/);
                    if (newFirmware.name && !nameRegex.test(newFirmware.name)) {
                        Ember.get(this, 'toast').error('Names may only include letters, numbers and underscores');
                    } else if (!newFirmware.name || newFirmware.name && !(newFirmware.name.length >= 3 && newFirmware.name.length <= 30)) {
                        Ember.get(this, 'toast').error('Please enter a name between than 3-30 characters');
                    } else if (!newFirmware.description || newFirmware.description && !(newFirmware.description.length >= 3 && newFirmware.description.length <= 500)) {
                        Ember.get(this, 'toast').error('Please enter a description between 3-500 characters');
                    } else {
                        newFirmware.save().then(function (result) {
                            // const upload = result.id;
                            Ember.get(_this2, 'toast').info('Firmware Created');
                            _this2.transitionToRoute('internal.halo-firmware.upload', result);
                        }).catch(function (e) {
                            var error = e && e.errors && e.errors[0].title ? e.errors[0].title : 'Error Creating Firmware';
                            Ember.get(_this2, 'toast').error(error);
                        });
                    }
                }
            };
            return _this;
        }

        return FirmwareCreation;
    }(Ember.Controller.extend({
        // anything which *must* be merged to prototype here
        toast: Ember.inject.service()
    }));

    exports.default = FirmwareCreation;
});
define('darwin-portal/components/compatible-date', ['exports', 'ember-ux-sauce/components/uxs-flatpickr'], function (exports, _uxsFlatpickr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uxsFlatpickr.default.extend({
    // Attributes
    //type: 'date',
    classNames: ['uxs-form__input', 'uxs-form__input--date'],
    type: 'text',
    enableTime: false,
    noCalendar: false,
    dateFormat: 'd/m/y'
  });
});
define('darwin-portal/mixins/controllers/partner-device', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        // Computed
        loading: Ember.computed.alias('deviceController.loading'),
        device: Ember.computed.alias('deviceController.device'),
        home: Ember.computed.alias('deviceController.home'),
        back: Ember.computed.alias('deviceController.back'),
        pressureStatus: Ember.computed('device.currentTelemetry', function () {
            if (this.device && this.device.currentTelemetry !== null && this.device.currentTelemetry !== undefined) {
                return this.device.currentTelemetry.waterPressureMean > 0;
            } else {
                return false;
            }
        }),
        burnerOperationHours: Ember.computed('device.currentTelemetry', function () {
            if (this.device && this.device.currentTelemetry !== null && this.device.currentTelemetry !== undefined) return this.device.currentTelemetry.burnerOperationHours;else return 'N/A';
        }),
        chPumpOperationHours: Ember.computed('device.currentTelemetry', function () {
            if (this.device && this.device.currentTelemetry !== null && this.device.currentTelemetry !== undefined) return this.device.currentTelemetry.chPumpOperationHours;else return 'N/A';
        }),
        dhwBurnerOperationHours: Ember.computed('device.currentTelemetry', function () {
            if (this.device && this.device.currentTelemetry !== null && this.device.currentTelemetry !== undefined && this.device.currentTelemetry.dhwBurnerOperationHours !== undefined) return this.device.currentTelemetry.dhwBurnerOperationHours;else return 'N/A';
        }),
        hasGas: Ember.computed('device', function () {
            return this.device && this.device.gasConsumption !== undefined && this.device.gasConsumption !== null && this.device.gasConsumption > 0;
        }),
        gasConsumptionTotalKwh: Ember.computed('device.telemetry', function () {
            if (this.device && this.device.telemetry) {
                return this.device.telemetry.reduce(function (acc, cur) {
                    if (typeof cur.gasConsumptionKwh === 'number') {
                        acc += cur.gasConsumptionKwh;
                    }
                    return acc;
                }, 0);
            } else {
                return 'N/A';
            }
        }),
        telemetryTimestamp: Ember.computed('device.currentTelemetry', function () {
            if (this.device && this.device.currentTelemetry !== null && this.device.currentTelemetry !== undefined) {
                if (this.device.currentTelemetry.timestamp !== null && this.device.currentTelemetry.timestamp !== undefined && this.device.currentTelemetry.timestamp !== '') return (0, _moment.default)(this.device.currentTelemetry.timestamp).format('Do MMM YYYY HH:mm');else return 'Not Available';
            } else return 'Not Available';
        }),
        // Controller
        deviceController: Ember.inject.controller('internal.partner-device'),
        // Service
        toast: Ember.inject.service(),
        can: Ember.inject.service()
    });
});
define("darwin-portal/helpers/check-if-aws-service-used", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.checkIfAWSServiceUsed = checkIfAWSServiceUsed;
    // services that should be monitored
    var servicesToAlertFor = [{
        "service": "route53",
        "location": ""
    }, {
        "service": "CertificateManager",
        "location": "eu-west-2"
    }, {
        "service": "CloudFormation",
        "location": "eu-west-2"
    }, {
        "service": "CloudWatch",
        "location": "eu-west-2"
    }, {
        "service": "Cognito",
        "location": "eu-west-2"
    }, {
        "service": "EC2",
        "location": "eu-west-2"
    }, {
        "service": "EC2",
        "location": "eu-west-1"
    }, {
        "service": "Events",
        "location": "eu-west-2"
    }, {
        "service": "KMS",
        "location": "eu-west-2"
    }, {
        "service": "Kinesis",
        "location": "eu-west-2"
    }, {
        "service": "Lambda",
        "location": "eu-west-2"
    }, {
        "service": "KMS",
        "location": "eu-west-1"
    }, {
        "service": "RDS",
        "location": "eu-west-1"
    }, {
        "service": "S3",
        "location": "eu-west-2"
    }, {
        "service": "SNS",
        "location": "eu-west-2"
    }, {
        "service": "SQS",
        "location": "eu-west-2"
    }, {
        "service": "SSM",
        "location": "eu-west-2"
    }, {
        "service": "SSM",
        "location": "eu-west-1"
    }, {
        "service": "S3",
        "location": "eu-west-1"
    }, {
        "service": "SNS",
        "location": "eu-west-1"
    }];
    // provide this function with the name of an AWS string to check if it's used
    // example: lambda-eu-west-2
    function checkIfAWSServiceUsed(serviceName) {
        {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = servicesToAlertFor[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var service = _step.value;

                    if (serviceName.includes(service.service.toLowerCase()) && serviceName.includes(service.location.toLowerCase())) {
                        return true;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        }
        return false;
    }
});